import { connect } from "react-redux";

import { OrthopredState } from "../../state/reducers";

import { WorkpackEditorView } from "./workpackEditor.component";
import {
  adminListFiles,
  loadSequenceTypes,
  getWorkpacks,
  createWorkpack,
} from "../../state/admin/admin.actions";

export const WorkpackEditorContainer = connect(
  (state: OrthopredState) => {
    return {
      sequenceTypes: state.admin.sequenceTypeList,
      sequenceTypesState: state.admin.sequenceTypeListState,
      fileList: state.admin.fileList,
      userList: state.admin.userList,
    };
  },
  dispatch => {
    return {
      listFiles: (sequenceTypes: string[], emails: string[], loadMore: boolean) => {
        for (const sequenceType of sequenceTypes) {
          dispatch(adminListFiles.request({ sequenceType, emails, loadMore }));
        }
      },
      loadSequenceTypes: () => {
        dispatch(loadSequenceTypes.request({}));
      },
      getWorkpacks: (loadMore: boolean) => {
        dispatch(getWorkpacks.request({ isLoadMore: loadMore }));
      },
      createWorkpack: (name: string, files: string[], sequenceTypes: string[]) => {
        dispatch(createWorkpack.request({ name, sequenceTypes, files }))
      }
    };
  }
)(WorkpackEditorView);
