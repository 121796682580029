import React, { useState, useEffect, useRef, useLayoutEffect, useCallback, MouseEvent, useMemo } from "react";

import dwv from "dwv";
import Konva from "konva";
import ReactResizeDetector from "react-resize-detector";

import Modal from "@material-ui/core/Modal";

import { DrawTool } from "./drawTool";

import { useStyles } from "./dwv.styles";
import { CircularProgress } from "@material-ui/core";
import { useEventListener } from "../useEventListener";
import { ColorHasher } from "./colorHasher";
import { FileViewState, FileViewAction, FileViewActionType } from "../fileView/fileViewState";

export interface ILabel {
  fileId: string;
  id: string;
  rect: [number, number, number, number];
  label: string;
  slice: number;
}

export interface IDicomWebViewComponentProps {
  state: FileViewState;
  dispatch: (action: FileViewAction) => void;
  fileId: string;
  userEmail: string;
  index: number;
}
// decode query
dwv.utils.decodeQuery = dwv.utils.base.decodeQuery;
// progress
dwv.gui.displayProgress = () => {
  /* Noop*/
};
// get element
dwv.gui.getElement = dwv.gui.base.getElement;
// refresh element
dwv.gui.refreshElement = dwv.gui.base.refreshElement;

dwv.image.WindowLevel.prototype.equals = function (e: any) {
  // tslint:disable-next-line: no-invalid-this
  return !e || (e !== null && this.getCenter() === e.getCenter() && this.getWidth() === e.getWidth());
};

// Image decoders (for web workers)
dwv.image.decoderScripts = {
  // jpeg2000: "assets/dwv/decoders/pdfjs/decode-jpeg2000.js",
  jpeg2000: "/assets/dwv/decoders/openjpeg/decode-jpeg2000.js",
  "jpeg-lossless": "/assets/dwv/decoders/rii-mango/decode-jpegloss.js",
  "jpeg-baseline": "/assets/dwv/decoders/pdfjs/decode-jpegbaseline.js",
  rle: "/assets/dwv/decoders/dwv/decode-rle.js",
};

(window as any).dwv = dwv;

const hasher = new ColorHasher({ lightness: [0.35, 0.5, 0.65], saturation: [0.6, 0.75, 0.9] });

export const DicomWebViewComponent: React.FC<IDicomWebViewComponentProps> = ({
  fileId,
  dispatch,
  state,
  userEmail,
}: IDicomWebViewComponentProps) => {
  const classes = useStyles();

  const [app, setApp] = useState<any>(undefined);
  const [progress, setProgress] = useState<number>(0);
  const [draw, setDraw] = useState<any>(undefined);
  const [drawController, setDrawController] = useState<any>(undefined);
  const [infoLayer, setInfoLayer] = useState<any>(undefined);

  const [mouseOver, setMouseOver] = useState(false);

  const divRef = useRef<HTMLDivElement>(null);
  const drawRef = useRef<HTMLDivElement>(null);
  const infoDivRef = useRef<HTMLDivElement>(null);

  const file = state.fileInfos.get(fileId);

  const infoLabels = useMemo(() => {
    return (
      state.displaySettings && [
        {
          ...state.displaySettings.patientIdLabel,
          id: "infoLabel_patientId",
          text: state.patientId,
        },
        {
          ...state.displaySettings.sliceLabel,
          id: "infoLabel_sliceLabel",
          text: file?.sliceLabels[file.slice] ? file?.sliceLabels[file.slice].toString() : "",
        },
        {
          ...state.displaySettings.laterality,
          id: "infoLabel_laterality",
          text: file?.tags ? file?.tags.Laterality : "",
        },
      ]
    );
  }, [state.displaySettings, file, state.patientId]);

  useEffect(() => {
    async function setupInfoLayer() {
      console.log("infosetup", infoLabels);

      if (!infoLabels) {
        return;
      }
      const newTexts = await Promise.all(
        infoLabels
          .filter((a) => !!a)
          .map(async (a) => {
            const col = a.color.startsWith("variable") ? await hasher.rgb(a.text) : a.color;

            return new Konva.Text({
              ...a,
              name: "InfoLabelText",
              fill: a.color.startsWith("variable")
                ? `RGBA(${col[0]},${col[1]},${col[2]},${a.color.substr(8)})`
                : a.color,
            });
          })
      );
      newTexts.forEach((t) => infoLayer.add(t));
      infoLayer.draw();
    }

    if (infoLayer && infoLabels) {
      setupInfoLayer();
      return () => {
        infoLayer.find(".InfoLabelText").destroy();
      };
    }
  }, [infoLayer, infoLabels, hasher]);

  useEffect(() => {
    if (drawController && drawController.getDrawLayer() && file && state.displaySettings.sliceIndicator.visible) {
      file.sliceIndicators.forEach((r) => drawController.getDrawLayer().add(r));
      drawController.getDrawLayer().draw();
      return () => {
        file.sliceIndicators.forEach((r) => r.destroy());
      };
    }
  }, [drawController, file, state.displaySettings]);

  const onCreated = useDrawEventCallback(drawController, fileId, (ev) => {
    // It emits events for labels that we add initially
    if (!file?.labels.find((l) => l.id === ev.id))
      dispatch({ type: FileViewActionType.CreateLabel, ...ev, fileId, labelMode: state.currentLabelMode });
  });
  const onUpdated = useDrawEventCallback(drawController, fileId, (ev, type) => {
    if (type === "draw-move") {
      return dispatch({ type: FileViewActionType.MoveLabel, ...ev, fileId });
    } else {
      return dispatch({ type: FileViewActionType.CreateLabel, ...ev, fileId, labelMode: state.currentLabelMode });
    }
  });
  useEventListener("draw-create", onCreated, draw);
  useEventListener(
    "draw-delete",
    useDrawEventCallback(drawController, fileId, (ev) =>
      dispatch({ type: FileViewActionType.DeleteLabel, ...ev, fileId })
    ),
    draw
  );
  useEventListener("draw-change", onUpdated, draw);
  useEventListener("draw-move", onUpdated, draw);
  useEventListener(
    "draw-selected",
    (ev) => {
      dispatch({ type: FileViewActionType.SelectLabel, id: ev.id });
    },
    draw
  );
  const currentStep = state.steps[state.currentStep];
  const wheelListener = useCallback(
    (e: MouseWheelEvent) => {
      e.preventDefault();
      // if (e.deltaMode !== 0) {
      //   console.warn("Please use a trackpad.");
      //   return;
      // }

      // Sometimes app is undefined...
      if (app) {
        const rect = (e.currentTarget as HTMLDivElement).getBoundingClientRect();
        const sliceNr = Number.parseInt(app.getViewController().getCurrentPosition().k, 10);
        if (e.ctrlKey) {
          const center = file?.sliceCenters.get(sliceNr);
          const delta = Math.max(-1, Math.min(1, e.deltaY)) / 5;

          if (e.shiftKey && center) {
            app.stepZoom(delta, center[0], center[1]);
          } else {
            app.stepZoom(delta, e.clientX - rect.left, e.clientY - rect.top);
          }
        } else {
          const step = e.shiftKey ? 5 : 1;
          if (e.deltaY > 0) {
            if (
              !currentStep ||
              currentStep.upperSliceLimit === undefined ||
              currentStep.upperSliceLimit === null ||
              currentStep.upperSliceLimit >= sliceNr + step
            ) {
              app.getViewController().setCurrentSlice(sliceNr + step);
            }
          } else {
            if (
              !currentStep ||
              currentStep.lowerSliceLimit === undefined ||
              currentStep.lowerSliceLimit === null ||
              currentStep.lowerSliceLimit <= sliceNr - step
            ) {
              app.getViewController().setCurrentSlice(sliceNr - step);
            }
          }
        }
      }
    },
    [app, file, currentStep]
  );
  useEventListener("wheel", wheelListener, divRef.current);

  const keyListener = useCallback(
    (e: any) => {
      function copyRect(roiId: string, sliceNr: number) {
        const oldLabel = file?.labels.find((a) => a.id === roiId);

        const maxSliceNr = app.getImage().getGeometry().getSize().getNumberOfSlices();

        if (!oldLabel || sliceNr < 0 || sliceNr >= maxSliceNr) {
          return;
        }
        const newLabel = {
          id: dwv.math.guid(),
          slice: sliceNr,
          label: oldLabel.label,
          labelMode: oldLabel.labelMode,
          fileId: oldLabel.fileId,
          rect: oldLabel.rect,
          color: state.labelModesWithColor.find((m) => m.name === oldLabel.labelMode)?.color,
          own: oldLabel.creator === userEmail,
        };
        draw.addLabel(newLabel);
        onCreated({ type: "draw-created", id: newLabel.id });
        dispatch({ type: FileViewActionType.SelectLabel, id: newLabel.id });
      }

      if (mouseOver && state.selectedROI && draw.hasShapeSelected()) {
        switch (e.key) {
          case "ArrowUp":
            moveRect(drawController, state.selectedROI, 0, -1, 0, e.ctrlKey ? 1 : 0);
            updateRect(drawController, state.selectedROI, {});
            return onUpdated({ type: "draw-moved", id: state.selectedROI });
          case "ArrowDown":
            moveRect(drawController, state.selectedROI, 0, e.ctrlKey ? 0 : 1, 0, e.ctrlKey ? 1 : 0);
            updateRect(drawController, state.selectedROI, {});
            return onUpdated({ type: "draw-moved", id: state.selectedROI });
          case "ArrowLeft":
            moveRect(drawController, state.selectedROI, -1, 0, e.ctrlKey ? 1 : 0, 0);
            updateRect(drawController, state.selectedROI, {});
            return onUpdated({ type: "draw-moved", id: state.selectedROI });
          case "ArrowRight":
            moveRect(drawController, state.selectedROI, e.ctrlKey ? 0 : 1, 0, e.ctrlKey ? 1 : 0, 0);
            updateRect(drawController, state.selectedROI, {});
            return onUpdated({ type: "draw-moved", id: state.selectedROI });
          case "a":
          case "A":
            if (
              !currentStep ||
              currentStep.upperSliceLimit === undefined ||
              currentStep.upperSliceLimit === null ||
              currentStep.upperSliceLimit > app.getViewController().getCurrentPosition().k
            ) {
              if (app.getViewController().incrementSliceNb()) {
                copyRect(state.selectedROI, app.getViewController().getCurrentPosition().k);
              }
            }
            return;
          case "d":
          case "D":
            if (
              !currentStep ||
              currentStep.lowerSliceLimit === undefined ||
              currentStep.lowerSliceLimit === null ||
              currentStep.lowerSliceLimit < app.getViewController().getCurrentPosition().k
            ) {
              if (app.getViewController().decrementSliceNb()) {
                copyRect(state.selectedROI, app.getViewController().getCurrentPosition().k);
              }
            }
            return;
          case "1":
          case "2":
          case "3":
          case "4":
          case "5":
          case "6":
          case "7":
          case "8":
          case "9":
            const mode = state.labelModesWithColor.find((l) => l.name === state.currentLabelMode);
            if (mode) {
              updateRect(drawController, state.selectedROI, { label: e.key, color: mode.color });
            }
            return onUpdated({ type: "draw-changed", id: state.selectedROI });
          case "Delete": {
            return draw.deleteDraw(state.selectedROI);
          }
        }
      } else if (mouseOver) {
        const slice = app.getViewController().getCurrentPosition().k as number;
        switch (e.key) {
          case "w":
          case "W":
            file?.labels
              .filter((a) => a.slice === slice - 1)
              .map((a) => {
                return {
                  fileId: a.fileId,
                  slice,
                  id: dwv.math.guid(),
                  label: a.label,
                  labelMode: a.labelMode,
                  rect: a.rect,
                };
              })
              .forEach((l) => {
                draw.addLabel(l);
                onCreated({ type: "draw-created", id: l.id });
              });
            return;
          case "1":
          case "2":
          case "3":
          case "4":
          case "5":
          case "6":
          case "7":
          case "8":
          case "9":
            dispatch({
              type: FileViewActionType.SetSliceLabel,
              fileId,
              slice,
              label: Number.parseInt(e.key, 10),
            });
            return;
          case "Enter":
            return drawController.getDrawLayer().visible(!drawController.getDrawLayer().visible());
          case "x":
            if (
              !currentStep ||
              currentStep.lowerSliceLimit === undefined ||
              currentStep.lowerSliceLimit === null ||
              currentStep.lowerSliceLimit < app.getViewController().getCurrentPosition().k
            ) {
              return app.getViewController().decrementSliceNb();
            }
            break;
          case "í":
            if (
              !currentStep ||
              currentStep.upperSliceLimit === undefined ||
              currentStep.upperSliceLimit === null ||
              currentStep.upperSliceLimit > app.getViewController().getCurrentPosition().k
            ) {
              return app.getViewController().incrementSliceNb();
            }
            break;
        }
      }
    },
    [mouseOver, state, drawController, draw, app, onCreated, onUpdated, currentStep, userEmail, dispatch, file, fileId]
  );
  useEventListener("keydown", keyListener, window);

  const handleCanvasMouseEvent = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      function mouseEventDWVifier(event: any) {
        const offsets = dwv.html.getEventOffset(event);
        event._xs = offsets[0].x;
        event._ys = offsets[0].y;
        const imageLayer = app?.getImageLayer();
        if (!imageLayer) return;
        const position = imageLayer.displayToIndex(offsets[0]);
        event._x = parseInt(position.x, 10);
        event._y = parseInt(position.y, 10);

        return event;
      }

      const dwvEvent = mouseEventDWVifier(e.nativeEvent);
      if (draw && dwvEvent) {
        if (
          (e.type === "mousemove" || e.type === "mousedown" || e.type === "mouseup") &&
          (e.button === 2 || e.button === 4)
        ) {
          return;
        }
        e.preventDefault();
        // e.stopPropagation();
        draw[e.type](dwvEvent);
      }
    },
    [app, draw]
  );

  const zoomChangeHandler = useCallback(
    (event: { type: "zoom-change"; scale: number; cx: number; cy: number }) => {
      if (drawController && drawController.getDrawStage()) {
        drawController.zoomStage(event.scale, { x: event.cx, y: event.cy });
        const drawLayer = drawController.getDrawLayer();
        const textNodes = drawLayer.find("Text");
        for (const text of textNodes) {
          const absScale = text.getAbsoluteScale();
          text.scaleX(text.scaleX() / absScale.x);
          text.scaleY(text.scaleY() / absScale.y);
        }
        drawLayer.draw();
      }
    },
    [drawController]
  );
  useEventListener("zoom-change", zoomChangeHandler, app);

  const offsetChangeHandler = useCallback(
    (event: { type: "offset-change"; scale: number; cx: number; cy: number }) => {
      if (app && drawController && drawController.getDrawStage()) {
        const ox = -app.getImageLayer().getOrigin().x / event.scale - event.cx;
        const oy = -app.getImageLayer().getOrigin().y / event.scale - event.cy;
        drawController.translateStage(ox, oy);
      }
    },
    [app, drawController]
  );
  useEventListener("offset-change", offsetChangeHandler, app);

  const loadProgressHandler = useCallback(
    (event: any) => {
      setProgress((p) => (p === 101 ? 101 : event.progress));
    },
    [setProgress]
  );
  useEventListener("load-progress", loadProgressHandler, app);

  const loadEndHandler = useCallback(
    (event: any) => {
      (window as any).app = app;
      (window as any).draw = draw;
      app.onKeydown = () => {
        /* disabling defaults */
      };
      const imgData = app.getImageData();
      drawController.create(imgData.width, imgData.height);

      // Resetting everything as it's not done automatically because we split the Draw out
      app.onResize();
      const scaleCenter = app.getScaleCenter();
      app.stepZoom(0, scaleCenter.x, scaleCenter.y);
      app.stepTranslate(0, 0);

      const type = app.getTags().find((t) => t.name === "SeriesDescription").value;
      const storedWindow = window.localStorage.getItem(`preferred-window`);
      if (storedWindow) {
        const w = JSON.parse(storedWindow);
        app.getViewController().setWindowLevel(w.wc, w.ww);
      }

      const tagObject: { [name: string]: string } = {};
      for (const tag of app.getTags()) {
        tagObject[tag.name] = tag.value;
      }

      const orientation = app.getImage().getGeometry().getOrientation();
      dispatch({
        type: FileViewActionType.FinishFileLoad,
        fileId,
        tags: tagObject,
        sliceCount: app.getImage().getGeometry().getSize().getNumberOfSlices(),
        orientation: [
          orientation.get(0, 0),
          orientation.get(0, 1),
          orientation.get(0, 2),
          orientation.get(1, 0),
          orientation.get(1, 1),
          orientation.get(1, 2),
        ],
        origins: app
          .getImage()
          .getGeometry()
          .getOrigins()
          .map((p: any) => [p.getX(), p.getY(), p.getZ()]),
      });
      app
        .getViewController()
        .setCurrentSlice(
          Math.min(
            Math.max((currentStep && currentStep.lowerSliceLimit) || 0, file?.storedFile?.meta.startingSlice || 0),
            (currentStep && currentStep.upperSliceLimit) || Number.POSITIVE_INFINITY
          )
        );
      drawController.activateDrawLayer(app.getViewController());
      draw.display(true);

      const infoStage = new Konva.Stage({
        container: infoDivRef.current,
        width: imgData.width,
        height: imgData.height,
        listening: false,
      });
      const newInfoLayer = new Konva.Layer({
        listening: false,
        hitGraphEnabled: false,
        visible: true,
      });
      infoStage.add(newInfoLayer);
      setInfoLayer(newInfoLayer);

      if (draw && file?.labels && draw.drawLayer) {
        const oldLabels = draw.drawLayer.find(".LabeledROI");
        oldLabels.forEach((element: any) => {
          if (file?.labels.every((l) => l.id !== element.id())) {
            element.destroy();
          }
        });
        file?.labels.forEach((l) =>
          draw.addLabel({
            ...l,
            color: state.labelModesWithColor.find((m) => m.name === l.labelMode)?.color,
            own: l.creator === userEmail,
          })
        );
        setProgress(101);
      }

      draw.display(true);
    },
    [app, draw, drawController, file, state, userEmail, currentStep, dispatch, fileId]
  );
  useEventListener("load-end", loadEndHandler, app);

  useEffect(() => {
    if (app && currentStep && app.getViewController()) {
      const sliceNr = app.getViewController().getCurrentPosition().k;
      if (
        currentStep.lowerSliceLimit !== undefined &&
        currentStep.lowerSliceLimit !== null &&
        currentStep.lowerSliceLimit > sliceNr
      ) {
        app.getViewController().setCurrentSlice(currentStep.lowerSliceLimit);
      } else if (
        currentStep.upperSliceLimit !== undefined &&
        currentStep.upperSliceLimit !== null &&
        currentStep.upperSliceLimit < sliceNr
      ) {
        app.getViewController().setCurrentSlice(currentStep.upperSliceLimit);
      }
    }
  }, [app, currentStep]);

  useEventListener(
    "slice-change",
    useCallback(
      (ev) => {
        const sliceNr = app.getViewController().getCurrentPosition().k;
        dispatch({ type: FileViewActionType.SetSlice, fileId, slice: Number.parseInt(sliceNr, 10) });
        drawController.activateDrawLayer(app.getViewController());
      },
      [drawController, app, dispatch, fileId]
    ),
    app
  );

  useEffect(() => {
    if (draw) {
      const mode = state.labelModesWithColor.find((l) => l.name === state.currentLabelMode);
      if (mode) {
        draw.setDefaultLabel(mode.defaultLabel, mode.color);
      }
    }
  }, [draw, state, dispatch, fileId]);

  useEffect(() => {
    if (app && progress === 101) {
      if (state.windowLevel && state.windowWidth) {
        app.getViewController().setWindowLevel(state.windowLevel, state.windowWidth, null);
      } else {
        const wl = app.getViewController().getWindowLevel();

        dispatch({
          type: FileViewActionType.SetWindow,
          wl: wl.center,
          ww: wl.width,
        });
      }
    }
  }, [app, progress, state.windowLevel, state.windowWidth]);

  useEffect(() => {
    if (app && draw && drawController && drawController.getDrawLayer()) {
      draw.setSelectedRectangle(state.selectedROI);
    }
  }, [app, draw, drawController, state.selectedROI]);

  const storedFile = file?.storedFile;
  useEffect(() => {
    setApp((app) => {
      if (storedFile && !app) {
        // create app
        const newApp = new dwv.App();

        // initialise app
        newApp.init({
          containerDivId: `dwv_${fileId}`,
          tools: [],
          isMobile: true,
        });

        const newDrawTool = new DrawTool(newApp, new dwv.tool.RectangleFactory(), "yellow");
        const newDrawController = new dwv.DrawController(drawRef.current);
        // Overriding default implementations to supply our own... The lib isn't designed to be extensible
        newApp.getDrawStage = () => newDrawController.getDrawStage();
        newApp.getDrawController = () => newDrawController;
        newApp.addToolCanvasListeners = () => {
          /* Noop, handled elsewhere */
        };
        const origFitToSize = newApp.fitToSize.bind(newApp);
        newApp.fitToSize = (s: { width: number; height: number }) => {
          origFitToSize(s);

          const windowScale = newApp.getWindowScale();
          const imgData = newApp.getImageData();

          if (!Number.isFinite(windowScale) || !imgData) {
            return;
          }

          const newWidth = windowScale * imgData.width;
          const newHeight = windowScale * imgData.height;

          newDrawController.resizeStage(newWidth, newHeight, newApp.getScale() * windowScale);
        };
        // load
        newApp.loadFiles([new File([storedFile.blob], "virtual.zip")]);

        // store
        setApp(newApp);
        setDraw(newDrawTool);
        setDrawController(newDrawController);

        return () => {
          newApp.abortLoad();
        };
      }
    });
  }, [setApp, storedFile, fileId]);

  useLayoutEffect(() => {
    if (draw) {
      draw.setDrawing(state.tool === "Draw");
    }
    const children = Array.from(divRef.current!.getElementsByTagName("div") as HTMLCollectionOf<HTMLElement>).concat(
      Array.from(divRef.current!.getElementsByTagName("canvas") as HTMLCollectionOf<HTMLElement>)
    );
    for (const elem of children) {
      elem.style.cursor = state.tool === "Draw" ? "crosshair" : "default";
      elem.oncontextmenu = (e: Event) => {
        e.preventDefault();
      };
    }
  }, [app, draw, state.tool, divRef]);

  return (
    <div
      id={`dwv_${fileId}`}
      className={classes.layerContainer}
      ref={divRef}
      onMouseUp={(ev) => {
        if (ev.button === 2) {
          dispatch({ type: FileViewActionType.SetTool, tool: state.tool === "Draw" ? "ZoomAndPan" : "Draw" });
        }
        ev.preventDefault();
      }}
    >
      <ReactResizeDetector
        handleWidth={true}
        handleHeight={true}
        onResize={(w, h) => {
          if (app) {
            app.onResize();
          }
          if (infoLayer) {
            infoLayer.getStage().setWidth(w);
            infoLayer.getStage().setHeight(h);
          }
        }}
      />
      <div
        className="layerContainer"
        onMouseOver={() => setMouseOver(true)}
        onMouseEnter={() => setMouseOver(true)}
        onMouseOut={() => setMouseOver(false)}
        onMouseLeave={() => setMouseOver(false)}
        onMouseMove={(ev) => {
          if (app) {
            switch (ev.buttons) {
              case 3:
                if (state.tool !== "Draw") {
                  app.stepTranslate(ev.movementX, ev.movementY);
                }
                break;
              case 4:
                const wl = app.getViewController().getWindowLevel();
                dispatch({
                  type: FileViewActionType.SetWindow,
                  wl: wl.center - ev.movementY * 2,
                  ww: wl.width + ev.movementX * 2,
                });
                break;
            }
          }
        }}
      >
        <canvas className="imageLayer">Only for HTML5 compatible browsers...</canvas>
        <div className="drawDiv2" ref={infoDivRef} />
        <div
          className="drawDiv2"
          ref={drawRef}
          onMouseDown={handleCanvasMouseEvent}
          onMouseMove={handleCanvasMouseEvent}
          onMouseUp={handleCanvasMouseEvent}
        />
      </div>
      <Modal container={divRef.current} open={progress <= 100} className={classes.progressModal}>
        <CircularProgress value={progress} variant={progress === 0 ? "indeterminate" : "determinate"} />
      </Modal>
    </div>
  );
};

function updateRect(drawController: any, selectedRectId: string, update: { label?: string; color?: string }) {
  const newDetails = Object.assign(
    drawController.getDrawDisplayDetails().find((d: { id: string }) => d.id === selectedRectId),
    update
  );
  drawController.updateDraw(newDetails);
}

function moveRect(
  drawController: any,
  selectedRectId: string,
  deltaX: number,
  deltaY: number,
  deltaWidth: number = 0,
  deltaHeight: number = 0
) {
  const drawLayer = drawController.getDrawLayer();
  const group = drawLayer.findOne(`#${selectedRectId}`);
  const rect = group.findOne("Rect");
  const size = rect.size();
  group.move({ x: deltaX, y: deltaY });

  if (deltaWidth !== 0 || deltaHeight !== 0) {
    rect.size({ width: size.width + deltaWidth, height: size.height + deltaHeight });
    for (const c of group.find("Circle")) {
      c.move({ x: c.x() === rect.x() ? 0 : deltaWidth, y: c.y() === rect.y() ? 0 : deltaHeight });
    }
  }
}

function useDrawEventCallback(
  drawController: any,
  fileId: string,
  cb: ((label: ILabel, type: string) => void) | undefined
) {
  return useCallback(
    (e: { type: string; id: string }) => {
      const posGroup = drawController.getCurrentPosGroup();
      const position = dwv.draw.getPositionFromGroupId(posGroup.id());

      const group = posGroup.findOne(`#${e.id}`);
      if (e.type === "draw-delete" && cb) {
        cb(
          {
            id: e.id,
            label: "",
            rect: undefined as any,
            slice: Number.parseInt(position.sliceNumber, 10),
            fileId,
          },
          e.type
        );
      }
      if (!group) {
        return;
      }

      const rect = group.findOne("Rect");
      const label = group.findOne("Label");

      if (cb) {
        cb(
          {
            id: e.id,
            label: label.getText().text(),
            rect: [rect.x() + group.x(), rect.y() + group.y(), rect.width(), rect.height()],
            slice: Number.parseInt(position.sliceNumber, 10),
            fileId,
          },
          e.type
        );
      }
    },
    [cb, fileId, drawController]
  );
}
