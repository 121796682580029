import { connect } from "react-redux";

import { OrthopredState } from "../../state/reducers";

import { WorkpackView } from "./workpackView.component";
import { adminListFiles, getWorkpacks, assignWorkpack } from "../../state/admin/admin.actions";
import { match } from "react-router";

export const WorkpackViewContainer = connect(
  (state: OrthopredState, ownProps: { match: match<{ id: string }> }) => {
    return {
      sequenceTypes: state.admin.sequenceTypeList,
      sequenceTypesState: state.admin.sequenceTypeListState,
      fileList: state.admin.fileList,
      userList: state.admin.userList,
      workpackList: state.admin.workpackList,
      packId: ownProps.match.params.id,
    };
  },
  dispatch => {
    return {
      listFiles: (sequenceTypes: string[], emails: string[], loadMore: boolean) => {
        for (const sequenceType of sequenceTypes) {
          dispatch(adminListFiles.request({ sequenceType, emails, loadMore }));
        }
      },
      assignWorkpack: (packId: string, email: string) => {
        dispatch(assignWorkpack.request({ packId, email }));
      },
      getWorkpacks: (loadMore: boolean) => {
        dispatch(getWorkpacks.request({ isLoadMore: loadMore }));
      },
    };
  }
)(WorkpackView);
