import { getType } from "typesafe-actions";

import { OrthopredAction } from "../actions";
import * as SessionActions from "./session.actions";
import { ILoginState, LoginStatus, defaultImageInfoDisplaySettings } from "./session.state";

export function loginState(
  state: ILoginState = {
    loginStatus: LoginStatus.Unknown,
    sessionInfo: undefined,
    userIdentity: undefined,
    labelingSettings: undefined,
    imageInfoDisplaySettings: defaultImageInfoDisplaySettings,
  },
  action: OrthopredAction
): ILoginState {
  switch (action.type) {
    case getType(SessionActions.login.request):
      return { loginStatus: LoginStatus.LoggingIn, ...state };
    case getType(SessionActions.login.failure):
      return {
        loginStatus: LoginStatus.LoggedOut,
        sessionInfo: undefined,
        userIdentity: undefined,
        labelingSettings: undefined,
        imageInfoDisplaySettings: defaultImageInfoDisplaySettings,
      };
    case getType(SessionActions.login.success):
      return action.payload;

    case getType(SessionActions.logout.request):
      return { loginStatus: LoginStatus.LoggingOut, ...state };
    case getType(SessionActions.logout.failure):
      return state;
    case getType(SessionActions.logout.success):
      return {
        loginStatus: LoginStatus.LoggedOut,
        sessionInfo: undefined,
        userIdentity: undefined,
        labelingSettings: undefined,
        imageInfoDisplaySettings: defaultImageInfoDisplaySettings,
      };

    case getType(SessionActions.checkSession.request):
      return state;
    case getType(SessionActions.checkSession.failure):
      return {
        loginStatus: LoginStatus.LoggedOut,
        sessionInfo: undefined,
        userIdentity: undefined,
        labelingSettings: undefined,
        imageInfoDisplaySettings: defaultImageInfoDisplaySettings,
      };
    case getType(SessionActions.checkSession.success):
      return action.payload;

    case getType(SessionActions.saveImageInfoDisplaySettings.request):
      return state;
    case getType(SessionActions.saveImageInfoDisplaySettings.failure):
      return {
        ...state,
        imageInfoDisplaySettings: defaultImageInfoDisplaySettings,
      };
    case getType(SessionActions.saveImageInfoDisplaySettings.success):
      return {
        ...state,
        imageInfoDisplaySettings: action.payload,
      };

    default:
      return state;
  }
}
