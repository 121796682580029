export enum UserFilePermission {
  FileUpload = "U",
  FileDelete = "D",
  FileView = "V",
  List = "L",
  ViewAny = "X",
  SetPermissions = "P",
  Annotate = "A",
  ViewAnnotations = "R",
  EditAnnotations = "E",
}

export class UserFilePermissions {
  permissons: UserFilePermission[];

  constructor(...permissons: UserFilePermission[]) {
    this.permissons = permissons;
  }

  static deserialize(str: string) {
    return new UserFilePermissions(...(str ? (str.split("") as UserFilePermission[]) : []));
  }

  check(perm: UserFilePermission) {
    return this.permissons.includes(perm);
  }

  serialize() {
    return this.permissons.join("");
  }
}
