import React from "react";
import { Container } from "@material-ui/core";

import { History } from "history";

import { LoadingView } from "../loadingView.component";
import { useUserFileList } from "../../queries/fileList.query";
import { Redirect } from "react-router-dom";

export interface IFileListComponentProps {
  history: History;
}

export const OpenCurrentPatientView: React.FC<IFileListComponentProps> = ({ history }: IFileListComponentProps) => {
  const { data, called, loading, error } = useUserFileList();

  if (!called || loading) {
    return <LoadingView />;
  }

  if (!data || error) {
    return <main>{JSON.stringify(error)}</main>;
  }

  const currentProgresses = data.me.currentProgresses;
  return (
    <Container component="main" maxWidth="lg">
      {currentProgresses.length > 0 ? (
        <Redirect
          to={`/images/${currentProgresses[0].workpack.id}/${currentProgresses[0].currentPatient ||
            currentProgresses[0].workpack.patients[0].id}`}
        />
      ) : (
        <Redirect to="/images/" />
      )}
    </Container>
  );
};
