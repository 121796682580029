import { Theme, createStyles, makeStyles } from "@material-ui/core";

import green from "@material-ui/core/colors/lightGreen";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      maxHeight: "30vh",
      // overflowY: "auto",
      // overflowX: "hidden",
      // padding: theme.spacing(1),

      // "position": "absolute",
      // "bottom": theme.spacing(0),
      // "right": 0,

      "& ul": {
        width: "30vw",
        display: "flex",
        flexDirection: "column-reverse",
        "& li": {
          paddingRight: theme.spacing(1 / 2),
        },
      },
    },
    badge: {
      fontSize: 12,
    },
    secondary: {
      backgroundColor: green["A400"],
    },
    listText: {
      paddingRight: theme.spacing(1 / 2),
    },
  })
);
