import { OrthopredAction } from "../actions";
import { getType } from "typesafe-actions";
import { loadLabels, saveLabels, updateLabel, addLog, setSliceLabel } from "./labeling.actions";

export interface Label {
  id: string;
  rect: [number, number, number, number];
}

export interface ILogData {
  message: string;
  fileId: string;
  slice: number;
  timestamp: Date;
}
export interface ILabelData {
  fileId: string;
  slice: number;
  id: string;
  labelMode: string;
  label: string;
  creator: string;
  rect: [number, number, number, number];
}

export interface ISliceLabelData {
  fileId: string;
  slice: number;
  label: number;
}

export interface ILabelingState {
  sliceLabelLists: Map<string, Map<number, number>>;
  sliceLabelActions: ISliceLabelData[];
  labelLists: Map<string, Map<number, Map<string, ILabelData>>>;
  labelActions: ILabelData[];
  logItems: ILogData[];
}

const startState = {
  sliceLabelLists: new Map(),
  sliceLabelActions: [],
  labelLists: new Map(),
  labelActions: [],
  logItems: [],
};

export function labelingReducer(state: ILabelingState = startState, action: OrthopredAction): ILabelingState {
  switch (action.type) {
    case getType(loadLabels.success):
      const sliceMaps = new Map<number, Map<string, ILabelData>>();
      for (const l of action.payload.labels) {
        let sl = sliceMaps.get(l.slice);
        if (!sl) {
          sl = new Map();
          sliceMaps.set(l.slice, sl);
        }
        sl.set(l.id, l);
      }

      const labelLists = new Map(state.labelLists.entries());
      labelLists.set(action.payload.fileId, sliceMaps);

      const sliceLabelMap = new Map<number, number>();
      for (const [i, v] of Array.from(action.payload.sliceLabels.entries())) {
        sliceLabelMap.set(i, v);
      }

      const sliceLabelLists = new Map(state.sliceLabelLists.entries());
      sliceLabelLists.set(action.payload.fileId, sliceLabelMap);

      return { ...state, labelLists, sliceLabelLists };
    case getType(saveLabels.success):
      return {
        ...state,
        labelActions: state.labelActions.filter(a => action.payload.saved.indexOf(a) === -1),
        sliceLabelActions: state.sliceLabelActions.filter(a => action.payload.savedSliceLabels.indexOf(a) === -1),
        logItems: state.logItems.filter(a => action.payload.savedLogs.indexOf(a) === -1),
      };
    case getType(addLog):
      return { ...state, logItems: state.logItems.concat({ timestamp: new Date(), ...action.payload }) };
    case getType(updateLabel):
      const updatedLists = new Map(state.labelLists.entries());
      let fileList = updatedLists.get(action.payload.fileId);
      if (!fileList) {
        fileList = new Map();
        updatedLists.set(action.payload.fileId, fileList);
      }

      let newSliceList = fileList.get(action.payload.slice);
      if (!newSliceList) {
        newSliceList = new Map();
        fileList.set(action.payload.slice, newSliceList);
      }
      if (action.payload.rect) {
        newSliceList.set(action.payload.id, action.payload);
      } else {
        newSliceList.delete(action.payload.id);
      }

      return {
        ...state,
        labelActions: state.labelActions.filter(a => a.id !== action.payload.id).concat(action.payload),
        labelLists: updatedLists,
      };
    case getType(setSliceLabel):
      const updatedSliceLabels = new Map(state.sliceLabelLists.entries());
      let sliceList = updatedSliceLabels.get(action.payload.fileId);
      if (!sliceList) {
        sliceList = new Map();
        updatedSliceLabels.set(action.payload.fileId, sliceList);
      }

      sliceList.set(action.payload.slice, action.payload.label);

      return {
        ...state,
        sliceLabelActions: state.sliceLabelActions
          .filter(a => a.fileId !== action.payload.fileId || a.slice !== action.payload.slice)
          .concat(action.payload),
        sliceLabelLists: updatedSliceLabels,
      };
    default:
      return state;
  }
}
