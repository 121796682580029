import { connect } from "react-redux";

import { OrthopredState } from "../../state/reducers";
import { checkSession, logout } from "../../state/session/session.actions";
import { LayoutComponent } from "./layout.component";

export const LayoutContainer = connect(
  (state: OrthopredState) => {
    return {
      loginStatus: state.loginState.loginStatus,
      identity: state.loginState.userIdentity,
    };
  },
  dispatch => {
    return {
      checkSession: () => {
        dispatch(checkSession.request());
      },
      logout: () => {
        dispatch(logout.request());
      },
    };
  }
)(LayoutComponent);
