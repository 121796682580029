import { combineEpics, Epic } from "redux-observable";

import { filter, mergeMap, withLatestFrom } from "rxjs/operators";

import { isActionOf } from "typesafe-actions";

import { WebApi } from "../../modules/api/webApi";
import { WebApiError } from "../../modules/api/WebApiError";
import { OrthopredAction } from "../actions";
import { OrthopredState } from "../reducers";
import { loadLabels, saveLabels } from "./labeling.actions";
import { LoginStatus } from "../session/session.state";

const loadLabelsEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi }> = (
  action$,
  state$,
  { api }
) => {
  return action$.pipe(
    filter(isActionOf(loadLabels.request)),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
          throw new Error("NotLoggedIn");
        }

        const { sharedKey, sessionId } = state.loginState.sessionInfo;

        const res = await api.getLabels(action.payload.fileId, sessionId, sharedKey);

        return loadLabels.success({ fileId: action.payload.fileId, labels: res.labels, sliceLabels: res.sliceLabels });
      } catch (err) {
        if (err instanceof WebApiError) {
          return loadLabels.failure(err);
        }

        throw err;
      }
    })
  );
};

const saveLabelsEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi }> = (
  action$,
  state$,
  { api }
) => {
  return action$.pipe(
    filter(isActionOf(saveLabels.request)),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
          throw new Error("NotLoggedIn");
        }

        const { sharedKey, sessionId } = state.loginState.sessionInfo;
        const labels = Array.from(state.labels.labelActions);
        const sliceLabels = Array.from(state.labels.sliceLabelActions);
        const logs = Array.from(state.labels.logItems);

        if (labels.length === 0 && logs.length === 0 && sliceLabels.length === 0) {
          return saveLabels.success({ saved: labels, savedSliceLabels: sliceLabels, savedLogs: logs });
        }

        await api.saveLabels(labels, sliceLabels, logs, sessionId, sharedKey);

        return saveLabels.success({ saved: labels, savedSliceLabels: sliceLabels, savedLogs: logs });
      } catch (err) {
        if (err instanceof WebApiError) {
          return saveLabels.failure(err);
        }

        throw err;
      }
    })
  );
};

export const labelingEpics = combineEpics(loadLabelsEpic, saveLabelsEpic);
