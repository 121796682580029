import React, { useState, useEffect } from "react";
import { Button, CircularProgress, FormControl, FormHelperText, Input, InputLabel } from "@material-ui/core";

import { LoginStatus } from "../../state/session/session.state";

import "./registration.css";
import { registration } from "../../state/registration/registration.actions";
import { failedAction } from "../../state/errors/error.actions";
import { ValidationResult } from "../../modules/validation/validationResult";
import { popMyError } from "../util";
import { BigLogoComponent } from "../logo/bigLogo.component";
import { RegStatus } from "../../state/registration/registration.reducers";
import { Redirect } from "react-router";

export interface IRegistrationProps {
  loginStatus: LoginStatus;
  regStatus: RegStatus;
  errors: failedAction[];

  checkSession: () => void;
  register: (fullName: string, email: string, password: string) => void;
  login: (email: string, password: string) => void;
  dismissError: (err: failedAction) => void;
}

export const RegistrationComponent: React.FC<IRegistrationProps> = ({
  loginStatus,
  regStatus,
  errors,
  checkSession,
  register,
  login,
  dismissError,
}: IRegistrationProps) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationResult, setValidationResult] = useState<ValidationResult | undefined>(undefined);

  useEffect(() => {
    if (loginStatus === LoginStatus.Unknown) {
      checkSession();
    }
    if (regStatus === "Registered") {
      login(email, password);
    }
  });

  useEffect(() => {
    const e = popMyError(errors, registration, dismissError);
    if (e) {
      setValidationResult(e);
      setTimeout(() => {
        if (validationResult === e) {
          setValidationResult(undefined);
        }
      }, 5000);
    }
  }, [errors, dismissError, validationResult]);

  if (loginStatus === LoginStatus.LoggedIn && regStatus === "Registered") {
    return <Redirect to="/" />;
  }

  return (
    <div id="loginPage">
      <div className="loginForm">
        <form
          className="loginForm"
          onSubmit={(ev) => {
            register(fullName, email, password);
            ev.preventDefault();
          }}
        >
          <BigLogoComponent />
          <FormControl required={true} error={validationResult && validationResult.isFieldInvalid("fullName")}>
            <InputLabel htmlFor="fullName">Név</InputLabel>
            <Input name="fullName" onChange={(ev) => setFullName(ev.currentTarget.value)} value={fullName} />
            <FormHelperText id="email-error-text">
              {validationResult && validationResult.fieldValidationMessages("fullName")}
            </FormHelperText>
          </FormControl>
          <FormControl required={true} error={validationResult && validationResult.isFieldInvalid("email")}>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input name="email" onChange={(ev) => setEmail(ev.currentTarget.value)} value={email} />
            <FormHelperText id="email-error-text">
              {validationResult && validationResult.fieldValidationMessages("email")}
            </FormHelperText>
          </FormControl>

          <FormControl required={true} error={validationResult && validationResult.isFieldInvalid("password")}>
            <InputLabel htmlFor="password">Jelszó</InputLabel>
            <Input
              id="password"
              value={password}
              type="password"
              onChange={(ev) => setPassword(ev.currentTarget.value)}
            />
            <FormHelperText id="password-error-text">
              {validationResult && validationResult.fieldValidationMessages("password")}
            </FormHelperText>
          </FormControl>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth={true}
            disabled={regStatus === "Registering"}
          >
            {(regStatus === "Registering" && <CircularProgress size={24} />) || "Regisztracio"}
          </Button>
        </form>
      </div>
    </div>
  );
};
