import React, { useState } from "react";
import { match } from "react-router";

import {
  Container,
  ListItem,
  ListItemText,
  List,
  Typography,
  ListItemIcon,
  CircularProgress,
  IconButton,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import CloudDownload from "@material-ui/icons/CloudDownload";
import FontDownload from "@material-ui/icons/FontDownload";

import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { History } from "history";

// import { useStyles } from "./adminFileList.style";
import { useStyles } from "./workpackView.style";

import { UserSelectComponent } from "../userSelect/userSelect.component";
import { UserPermission } from "../../dtos/enums/userPermissions";
import { LoadingView } from "../loadingView.component";
import { useFileDownloader } from "../../modules/file.hooks";

export interface IFileListComponentProps {
  match: match<{ packId: string }>;
  history: History;
}

const packQuery = gql`
  query pack($packId: String!) {
    workpack(packId: $packId) {
      id
      name
      creator
      created
      sequenceTypes
      labelingModes {
        name
        labels
        defaultLabel
      }
      workflow {
        labelingModes
        lowerSliceLimit
        upperSliceLimit
      }
      patients {
        id
        source
      }
      assignees {
        email
        fullName
        currentPatient
        progressPercent
        patientProgresses {
          id
          currentStep
          completedSteps
        }
      }
    }
  }
`;

export const WorkpackView: React.FC<IFileListComponentProps> = ({ match, history }: IFileListComponentProps) => {
  const packId = match.params.packId;
  const classes = useStyles();

  const [isUserListOpen, setIsUserListOpen] = useState(false);
  const [tasks, , downloadLabelPack, downloadLogPack] = useFileDownloader();
  const { data } = useQuery<{
    workpack: {
      name: string;
      creator: string;
      created: string;
      sequenceTypes: string[];
      labelingModes: Array<{ name: string; labels: string[]; defaultLabel: number }>;
      workflow: Array<{ labelingModes?: string[]; lowerSliceLimit?: number; upperSliceLimit?: number }>;
      patients: { id: string; source: string }[];
      assignees: {
        email: string;
        fullName: string;
        currentPatient: string;
        progressPercent: number;
        patientProgresses: Array<{
          id: string;
          currentStep: number;
          completedSteps: number[];
        }>;
      }[];
    };
  }>(packQuery, {
    variables: {
      packId,
    },
  });

  const [assignWorkpack, { loading }] = useMutation(gql`
    mutation assign($email: String!, $packId: String!) {
      assignPack(email: $email, packId: $packId, unassignOthers: true, unassignWorkpacks: true) {
        __typename
        id
        assignees {
          __typename
          email
          fullName
          currentPatient
          progressPercent
          patientProgresses {
            id
            currentStep
            completedSteps
          }
        }
      }
    }
  `);

  const workpack = data && data.workpack;
  const downloadingLabels = tasks.inProgressTasks.some((f) => f.id === packId + "_LabelPack");
  const downloadingLogs = tasks.inProgressTasks.some((f) => f.id === packId + "_LogPack");

  if (!workpack) {
    return <LoadingView></LoadingView>;
  }

  return (
    <Container component="main" className={classes.workpackView} maxWidth="md">
      <Typography variant="h4">{workpack.name}</Typography>
      <Typography variant="subtitle1">
        {workpack.creator} ({workpack.created})
      </Typography>
      <Typography variant="subtitle2">{workpack.sequenceTypes.join(" - ")}</Typography>
      <IconButton onClick={() => !downloadingLabels && downloadLabelPack(packId)}>
        {downloadingLabels ? <CircularProgress /> : <CloudDownload />}
      </IconButton>
      <IconButton onClick={() => !downloadingLabels && downloadLogPack(packId)}>
        {downloadingLogs ? <CircularProgress /> : <FontDownload />}
      </IconButton>
      <Dialog open={isUserListOpen} onClose={() => setIsUserListOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Felhasznalok</DialogTitle>
        <DialogContent className={classes.dialog}>
          <UserSelectComponent
            rawItems={true}
            renderListItem={(user) => {
              return (
                <>
                  <ListItemIcon
                    onClick={() =>
                      !loading &&
                      assignWorkpack({
                        variables: {
                          packId,
                          email: user.email,
                        },
                      }).then(() => setIsUserListOpen(false))
                    }
                  >
                    {loading ? <CircularProgress size="small" /> : <AddIcon />}
                  </ListItemIcon>
                  <ListItemText secondary={user.email}>
                    {user.fullName || user.email}
                    {user.userPermissions.check(UserPermission.Login) ? "" : "(Nem validalt!)"}
                  </ListItemText>
                </>
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsUserListOpen(false)} color="secondary">
            Megse
          </Button>
        </DialogActions>
      </Dialog>
      <section>
        <Typography variant="h6">Felhasználók</Typography>
        <List>
          {workpack.assignees.map(({ email, fullName, progressPercent }) => (
            <ListItem key={email}>
              <ListItemIcon>
                <div className={classes.userProgress}>
                  <CircularProgress variant="determinate" value={100} color={"secondary"} />
                  <CircularProgress value={progressPercent} variant="static" />
                </div>
              </ListItemIcon>
              <ListItemText primary={fullName} secondary={email} />
            </ListItem>
          ))}
            <ListItem>
              <ListItemIcon onClick={() => setIsUserListOpen(true)}>
                <AddIcon />
              </ListItemIcon>
              <ListItemText>Máshoz rendelés</ListItemText>
            </ListItem>
        </List>
      </section>
      <section>
        <Typography variant="h6">Cimkezesi modok</Typography>
        <List dense={true}>
          {/* <FlipMove typeName={null}> */}
          {workpack.labelingModes.map((p) => {
            return (
              <ListItem key={p.name}>
                <ListItemText
                  primary={p.name}
                  secondary={p.labels
                    .map((v, i) => v && `${i === p.defaultLabel ? "*" : ""}${i}: ${v}`)
                    .filter((a) => a && a.length > 0)
                    .join(", ")}
                />
              </ListItem>
            );
          })}
          {/* </FlipMove> */}
        </List>
      </section>
      {workpack.workflow.length > 0 && (
        <section>
          <Typography variant="h6">Lepesek</Typography>
          <List dense={true}>
            {workpack.workflow.map((p, i) => {
              return (
                <ListItem key={`workpack_step_${i}`}>
                  <ListItemText
                    primary={`Cimkezesi modok: ${p.labelingModes ? p.labelingModes.join(", ") : "*"}`}
                    secondary={`Szeletek: ${p.lowerSliceLimit === undefined ? "*" : p.lowerSliceLimit} - ${
                      p.upperSliceLimit === undefined ? "*" : p.upperSliceLimit
                    }`}
                  />
                </ListItem>
              );
            })}
          </List>
        </section>
      )}

      <section>
        <Typography variant="h6">Vizsgalatok</Typography>
        <List className={classes.gridList} dense={true}>
          {/* <FlipMove typeName={null}> */}
          {workpack.patients.map((p) => {
            const pprog = workpack.assignees[0] && workpack.assignees[0].patientProgresses.find((pp) => pp.id === p.id);
            return (
              <ListItem key={p.id}>
                {workpack.assignees.length === 1 && pprog && (
                  <ListItemIcon>
                    <div className={classes.userProgress}>
                      <CircularProgress variant="determinate" value={100} color={"secondary"} />
                      <CircularProgress
                        value={(pprog.completedSteps.length / workpack.workflow.length) * 100}
                        variant="static"
                      />
                    </div>
                  </ListItemIcon>
                )}
                <ListItemText primary={p.id} secondary={p.source} />
              </ListItem>
            );
          })}
          {/* </FlipMove> */}
        </List>
      </section>
    </Container>
  );
};
