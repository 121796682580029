import React, { ReactNode } from "react";

import { IconButton, Paper } from "@material-ui/core";
import DragIcon from "@material-ui/icons/DragHandle";

import { useStyles } from "./fileView.style";
import { Layout } from "react-grid-layout";

export type GridTileProps = {
  gridKey: string;
  title: string | ReactNode;
  layout: Array<Layout>;
  children: ReactNode;
};

export const GridTile: React.FC<GridTileProps> = ({ title, children, gridKey, layout }) => {
  return (
    <>
      <div className="resizeHandle" />
      <div className="titleBar">
        <span>{title}</span>
        <IconButton color="primary" size="small">
          <DragIcon />
        </IconButton>
      </div>
      <div className="resizeHandle ne" />
      <div className="resizeHandle" />
      {children}
      <div className="resizeHandle" />
      <div className="resizeHandle" />
      <div className="resizeHandle" />
      <div className="resizeHandle" />
    </>
  );
};
