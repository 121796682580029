import { getType } from "typesafe-actions";

import { OrthopredAction } from "../actions";
import * as AdminActions from "./admin.actions";
import { UserPermissions } from "../../dtos/enums/userPermissions";
import { UserFilePermissions } from "../../dtos/enums/userFilePermissions";

export interface IWorkpack {
  id: string;
  name: string;
  files: string[];
  sequenceTypes: string[];
  created: Date;
  creator: string;
}

export interface IUserInfo {
  email: string;
  fullName: string;
  userPermissions: UserPermissions;
  filePermissions: UserFilePermissions;
  labelingettings: any;
}

export interface IUserList {
  startDate: Date | undefined;
  cursorDate: Date | undefined;
  cursor: number;
  users: IUserInfo[];
  isRefreshing: boolean;
  isLoadingMore: boolean;
  isFullyLoaded: boolean;
}

export interface IFileInfo {
  fileId: string;
  meta: any;
  userPermissions: any;
}

export interface IFileList {
  [sequenceType: string]: {
    startDate: Date | undefined;
    cursorDate: Date | undefined;
    cursor: number;
    files: IFileInfo[];
    isRefreshing: boolean;
    isLoadingMore: boolean;
    isFullyLoaded: boolean;
  };
}

export interface IWorkpackList {
  startDate: Date | undefined;
  cursorDate: Date | undefined;
  cursor: number;
  workpacks: Array<{ pack: IWorkpack; assignees: string[] }>;
  isRefreshing: boolean;
  isLoadingMore: boolean;
  isFullyLoaded: boolean;
}

export interface IAdminState {
  userList: IUserList;
  fileList: IFileList;
  workpackList: IWorkpackList;
  sequenceTypeList: string[];
  sequenceTypeListState: "Default" | "Loading" | "Loaded" | "Failed";
}

const startState: IAdminState = {
  userList: {
    startDate: undefined,
    cursorDate: undefined,
    cursor: 0,
    users: [],
    isRefreshing: false,
    isLoadingMore: false,
    isFullyLoaded: false,
  },
  workpackList: {
    startDate: undefined,
    cursorDate: undefined,
    cursor: 0,
    workpacks: [],
    isRefreshing: false,
    isLoadingMore: false,
    isFullyLoaded: false,
  },
  fileList: {},
  sequenceTypeList: ["COR_IW_TSE", "SAG_IW_TSE"],
  sequenceTypeListState: "Default",
};

export function adminReducer(state: IAdminState = startState, action: OrthopredAction): IAdminState {
  switch (action.type) {
    case getType(AdminActions.listUsers.request):
      return {
        ...state,
        userList: {
          ...state.userList,
          startDate: action.payload.cursor === 0 ? new Date() : state.userList.startDate,
          isRefreshing: action.payload.cursor === 0,
          isLoadingMore: action.payload.cursor !== 0,
        },
      };
    case getType(AdminActions.listUsers.success):
      if (action.payload.originalCursor !== 0 && action.payload.originalCursor !== state.userList.cursor) {
        return state;
      }
      const users =
        action.payload.originalCursor === 0
          ? action.payload.users
          : state.userList.users.concat(
              action.payload.users.filter(n => state.userList.users.every(o => n.email !== o.email))
            );
      return {
        ...state,
        userList: {
          startDate: action.payload.originalCursor === 0 ? new Date() : state.userList.startDate,
          cursorDate: new Date(),
          users: users,
          cursor: action.payload.cursor,
          isFullyLoaded: action.payload.cursor === 0,
          isRefreshing: false,
          isLoadingMore: false,
        },
      };
    case getType(AdminActions.listUsers.failure):
      return {
        ...state,
        userList: {
          startDate: new Date(),
          cursorDate: state.userList.cursorDate,
          users: [],
          cursor: 0,
          isRefreshing: false,
          isFullyLoaded: true,
          isLoadingMore: false,
        },
      };

    case getType(AdminActions.adminListFiles.request):
      return {
        ...state,
        fileList: {
          ...state.fileList,
          [action.payload.sequenceType]: {
            ...startState.fileList[action.payload.sequenceType],
            isRefreshing: !action.payload.loadMore,
            isLoadingMore: action.payload.loadMore,
            isFullyLoaded: false,
          },
        },
      };
    case getType(AdminActions.adminListFiles.success):
      if (action.payload.originalCursor !== 0 && action.payload.originalCursor !== state.userList.cursor) {
        return state;
      }

      const files =
        action.payload.originalCursor === 0
          ? action.payload.files
          : state.fileList[action.payload.sequenceType].files.concat(
              action.payload.files.filter(n =>
                state.fileList[action.payload.sequenceType].files.every(o => n.fileId !== o.fileId)
              )
            );
      return {
        ...state,
        fileList: {
          ...state.fileList,
          [action.payload.sequenceType]: {
            ...state.fileList[action.payload.sequenceType],
            files,
            cursor: action.payload.cursor,
            isRefreshing: false,
            isLoadingMore: false,
            isFullyLoaded: action.payload.cursor === 0,
          },
        },
      };
    case getType(AdminActions.adminListFiles.failure):
      return {
        ...state,
        fileList: {
          ...state.fileList,
          [action.payload.originalPayload!.sequenceType]: {
            ...state.fileList[action.payload.originalPayload!.sequenceType],
            isRefreshing: false,
            isFullyLoaded: true,
            isLoadingMore: false,
          },
        },
      };
    case getType(AdminActions.loadSequenceTypes.request):
      return {
        ...state,
        sequenceTypeListState: "Loading",
      };
    case getType(AdminActions.loadSequenceTypes.success):
      return {
        ...state,
        sequenceTypeList: action.payload.sequenceTypes,
        sequenceTypeListState: "Loaded",
      };
    case getType(AdminActions.loadSequenceTypes.failure):
      return {
        ...state,
        sequenceTypeListState: "Failed",
      };
    case getType(AdminActions.setPermissionForUser.failure):
    case getType(AdminActions.setPermissionForUser.request):
      return state; // There is not much we can do, but show an error (handled elsewhere)
    case getType(AdminActions.setPermissionForUser.success):
      return {
        ...state,
        userList: {
          ...state.userList,
          // We copy into a new array to help change detection (and to not modify things already in the cache)
          users: state.userList.users.map(a =>
            a.email === action.payload.email ? { ...a, userPermissions: action.payload.newPermission } : a
          ),
        },
      };
    case getType(AdminActions.setUserFilePermissionForUser.failure):
    case getType(AdminActions.setUserFilePermissionForUser.request):
      return state; // There is not much we can do.
    case getType(AdminActions.setUserFilePermissionForUser.success):
      return {
        ...state,
        userList: {
          ...state.userList,
          // We copy into a new array to help change detection (and to not modify things already in the cache)
          users: state.userList.users.map(a =>
            a.email === action.payload.email ? { ...a, filePermissions: action.payload.newPermission } : a
          ),
        },
      };
    case getType(AdminActions.setFilePermissionForUser.failure):
    case getType(AdminActions.setFilePermissionForUser.request):
      return state; // There is not much we can do.
    case getType(AdminActions.setFilePermissionForUser.success):
      const fl = { ...state.fileList };
      const newState = { ...state, fileList: fl };
      Object.keys(newState.fileList).forEach(seqType => {
        fl[seqType] = {
          ...state.fileList[seqType],
          files: state.fileList[seqType].files.map(f =>
            f.fileId === action.payload.fileId
              ? {
                  ...f,
                  userPermissions: {
                    ...f.userPermissions,
                    [action.payload.email]: action.payload.newPermission,
                  },
                }
              : f
          ),
        };
      });
      return newState;

    case getType(AdminActions.getWorkpacks.request):
      return {
        ...state,
        workpackList: {
          ...state.workpackList,
          startDate: !action.payload.isLoadMore ? new Date() : state.userList.startDate,
          isRefreshing: !action.payload.isLoadMore,
          isLoadingMore: action.payload.isLoadMore,
        },
      };
    case getType(AdminActions.getWorkpacks.success):
      if (action.payload.originalCursor !== 0 && action.payload.originalCursor !== state.userList.cursor) {
        return state;
      }
      const workpacks =
        action.payload.originalCursor === 0
          ? action.payload.workpacks
          : state.workpackList.workpacks.concat(
              action.payload.workpacks.filter(n => state.workpackList.workpacks.every(o => n.pack.id !== o.pack.id))
            );
      return {
        ...state,
        workpackList: {
          startDate: action.payload.originalCursor === 0 ? new Date() : state.userList.startDate,
          cursorDate: new Date(),
          workpacks,
          cursor: action.payload.cursor,
          isFullyLoaded: action.payload.cursor === 0,
          isRefreshing: false,
          isLoadingMore: false,
        },
      };
    case getType(AdminActions.getWorkpacks.failure):
      return {
        ...state,
        workpackList: {
          startDate: new Date(),
          cursorDate: state.userList.cursorDate,
          workpacks: [],
          cursor: 0,
          isRefreshing: false,
          isFullyLoaded: true,
          isLoadingMore: false,
        },
      };

    case getType(AdminActions.createWorkpack.success): {
      return {
        ...state,
        workpackList: {
          ...state.workpackList,
          workpacks: state.workpackList.workpacks.concat({ pack: action.payload, assignees: [] }),
        },
      };
    }

    case getType(AdminActions.assignWorkpack.success): {
      const pack = state.workpackList.workpacks.find(p => p.pack.id === action.payload.id);
      const workpacks = pack
        ? state.workpackList.workpacks.concat({ pack: pack.pack, assignees: [action.payload.email] })
        : state.workpackList.workpacks;
      return {
        ...state,
        workpackList: {
          ...state.workpackList,
          workpacks,
        },
      };
    }

    default:
      return state;
  }
}
