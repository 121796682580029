import { combineEpics, Epic } from "redux-observable";

import { filter, mergeMap } from "rxjs/operators";

import { isActionOf } from "typesafe-actions";

import { WebApi } from "../../modules/api/webApi";
import { WebApiError } from "../../modules/api/WebApiError";
import { OrthopredAction } from "../actions";
import { OrthopredState } from "../reducers";
import { registration } from "./registration.actions";

import * as jsrp from "jsrp";
import scryptAsync from "scrypt-async";
import { fromHexString } from "../../modules/util";
import { ValidationService } from "../../modules/validation/validationService";

const registerEpic: Epic<
  OrthopredAction,
  OrthopredAction,
  OrthopredState,
  { api: WebApi; validationService: ValidationService }
> = (action$, state$, { api, validationService }) => {
  return action$.pipe(
    filter(isActionOf(registration.request)),
    mergeMap(async action => {
      try {
        const stretchParams = await api.getDefaultStretchParams();
        const stretchSalt = window.crypto.getRandomValues(new Uint8Array(32));

        const stretchedPassword: Uint8Array = await new Promise(res =>
          // Undocumented but working API, typings are kind of bad
          scryptAsync(
            action.payload.password as any,
            stretchSalt as any,
            Object.assign({ encoding: "binary" }, stretchParams.params),
            res as any
          )
        );

        const registerSRP = new jsrp.client();
        await new Promise(res =>
          registerSRP.init({ username: action.payload.email, password: stretchedPassword as any }, res)
        );

        const regInfo = await new Promise<{ salt: string; verifier: string }>((res, rej) =>
          registerSRP.createVerifier((err, data) => {
            if (err) {
              rej(err);
            } else {
              res(data);
            }
          })
        );

        const saltBytes = fromHexString(regInfo.salt);
        const verifierBytes = fromHexString(regInfo.verifier);

        await api.register(
          action.payload.email,
          saltBytes,
          verifierBytes,
          stretchSalt,
          stretchParams,
          action.payload.fullName
        );
        return registration.success({ email: action.payload.email, stretchedPassword });
      } catch (err) {
        if (err instanceof WebApiError) {
          return registration.failure(validationService.parseError(err) || err);
        }

        throw err;
      }
    })
  );
};

export const registrationEpics = combineEpics(registerEpic);
