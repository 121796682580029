import React, { useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Container,
  IconButton,
  TextField,
  InputAdornment,
  LinearProgress,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/DeleteForever";
import CancelIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";

import { DropzoneArea } from "material-ui-dropzone";

import { useStyles } from "./upload.style";
import { ValidationResult } from "../../modules/validation/validationResult";
import { Task } from "../../state/task/task.reducer";

export interface IUploadComponentProps {
  tasks: Task[];

  saveFile: (
    source: string,
    patientId: string,
    bodyPart: string,
    seqType: string,
    modality: string,
    imgNo: number,
    file: File
  ) => void;

  dismissTask: (id: string) => {};
  cancelTask: (id: string) => {};
}

export const UploadComponent: React.FC<IUploadComponentProps> = ({
  tasks,
  saveFile,
  dismissTask,
  cancelTask,
}: IUploadComponentProps) => {
  const classes = useStyles();

  const [source, setSource] = useState("");
  const [patientId, setPatientId] = useState("");
  const [bodyPart, setBodyPart] = useState("");
  const [seqType, setSeqType] = useState("");
  const [modality, setModality] = useState("");
  const [imgNo, setImgNo] = useState(0);
  const [file, setFile] = useState(undefined as File | undefined);

  const currTask = tasks.find((t) => t.id === source + patientId + seqType);

  const validationResult = undefined;

  function clearForm() {
    setSource("");
    setPatientId("");
    setBodyPart("");
    setSeqType("");
    setModality("");
    setImgNo(0);
    setFile(undefined);
  }

  function getField<T>(
    name: string,
    displayName: string,
    val: T,
    setter: (v: T) => void,
    validationRes: ValidationResult | undefined,
    type: string,
    conv: (str: string) => T
  ) {
    return (
      <FormControl required={true} error={validationRes && validationRes.isFieldInvalid(name)}>
        <InputLabel htmlFor={name}>{displayName}</InputLabel>
        <Input name={name} onChange={(ev) => setter(conv(ev.currentTarget.value))} value={val} type={type} />
        <FormHelperText id="email-error-text">
          {validationRes && validationRes.fieldValidationMessages(name)}
        </FormHelperText>
      </FormControl>
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      <form
        className={classes.form}
        onSubmit={(ev) => {
          if (file) {
            saveFile(source, patientId, bodyPart, seqType, modality, imgNo, file);
          }
          ev.preventDefault();
        }}
      >
        {getField("source", "Forras adatbazis", source, setSource, validationResult, "text", (s) => s)}
        {getField("patientId", "Beteg azonosito", patientId, setPatientId, validationResult, "text", (s) => s)}
        {getField("bodyPart", "Testresz", bodyPart, setBodyPart, validationResult, "text", (s) => s)}
        {getField("seqType", "Szekvencia tipus", seqType, setSeqType, validationResult, "text", (s) => s)}
        {getField("modality", "Modalitas", modality, setModality, validationResult, "text", (s) => s)}
        {getField("images_no", "Kepek szama", imgNo, setImgNo, validationResult, "number", (s) => Number.parseInt(s))}

        {file ? (
          <TextField
            label="File"
            value={file.name}
            disabled={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {currTask ? (
                    currTask.state === "completed" ? (
                      <IconButton
                        onClick={() => {
                          clearForm();
                          dismissTask(currTask.id);
                        }}
                        aria-label="Dismiss"
                      >
                        <CheckIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => cancelTask(currTask.id)} aria-label="Cancel">
                        <CancelIcon />
                      </IconButton>
                    )
                  ) : (
                    <IconButton onClick={() => setFile(undefined)} aria-label="Clear">
                      <DeleteIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <DropzoneArea
            onChange={(files) => {
              if (!source && !patientId && !bodyPart && !seqType) {
                const res = /^([^_]+)_([^_]+)_([^_]+)_(.+)\.\w+/.exec(files[0].name);
                if (res) {
                  setSource(res[1]);
                  setPatientId(res[2]);
                  setBodyPart(res[3]);
                  setSeqType(res[4]);
                }
              }
              setFile(files[0]);
            }}
            filesLimit={1}
            showPreviews={false}
            showPreviewsInDropzone={false}
            maxFileSize={1000000000000000000000000000000000000000000000000000000000000}
            acceptedFiles={["", "application/dicom", "application/zip", "application/x-zip-compressed"]}
          />
        )}
        {currTask !== undefined ? (
          <LinearProgress variant="determinate" value={currTask.progress} title="Feltoltes..." />
        ) : (
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.submitButton}
            disabled={!!currTask}
          >
            Feltolt
          </Button>
        )}
      </form>
    </Container>
  );
};
