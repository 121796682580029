import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  CircularProgress,
  ListItem,
  ListItemText,
  List,
  Button,
  ListItemIcon,
  IconButton,
  Tabs,
  Tab,
  InputAdornment,
  Typography,
  Box,
  ListItemSecondaryAction,
  Modal,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import { MultiChipInput } from "../MultiChipInput";
import { useStyles } from "./workpackEditor.style";

import RefreshIcon from "@material-ui/icons/Refresh";
import FileIcon from "@material-ui/icons/FileCopy";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { History } from "history";

import { LoadingView } from "../loadingView.component";
import { Workflow, workflowListQuery } from "../workflow/workflow.component";

export const WorkpackEditorView: React.FC<{ history: History }> = ({ history }) => {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [selectedSequenceTypes, setSelectedSequenceTypes] = useState<string[]>([]);
  const [patientFilterStart, setPatientFilterStart] = useState("");
  const [count, setCount] = useState(50);
  const [workflow, setWorkflow] = useState<string | undefined>();

  const id = history.location.hash.replace(/^#/, "");

  const { data: workflowList } = useQuery<{ listWorkflows: Workflow[] }>(workflowListQuery);

  const origPackQuery = useQuery(
    gql`
      query workpack($id: String!) {
        workpack(packId: $id) {
          id
          name
          sequenceTypes
          labelingModes {
            name
            labels
            defaultLabel
          }
          workflowName
          patients {
            id
          }
        }
      }
    `,
    {
      variables: {
        id,
      },
    }
  );

  const { data, networkStatus, refetch } = useQuery(
    gql`
      query wpEditor($min: String!, $count: Int!, $types: [String!]!) {
        patients(min: $min, count: $count, minTypes: $types) {
          id
          sequenceTypes
          source
        }
        sequenceTypes
      }
    `,
    {
      variables: {
        min: patientFilterStart === "" ? "-" : patientFilterStart,
        count,
        types: selectedSequenceTypes,
      },
    }
  );

  const [createWorkpack, { loading: mutationLoading }] = useMutation<{ createPack: { id: string } }>(gql`
    mutation createPack($name: String!, $patientIds: [String!]!, $sequenceTypes: [String!]!, $workflow: String!) {
      createPack(
        packData: { name: $name, patientIds: $patientIds, sequenceTypes: $sequenceTypes, workflowName: $workflow }
      ) {
        id
      }
    }
  `);

  useEffect(() => {
    if (id && origPackQuery && origPackQuery.data) {
      const pack = origPackQuery.data.workpack;
      setWorkflow(origPackQuery.data.workflowName);
      setName(pack.name);
      setSelectedSequenceTypes(pack.sequenceTypes);
      const maxId = pack.patients.map((p) => p.id).reduce((c: string, m: string) => (c > m ? c : m), "");
      try {
        const counter = Number.parseInt(maxId.substr(-11));
        if (Number.isFinite(counter)) {
          setPatientFilterStart((counter + 1).toString());
        } else {
          setPatientFilterStart(maxId.substr(-11));
        }
      } catch {
        setPatientFilterStart(maxId.substr(-11));
      }

      setCount(pack.patients.length);
    }
  }, [id, origPackQuery, setName, setSelectedSequenceTypes, setPatientFilterStart, setCount]);

  const sequenceTypes = data && data.sequenceTypes;
  const patients = data && data.patients;

  useEffect(() => {
    if (sequenceTypes) {
      setSelectedSequenceTypes((ol) => (ol.length === 0 ? sequenceTypes : ol));
    }
  }, [sequenceTypes, setSelectedSequenceTypes]);

  if (!data) {
    return <LoadingView />;
  }

  return (
    <Container component="main" maxWidth="md">
      <Modal open={!!id && !origPackQuery.data}>
        <CircularProgress />
      </Modal>
      <div className={classes.head}>
        <Typography variant="h3">Munkacsomag összeállítása</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            !mutationLoading &&
            createWorkpack({
              variables: {
                name,
                patientIds: patients.map((p) => p.id),
                sequenceTypes: selectedSequenceTypes,
                workflow,
              },
            }).then((res) => {
              history.push(`/workpacks/${res.data!.createPack!.id}`);
            })
          }
        >
          {mutationLoading ? <CircularProgress /> : "Ment"}
        </Button>
      </div>

      <TextField
        className={classes.workflowSelect}
        label="Név"
        onChange={(ev) => setName(ev.currentTarget.value)}
        value={name}
        margin="normal"
      />
      <br />

      <FormControl className={classes.workflowSelect}>
        <InputLabel>Munkafolyamat</InputLabel>
        <Select
          value={workflow}
          onChange={(ev) => {
            setWorkflow(ev.target.value as string);
          }}
        >
          {workflowList?.listWorkflows.map((workflow) => (
            <MenuItem key={`menu_wf_${workflow.id}`} value={workflow.id}>
              {workflow.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <h2>Szekvenciak</h2>
      <MultiChipInput
        label="Szekvencia tipusok"
        classes={{ wrapper: classes.chipInputWrapper, chip: classes.chipInputChip }}
        selectedChanged={(selection: string[]) => setSelectedSequenceTypes(selection)}
        defaultSelectedItems={
          selectedSequenceTypes && selectedSequenceTypes.map((a) => ({ key: a, label: a, value: a }))
        }
        getSuggestions={(val: string) =>
          sequenceTypes &&
          sequenceTypes
            .filter((a) => a.toLocaleLowerCase().includes(val.toLocaleLowerCase()))
            .map((a) => ({ key: a, label: a, value: a }))
        }
      />

      <div className={classes.searchBar}>
        <TextField
          fullWidth={false}
          label="Első sorszám"
          onChange={(ev) => setPatientFilterStart(ev.currentTarget.value)}
          value={patientFilterStart}
          margin="normal"
        />
        <TextField
          fullWidth={false}
          label="Darabszám"
          type="number"
          onChange={(ev) => setCount(Number.parseInt(ev.currentTarget.value))}
          value={count}
          margin="normal"
        />
        <IconButton onClick={() => refetch()}>
          {networkStatus < 7 ? (
            <CircularProgress size={16} />
          ) : (
            <RefreshIcon cursor="pointer" onClick={() => refetch()} />
          )}
        </IconButton>
      </div>
      <List className={classes.gridList} dense={true}>
        {/* <FlipMove typeName={null}> */}
        {patients.map((p) => {
          return (
            <ListItem key={p.id}>
              <ListItemIcon>
                <FileIcon />
              </ListItemIcon>
              <ListItemText primary={p.id + " - " + p.source} secondary={p.sequenceTypes.join(", ")} />
            </ListItem>
          );
        })}
        {/* </FlipMove> */}
      </List>
    </Container>
  );
};
