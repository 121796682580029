import { connect } from "react-redux";

import { OrthopredState } from "../../state/reducers";
import { fileUpload } from "../../state/file/file.actions";

import { UploadComponent } from "./upload.component";
import { cancelTask, dismissTask } from "../../state/task/task.actions";

export const UploadContainer = connect(
  (state: OrthopredState) => {
    return {
      tasks: state.task.tasks,
    };
  },
  (dispatch) => {
    return {
      saveFile: (
        source: string,
        patientId: string,
        bodyPart: string,
        seqType: string,
        modality: string,
        imgNo: number,
        file: File
      ) => {
        dispatch(fileUpload.request({ source, patientId, bodyPart, modality, imgNo, seqType, file }));
      },
      cancelTask: (id: string) => dispatch(cancelTask({ id })),
      dismissTask: (id: string) => dispatch(dismissTask({ id })),
    };
  }
)(UploadComponent);
