import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    head: {
      display: "flex",
      justifyContent: "space-between",
    },
    userList: {
      display: "flex",
      flexDirection: "row",
      padding: "8px 12px",
    },

    chipInputWrapper: {
      flexGrow: 1,
      minWidth: theme.spacing(60),
    },
    chipInputChip: {
      margin: theme.spacing(0.5),
    },
    searchBar: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    gridList: {
      display: "grid",
      gridAutoRows: "max-content",
      textAlign: "left",
      padding: theme.spacing(1),
      gridGap: theme.spacing(1),
      [theme.breakpoints.up("xs")]: {
        gridTemplateColumns: "1fr",
      },
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "1fr 1fr",
      },
      [theme.breakpoints.up("md")]: {
        gridTemplateColumns: "1fr 1fr 1fr",
      },
      [theme.breakpoints.up("lg")]: {
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
      },
    },
    gridTile: {
      "&>h5": {
        display: "inline-block",
      },
    },
    workflowSelect: {
      width: theme.spacing(30),
    },
  })
);
