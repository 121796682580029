import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterInput: {
      minWidth: theme.spacing(40),
    },
    progressListItem: {
      textAlign: "center",
    },
  })
);
