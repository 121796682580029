import { connect } from "react-redux";

import { OrthopredState } from "../../state/reducers";

import { SettingsView } from "./settings.component";
import { IImageInfoDisplaySettings } from "../../state/session/session.state";
import { saveImageInfoDisplaySettings } from "../../state/session/session.actions";

export const SettingsContainer = connect(
  (state: OrthopredState) => {
    return {
      settings: state.loginState.imageInfoDisplaySettings,
    };
  },
  dispatch => {
    return {
      saveSettings: (settings: IImageInfoDisplaySettings) => {
        dispatch(saveImageInfoDisplaySettings.request(settings));
      },
    };
  }
)(SettingsView);
