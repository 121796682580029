import React, { useState, useEffect } from "react";
import { Button, CircularProgress, FormControl, FormHelperText, Input, InputLabel, Container } from "@material-ui/core";

import { LoginStatus } from "../../state/session/session.state";

import "./login.css";
import { ValidationResult } from "../../modules/validation/validationResult";
import { login } from "../../state/session/session.actions";
import { failedAction } from "../../state/errors/error.actions";
import { popMyError } from "../util";
import { BigLogoComponent } from "../logo/bigLogo.component";
import { Redirect } from "react-router";

export interface ILoginViewProps {
  loginStatus: LoginStatus;
  errors: failedAction[];

  checkSession: () => void;
  onSubmit: (email: string, password: string) => void;
  dismissError: (err: failedAction) => void;
}

export const LoginView: React.FC<ILoginViewProps> = ({
  loginStatus,
  errors,
  checkSession,
  onSubmit,
  dismissError,
}: ILoginViewProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationResult, setValidationResult] = useState<ValidationResult | undefined>(undefined);

  useEffect(() => {
    if (loginStatus === LoginStatus.Unknown) {
      checkSession();
    }
  });

  useEffect(() => {
    const e = popMyError(errors, login, dismissError);
    if (e) {
      setValidationResult(e);
      setTimeout(() => {
        if (validationResult === e) {
          setValidationResult(undefined);
        }
      }, 5000);
    }
  }, [errors, dismissError, validationResult]);

  if (loginStatus === LoginStatus.LoggedIn) {
    return <Redirect to="/image" />;
  }

  return (
    <Container component="main" id="loginPage">
      <div className="loginForm">
        <form
          className="loginForm"
          onSubmit={(ev) => {
            onSubmit(email, password);
            ev.preventDefault();
          }}
        >
          <BigLogoComponent />
          <FormControl required={true} error={validationResult && validationResult.isFieldInvalid("email")}>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input name="email" onChange={(ev) => setEmail(ev.currentTarget.value)} value={email} />
            <FormHelperText id="email-error-text">
              {validationResult && validationResult.fieldValidationMessages("email")}
            </FormHelperText>
          </FormControl>

          <FormControl required={true} error={validationResult && validationResult.isFieldInvalid("password")}>
            <InputLabel htmlFor="password">Jelszó</InputLabel>
            <Input
              id="password"
              value={password}
              type="password"
              onChange={(ev) => setPassword(ev.currentTarget.value)}
            />
            <FormHelperText id="password-error-text">
              {validationResult && validationResult.fieldValidationMessages("password")}
            </FormHelperText>
          </FormControl>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth={true}
            disabled={loginStatus === LoginStatus.LoggingIn}
          >
            {(loginStatus === LoginStatus.LoggingIn && <CircularProgress size={24} />) || "Bejelentkezés"}
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default LoginView;
