import { connect } from "react-redux";
import { match } from "react-router";

import { OrthopredState } from "../../state/reducers";

import { UserManagementViewComponent } from "./userManagement.component";
import { listUsers, setPermissionForUser, setUserFilePermissionForUser } from "../../state/admin/admin.actions";

import { PermissionAction } from "../../dtos/enums/permissionAction";
import { UserPermission } from "../../dtos/enums/userPermissions";
import { UserFilePermission } from "../../dtos/enums/userFilePermissions";

export const UserManagementViewContainer = connect(
  (state: OrthopredState, ownProps: { match: match<{ email: string }> }) => {
    return {
      user: state.admin.userList.users.find(u => u.email === ownProps.match.params.email),
      userList: state.admin.userList,

      sequenceTypes: state.admin.sequenceTypeList,
      sequenceTypesState: state.admin.sequenceTypeListState,

      fileList: state.admin.fileList,
    };
  },
  dispatch => {
    return {
      loadUsers: (cursor: number) => {
        dispatch(listUsers.request({ cursor }));
      },

      setUserPermission: (email: string, permission: UserPermission, value: boolean) => {
        dispatch(
          setPermissionForUser.request({
            email,
            permission,
            action: value ? PermissionAction.Add : PermissionAction.Remove,
          })
        );
      },
      setUserFilePermission: (email: string, permission: UserFilePermission, value: boolean) => {
        dispatch(
          setUserFilePermissionForUser.request({
            email,
            permission,
            action: value ? PermissionAction.Add : PermissionAction.Remove,
          })
        );
      },
    };
  }
)(UserManagementViewComponent);
