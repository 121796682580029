import React from "react";
import RefreshIcon from "@material-ui/icons/Refresh";

import { CircularProgress, makeStyles, Theme, createStyles, IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    title: {
      display: "flex",
      justifyContent: "space-between",
    },
    iconWrapper: {
      display: "inline-block",
      position: "relative",
      margin: theme.spacing(1),
    },
    iconProgress: {
      position: "absolute",
      top: 1,
      left: 1,
      zIndex: 1,
    },
  });
});

export const ProgressFab: React.SFC<{ onClick: () => void; isWorking: boolean }> = ({ onClick, isWorking }) => {
  const classes = useStyles();
  return (
    <div className={classes.iconWrapper}>
      <IconButton aria-label="Refresh" color="primary" onClick={onClick} disabled={isWorking} size="small">
        <RefreshIcon />
      </IconButton>
      {isWorking && <CircularProgress size={28} className={classes.iconProgress} />}
    </div>
  );
};
