async function digestMessage(message: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hash = await crypto.subtle.digest('SHA-256', data);
  return new Uint32Array(hash);
}

function isIterable<T>(obj: any): obj is Iterable<T> {
  return obj && typeof obj[Symbol.iterator] === 'function'
}

function toArrayWithDefault<T>(opt: T | T[] | undefined, def: T[]) {
  return opt
    ? (isIterable(opt)
      ? Array.from(opt)
      : [opt])
    : def;
}

function hslToRGB([h, s, l]: [number, number, number]): [number, number, number] {
  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs((h / 60) % 2 - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c; g = x; b = 0;
  } else if (60 <= h && h < 120) {
    r = x; g = c; b = 0;
  } else if (120 <= h && h < 180) {
    r = 0; g = c; b = x;
  } else if (180 <= h && h < 240) {
    r = 0; g = x; b = c;
  } else if (240 <= h && h < 300) {
    r = x; g = 0; b = c;
  } else if (300 <= h && h < 360) {
    r = c; g = 0; b = x;
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return [r, g, b];
}

type hashFunc = (str: string) => { [ind: number]: number } | Promise<{ [ind: number]: number }>;

export class ColorHasher {
  hash: hashFunc;
  L: number[];
  S: number[];
  hueRanges: { min: number; max: number; }[];

  constructor(options: { hue?: number | { min: number; max: number; }[], lightness?: number | number[], saturation?: number | number[], hash?: hashFunc } = {}) {
    this.hash = options.hash || digestMessage;

    this.L = toArrayWithDefault(options.lightness, [0.35, 0.5, 0.65]);
    this.S = toArrayWithDefault(options.saturation, [0.35, 0.5, 0.65]);

    let hue: { min: number; max: number; }[];

    switch (typeof options.hue) {
      case "number":
        hue = [{ min: options.hue, max: options.hue }];
        break;
      case "object":
        hue = isIterable(options.hue) ? Array.from(options.hue) : [options.hue];
        break;
      case "undefined":
      default:
        hue = [{ min: 0, max: 360 }];
    }
    this.hueRanges = hue.map(range => {
      return {
        min: range.min || 0,
        max: range.max || 360,
      };
    });
  }

  async hsl(message: string): Promise<[number, number, number]> {
    const digest = await this.hash(message);
    const range = this.hueRanges[digest[0] % this.hueRanges.length];
    const h = (digest[1] % (range.max - range.min)) + range.min;
    const s = this.S[digest[2] % this.S.length];
    const l = this.L[digest[3] % this.L.length];

    return [h, s, l];
  }

  async rgb(str: string): Promise<[number, number, number]> {
    const hsl = await this.hsl(str);
    return hslToRGB(hsl);
  }

  async hex(str: string): Promise<string> {
    const rgb = await this.rgb(str);
    return rgb.reduce((a, c) => a + c.toString(16).padStart(2, "0"), "#");
  }
}
