import { connect } from "react-redux";

import { OrthopredState } from "../../state/reducers";

import { NotValidatedComponent } from "./notValidated.component";
import { checkSession } from "../../state/session/session.actions";

export const NotValidatedContainer = connect(
  (state: OrthopredState) => {
    return {
      permissions: state.loginState.userIdentity && state.loginState.userIdentity.userPermissions,
    };
  },
  dispatch => {
    return {
      checkSession: () => {
        dispatch(checkSession.request());
      },
    };
  }
)(NotValidatedComponent);
