import dwv from "dwv";
import Konva from "konva/konva";

/**
 * Drawing tool.
 *
 * This tool is responsible for the draw layer group structure. The layout is:
 *
 * drawLayer
 * |_ positionGroup: name="position-group", id="slice-#_frame-#""
 *    |_ shapeGroup: name="{shape name}-group", id="#"
 *       |_ shape: name="shape"
 *       |_ label: name="label"
 *       |_ extra: line tick, protractor arc...
 *
 * Discussion:
 * - posGroup > shapeGroup
 *    pro: slice/frame display: 1 loop
 *    cons: multi-slice shape splitted in positionGroups
 * - shapeGroup > posGroup
 *    pros: more logical
 *    cons: slice/frame display: 2 loops
 *
 * @constructor
 * @param {Object} app The associated application.
 * @external Konva
 */
export class DrawTool {
  public drawLayer: any;

  private defaultLabel: number;
  private started: boolean;
  private tmpShapeGroup: any;
  private points: any[];
  private lastPoint: any;
  private shapeEditor: any;
  private trash: any;
  private style: any;
  private listeners: {
    [type: string]: Array<(ev: any) => void>;
  };
  private timer: any;
  private boundFireEvent: (event: any) => void;
  private canCreateNew: boolean;

  constructor(private app: any, private currentFactory: any, private defaultColor: string) {
    this.boundFireEvent = this.fireEvent.bind(this);

    this.canCreateNew = false;
    this.started = false;
    this.points = [];
    this.lastPoint = null;
    this.shapeEditor = new dwv.tool.ShapeEditor(app);
    this.shapeEditor.setDrawEventCallback(this.boundFireEvent);
    this.trash = new Konva.Group({
      id: "trash",
    });

    // first line of the cross
    const trashLine1 = new Konva.Line({
      points: [-10, -10, 10, 10],
      stroke: "red",
    });
    // second line of the cross
    const trashLine2 = new Konva.Line({
      points: [10, -10, -10, 10],
      stroke: "red",
    });
    this.trash.width(20);
    this.trash.height(20);
    this.trash.add(trashLine1);
    this.trash.add(trashLine2);

    this.style = new dwv.html.Style();

    this.listeners = {};

    this.drawLayer = null;
    this.defaultLabel = 0;
  }

  /**
   * Handle mouse down event.
   * @param {Object} event The mouse down event.
   */
  public mousedown(event: any) {
    // exit if a draw was started (handle at mouse move or up)
    if (this.started) {
      return;
    }

    // determine if the click happened in an existing shape
    const stage = this.app.getDrawStage();
    const kshape = stage.getIntersection({
      x: event._xs,
      y: event._ys,
    });

    if (kshape) {
      const group = kshape.getParent();
      const selectedShape = group.find(".shape")[0];
      // reset editor if click on other shape
      // (and avoid anchors mouse down)
      if (selectedShape && selectedShape !== this.shapeEditor.getShape()) {
        this.fireEvent({ type: "draw-selected", id: selectedShape.parent.id() });
      }
    } else {
      this.fireEvent({ type: "draw-selected", id: undefined });
      if (this.canCreateNew) {
        this.disableEdit();

        // start storing points
        this.started = true;
        // clear array
        this.points = [];
        // store point
        this.lastPoint = new dwv.math.Point2D(event._x, event._y);
        this.points.push(this.lastPoint);
      }
      // disable edition
    }
  }

  /**
   * Handle mouse move event.
   * @param {Object} event The mouse move event.
   */
  public mousemove(event: any) {
    // exit if not started draw
    if (!this.started) {
      return;
    }

    // draw line to current pos
    if (Math.abs(event._x - this.lastPoint.getX()) > 0 || Math.abs(event._y - this.lastPoint.getY()) > 0) {
      // clear last added point from the list (but not the first one)
      // if it was marked as temporary
      if (this.points.length !== 1 && typeof this.points[this.points.length - 1].tmp !== "undefined") {
        this.points.pop();
      }
      // current point
      this.lastPoint = new dwv.math.Point2D(event._x, event._y);
      // mark it as temporary
      this.lastPoint.tmp = true;
      // add it to the list
      this.points.push(this.lastPoint);
      // update points
      this.onNewPoints(this.points);
    }
  }

  /**
   * Handle mouse up event.
   * @param {Object} event The mouse up event.
   */
  public mouseup(_event?: any) {
    // exit if not started draw
    if (!this.started) {
      return;
    }

    // exit if no points
    if (this.points.length <= 1) {
      this.started = false;

      // re-activate layer
      this.drawLayer!.hitGraphEnabled(true);
      this.drawLayer!.draw();
      return;
    }

    // do we have all the needed points
    if (this.points.length === this.currentFactory.getNPoints()) {
      // store points
      this.onFinalPoints(this.points);

      // re-activate layer
      this.drawLayer!.hitGraphEnabled(true);
      this.drawLayer!.draw();
      // reset flag
      this.started = false;
    } else {
      // remove temporary flag
      if (typeof this.points[this.points.length - 1].tmp !== "undefined") {
        delete this.points[this.points.length - 1].tmp;
      }
    }
  }

  /**
   * Handle mouse out event.
   * @param {Object} event The mouse out event.
   */
  public mouseout(event: any) {
    this.mouseup(event);
  }

  /**
   * Handle touch start event.
   * @param {Object} event The touch start event.
   */
  public touchstart(event: any) {
    this.mousedown(event);
  }

  /**
   * Handle touch move event.
   * @param {Object} event The touch move event.
   */
  public touchmove(event: any) {
    // exit if not started draw
    if (!this.started) {
      return;
    }

    if (Math.abs(event._x - this.lastPoint.getX()) > 0 || Math.abs(event._y - this.lastPoint.getY()) > 0) {
      // clear last added point from the list (but not the first one)
      if (this.points.length !== 1) {
        this.points.pop();
      }
      // current point
      this.lastPoint = new dwv.math.Point2D(event._x, event._y);
      // add current one to the list
      this.points.push(this.lastPoint);
      // allow for anchor points
      if (this.points.length < this.currentFactory.getNPoints()) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.points.push(this.lastPoint);
        }, this.currentFactory.getTimeout());
      }
      // update points
      this.onNewPoints(this.points);
    }
  }

  /**
   * Handle key down event.
   * @param {Object} event The key down event.
   */
  public keydown(event: any) {
    this.app.onKeydown(event);

    // press delete key
    if (event.keyCode === 46 && this.shapeEditor.isActive()) {
      // get shape
      const shapeGroup = this.shapeEditor.getShape().getParent();
      const shapeDisplayName = dwv.tool.GetShapeDisplayName(shapeGroup.getChildren(dwv.draw.isNodeNameShape)[0]);
      // delete command
      this.deleteShapeGroup(shapeGroup, shapeDisplayName);
    }
  }

  public deleteDraw(id: string) {
    const rect = this.drawLayer.findOne(`#${id}`);
    if (rect) {
      this.deleteShapeGroup(rect, id);
    }
  }

  /**
   * Enable the tool.
   * @param {Boolean} flag The flag to enable or not.
   */
  public display(flag: boolean) {
    // reset shape display properties
    this.disableEdit();
    // this.fireEvent({ type: "draw-selected", id: undefined });

    // make layer listen or not to events
    this.app.getDrawStage().listening(flag);
    // get the current draw layer
    this.drawLayer = this.app.getDrawController().getDrawLayer();
    this.renderDrawLayer(flag);
    // listen to app change to update the draw layer
    if (flag) {
      this.app.addEventListener("slice-change", this.updateDrawLayer.bind(this));
      this.app.addEventListener("frame-change", this.updateDrawLayer.bind(this));
    } else {
      this.app.removeEventListener("slice-change", this.updateDrawLayer.bind(this));
      this.app.removeEventListener("frame-change", this.updateDrawLayer.bind(this));
    }
  }

  /**
   * Set shape group on properties.
   * @param {Object} shapeGroup The shape group to set on.
   */
  public setShapeOn(shapeGroup: any) {
    // make it draggable
    shapeGroup.draggable(true);
    // cache drag start position
    let dragStartPos = { x: shapeGroup.x(), y: shapeGroup.y() };

    // command name based on shape type
    const shapeDisplayName = dwv.tool.GetShapeDisplayName(shapeGroup.getChildren(dwv.draw.isNodeNameShape)[0]);

    let colour: string | null = null;

    shapeGroup.on("mouseover", () => {
      this.drawLayer.getCanvas()._canvas.style.cursor = "grab";
    });

    shapeGroup.on("onmousedown", () => {
      this.drawLayer.getCanvas()._canvas.style.cursor = "grabbing";
    });

    shapeGroup.on("mouseup", () => {
      this.drawLayer.getCanvas()._canvas.style.cursor = "grab";
    });
    shapeGroup.on("mouseout", () => {
      this.drawLayer.getCanvas()._canvas.style.cursor = "inherit";
    });

    // drag start event handling
    shapeGroup.on("dragstart.draw", (/*event*/) => {
      // store colour
      colour = shapeGroup.getChildren(dwv.draw.isNodeNameShape)[0].stroke();
      // display trash
      const stage = this.app.getDrawStage();
      const scale = stage.scale();
      const invscale = { x: 1 / scale.x, y: 1 / scale.y };
      this.trash.x(stage.offset().x + stage.width() / (2 * scale.x));
      this.trash.y(stage.offset().y + stage.height() / (15 * scale.y));
      this.trash.scale(invscale);
      this.drawLayer!.add(this.trash);
      // deactivate anchors to avoid events on null shape
      this.shapeEditor.setAnchorsActive(false);
      // draw
      this.drawLayer!.draw();
    });
    // drag move event handling
    shapeGroup.on("dragmove.draw", (event: { evt: any }) => {
      // highlight trash when on it
      const offset = dwv.html.getEventOffset(event.evt)[0];
      const eventPos = this.getRealPosition(offset);
      const trashHalfWidth = (this.trash.width() * this.trash.scaleX()) / 2;
      const trashHalfHeight = (this.trash.height() * this.trash.scaleY()) / 2;
      if (
        Math.abs(eventPos.x - this.trash.x()) < trashHalfWidth &&
        Math.abs(eventPos.y - this.trash.y()) < trashHalfHeight
      ) {
        this.trash.getChildren().each((tshape: { stroke: (arg0: string) => void }) => {
          tshape.stroke("orange");
        });
        // change the group shapes colour
        shapeGroup.getChildren(dwv.draw.canNodeChangeColour).forEach((ashape: { stroke: (arg0: string) => void }) => {
          ashape.stroke("red");
        });
      } else {
        this.trash.getChildren().each((tshape: { stroke: (arg0: string) => void }) => {
          tshape.stroke("red");
        });
        // reset the group shapes colour
        shapeGroup
          .getChildren(dwv.draw.canNodeChangeColour)
          .forEach((ashape: { stroke: (arg0: string | null) => void }) => {
            ashape.stroke(colour);
          });
      }
      // draw
      this.drawLayer!.draw();
    });

    // drag end event handling
    shapeGroup.on("dragend.draw", (event: { evt: any }) => {
      const pos = { x: shapeGroup.x(), y: shapeGroup.y() };
      // remove trash
      this.trash.remove();
      // delete case
      const offset = dwv.html.getEventOffset(event.evt)[0];
      const eventPos = this.getRealPosition(offset);
      const trashHalfWidth = (this.trash.width() * this.trash.scaleX()) / 2;
      const trashHalfHeight = (this.trash.height() * this.trash.scaleY()) / 2;
      if (
        Math.abs(eventPos.x - this.trash.x()) < trashHalfWidth &&
        Math.abs(eventPos.y - this.trash.y()) < trashHalfHeight
      ) {
        // compensate for the drag translation
        shapeGroup.x(dragStartPos.x);
        shapeGroup.y(dragStartPos.y);
        // disable editor
        this.disableEdit();
        this.fireEvent({ type: "draw-selected", id: undefined });
        // reset colour
        shapeGroup
          .getChildren(dwv.draw.canNodeChangeColour)
          .forEach((ashape: { stroke: (arg0: string | null) => void }) => {
            ashape.stroke(colour);
          });
        // delete command
        this.deleteShapeGroup(shapeGroup, shapeDisplayName);
      } else {
        // save drag move
        const translation = { x: pos.x - dragStartPos.x, y: pos.y - dragStartPos.y };
        if (translation.x !== 0 || translation.y !== 0) {
          // const mvcmd = new dwv.tool.MoveGroupCommand(shapeGroup, shapeDisplayName, translation, this.drawLayer);
          // mvcmd.onExecute = this.boundFireEvent;
          // mvcmd.onUndo = this.boundFireEvent;
          // this.app.addToUndoStack(mvcmd);

          // mvcmd.execute();
          // the move is handled by Konva, trigger an event manually
          shapeGroup.find("Label").move(translation);
          shapeGroup.find("Rect").move(translation);
          shapeGroup.x(0);
          shapeGroup.y(0);
          this.fireEvent({ type: "draw-move", id: shapeGroup.id() });
        }
        // reset anchors
        this.shapeEditor.setAnchorsActive(true);
        this.shapeEditor.resetAnchors();
      }
      // draw
      this.drawLayer!.draw();
      // reset start position
      dragStartPos = { x: shapeGroup.x(), y: shapeGroup.y() };
    });
  }

  /**
   * Initialise the tool.
   */
  public init() {
    // Noop
  }

  /**
   * Add an event listener on the app.
   * @param {String} type The event type.
   * @param {Object} listener The method associated with the provided event type.
   */
  public addEventListener(type: string, listener: any) {
    if (typeof this.listeners[type] === "undefined") {
      this.listeners[type] = [];
    }
    this.listeners[type].push(listener);
  }

  /**
   * Remove an event listener from the app.
   * @param {String} type The event type.
   * @param {Object} listener The method associated with the provided event type.
   */
  public removeEventListener(type: string, listener: any) {
    if (typeof this.listeners[type] === "undefined") {
      return;
    }
    for (let i = 0; i < this.listeners[type].length; ++i) {
      if (this.listeners[type][i] === listener) {
        this.listeners[type].splice(i, 1);
      }
    }
  }

  /**
   * Set the line colour of the drawing.
   * @param {String} colour The colour to set.
   */
  public setLineColour(colour: string) {
    this.style.setLineColour(colour);
  }

  public setDrawing(flag: boolean) {
    this.canCreateNew = flag;
  }

  public setDefaultLabel(label: number, color: string) {
    this.defaultLabel = label;
    this.defaultColor = color;
  }

  public setSelectedRectangle(id: string) {
    this.disableEdit();

    if (id) {
      const shape = this.drawLayer.findOne(`#${id}`);
      if (shape && shape.findOne(".shape")) {
        const shapeNode = shape.findOne(".shape");
        this.shapeEditor.setShape(shapeNode);
        this.shapeEditor.setImage(this.app.getImage());
        this.shapeEditor.enable();
        // shape.findOne(".shape").fill("rgba(106, 203, 21, 1)");
      }
    }
  }

  public hasShapeSelected(): boolean {
    return !!this.shapeEditor.getShape();
  }

  public addLabel(label: {
    id: string;
    label: string;
    slice: number;
    rect: [number, number, number, number];
    color: string;
    own: boolean;
  }) {
    const posGroupId = dwv.draw.getDrawPositionGroupId(label.slice, 0);

    let posGroup = this.drawLayer.getChildren(dwv.draw.isNodeWithId(posGroupId))[0];
    if (!posGroup) {
      posGroup = new Konva.Group({
        id: posGroupId,
        name: "position-group",
        visible: false,
      });
      this.drawLayer.add(posGroup);
    }
    if (posGroup.find("#" + label.id).length === 0) {
      const addedLabel = this.addROI(
        [
          new dwv.math.Point2D(label.rect[0], label.rect[1]),
          new dwv.math.Point2D(label.rect[0] + label.rect[2], label.rect[1] + label.rect[3]),
        ],
        label.id,
        label.label.toString(),
        posGroup,
        label.color,
        label.own
      );
      // draw shape command
      const command = new dwv.tool.DrawGroupCommand(addedLabel, "Rectangle", this.drawLayer);
      command.onExecute = this.boundFireEvent;
      command.onUndo = this.boundFireEvent;
      // execute it
      command.execute();
      // save it in undo stack
      this.app.addToUndoStack(command);

      // activate shape listeners
      this.setShapeOn(addedLabel);
    }
  }

  /**
   * Update the draw layer.
   */
  public updateDrawLayer() {
    // activate the draw layer
    this.renderDrawLayer(true);
  }

  private deleteShapeGroup(shapeGroup: any, shapeDisplayName: any) {
    const delcmd = new dwv.tool.DeleteGroupCommand(shapeGroup, shapeDisplayName, this.drawLayer);
    delcmd.onExecute = this.boundFireEvent;
    delcmd.onUndo = this.boundFireEvent;
    delcmd.execute();
    this.app.addToUndoStack(delcmd);
  }

  private disableEdit() {
    const prevShape = this.shapeEditor.getShape();
    if (prevShape) {
      // prevShape.fill("rgba(0,0,0,0)");
    }
    this.shapeEditor.disable();
    this.shapeEditor.setShape(null);
    this.shapeEditor.setImage(null);
  }

  /**
   * Fire an event: call all associated listeners.
   * @param {Object} event The event to fire.
   */
  private fireEvent(event: any) {
    if (typeof this.listeners[event.type] === "undefined") {
      return;
    }
    for (const listener of this.listeners[event.type]) {
      listener(event);
    }
  }

  /**
   * Update the current draw with new points.
   * @param {Array} tmpPoints The array of new points.
   */
  private onNewPoints(tmpPoints: any[]) {
    // remove temporary shape draw
    if (this.tmpShapeGroup) {
      this.tmpShapeGroup.destroy();
    }
    // create shape group
    this.tmpShapeGroup = this.currentFactory.create(tmpPoints, this.style, this.app.getImage());
    // do not listen during creation
    const shape = this.tmpShapeGroup.getChildren(dwv.draw.isNodeNameShape)[0];

    shape.strokeWidth(1);
    shape.listening(false);
    this.drawLayer!.hitGraphEnabled(false);
    // draw shape
    this.drawLayer!.add(this.tmpShapeGroup);
    this.drawLayer!.draw();
  }

  /**
   * Create the final shape from a point list.
   * @param {Array} finalPoints The array of points.
   */
  private onFinalPoints(finalPoints: any[]) {
    // reset temporary shape group
    if (this.tmpShapeGroup) {
      this.tmpShapeGroup.destroy();
    }
    // create final shape
    const finalShapeGroup = this.addROI(
      finalPoints,
      dwv.math.guid(),
      this.defaultLabel.toString(),
      this.app.getDrawController().getCurrentPosGroup(),
      this.defaultColor,
      true
    );

    // draw shape command
    const command = new dwv.tool.DrawGroupCommand(finalShapeGroup, "Rectangle", this.drawLayer);
    command.onExecute = this.boundFireEvent;
    command.onUndo = this.boundFireEvent;
    // execute it
    command.execute();
    // save it in undo stack
    this.app.addToUndoStack(command);

    // activate shape listeners
    this.setShapeOn(finalShapeGroup);
  }

  private addROI(
    finalPoints: any[],
    id: string,
    label: string,
    posGroup: any,
    color: string = this.defaultColor,
    own: boolean = true
  ) {
    const finalShapeGroup = this.currentFactory.create(finalPoints, this.style, this.app.getImage());
    finalShapeGroup.addName("LabeledROI");
    finalShapeGroup.id(id);

    for (const c of finalShapeGroup.children) {
      if (!own && c.dash) {
        c.dash([4, 2]);
      }
      if (c.stroke) {
        c.stroke(color);
      }
      if (c.strokeWidth) {
        c.strokeWidth(1);
      }
    }
    const lab = finalShapeGroup.findOne("Label");
    const text = lab.getText();
    text.fontSize(16);
    text.setText(label);
    // text.stroke(color);
    text.textExpr = label;

    const absScale = this.drawLayer.getStage().getAbsoluteScale();
    text.scaleX(text.scaleX() / absScale.x);
    text.scaleY(text.scaleY() / absScale.y);
    lab.move({ x: 4, y: -finalShapeGroup.findOne("Rect").height() - 6 });
    // get the position group
    // add shape group to position group
    posGroup.add(finalShapeGroup);
    return finalShapeGroup;
  }

  /**
   * Render (or not) the draw layer.
   * @param {Boolean} visible Set the draw layer visible or not.
   */
  private renderDrawLayer(visible: boolean) {
    this.drawLayer!.listening(visible);
    this.drawLayer!.hitGraphEnabled(visible);

    // get shape groups at the current position
    const shapeGroups = this.app.getDrawController().getCurrentPosGroup().getChildren();

    // set shape display properties
    if (visible) {
      // activate tool listeners
      this.app.addToolCanvasListeners(this.app.getDrawStage().getContent());
      // activate shape listeners
      shapeGroups.forEach((group: any) => {
        this.setShapeOn(group);
      });
    } else {
      // de-activate tool listeners
      this.app.removeToolCanvasListeners(this.app.getDrawStage().getContent());
      // de-activate shape listeners
      shapeGroups.forEach((group: any) => {
        this.setShapeOff(group);
      });
    }
    // draw
    this.drawLayer!.draw();
  }

  /**
   * Set shape group off properties.
   * @param {Object} shapeGroup The shape group to set off.
   */
  private setShapeOff(shapeGroup: any) {
    // mouse styling
    shapeGroup.off("mouseover");
    shapeGroup.off("mouseout");
    shapeGroup.off("mousedown");
    shapeGroup.off("mouseup");
    // drag
    shapeGroup.draggable(false);
    shapeGroup.off("dragstart.draw");
    shapeGroup.off("dragmove.draw");
    shapeGroup.off("dragend.draw");
    shapeGroup.off("dblclick");
  }

  /**
   * Get the real position from an event.
   */
  private getRealPosition(index: { x: number; y: number }) {
    const stage = this.app.getDrawStage();
    return { x: stage.offset().x + index.x / stage.scale().x, y: stage.offset().y + index.y / stage.scale().y };
  }
}

const oldUpdateRect = dwv.tool.UpdateRect;
dwv.tool.UpdateRect = (anchor: any, image: any) => {
  const group = anchor.getParent();

  oldUpdateRect(anchor, image);

  const newGroup = group.getParent().findOne(`#${group.id()}`);
  const newRect = newGroup.findOne("Rect");
  const label = newGroup.findOne("Label");

  label.move({ x: 4, y: -newRect.height() - 6 });
};
