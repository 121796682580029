import React, { useState } from "react";
import { Typography, Switch, TextField, Paper, Button } from "@material-ui/core";
import { IInfoLabelSettings } from "../../state/session/session.state";
import { useStyles, useColorPickerStyles } from './infoLabelSettingsCard.styles';
import { AlphaPicker, RGBColor } from 'react-color';

export const InfoLabelSettingsCard: React.FC<{
  title: string;
  isSliceIndicator?: boolean;
  labelSettings: IInfoLabelSettings;
  save: (value: IInfoLabelSettings) => void;
}> = ({ title, isSliceIndicator = false, labelSettings = { x: 256, y: 256, color: "variable", fontSize: 24, visible: false }, save }) => {
  const classes = useStyles();
  const colorPickerClasses = useColorPickerStyles();

  const [x, setX] = useState<number>(labelSettings.x);
  const [y, setY] = useState<number>(labelSettings.y);
  const [fontSize, setFontSize] = useState<number>(labelSettings.fontSize);

  const rgbaCol = rgbaFromString(labelSettings.color);

  const [isVar, setIsVar] = useState<boolean>(rgbaCol.isVar);
  const [r, setR] = useState<number>(rgbaCol.r);
  const [g, setG] = useState<number>(rgbaCol.g);
  const [b, setB] = useState<number>(rgbaCol.b);
  const [a, setA] = useState<number>(rgbaCol.a || 1);

  const colors = ['#FF6900', '#FCB900', '#00D084', '#8ED1FC', '#0693E3', '#EB144C', '#F78DA7', '#9900EF'];

  function rgbaFromString(str: string): RGBColor & { isVar: boolean } {
    if (str.startsWith("variable")) {
      const a = str.length > 8 ? Number.parseFloat(str.substr(8)) : 0.7;
      return { r: 0, g: 0, b: 0, a, isVar: true };
    }

    const rgbaMatch = /(?:RGBA?)\((\d+), ?(\d+), ?(\d+),? ?([0-9.]+)?\)/i.exec(str);
    if (rgbaMatch) {
      return {
        r: Number.parseInt(rgbaMatch[1]),
        g: Number.parseInt(rgbaMatch[2]),
        b: Number.parseInt(rgbaMatch[3]),
        a: rgbaMatch[4] ? Number.parseFloat(rgbaMatch[4]) : undefined,
        isVar: false,
      }
    }
    const start = str.startsWith('#') ? 1 : 0;

    const r = Number.parseInt(str.substr(start, 2), 16);
    const g = Number.parseInt(str.substr(start + 2, 2), 16);
    const b = Number.parseInt(str.substr(start + 4, 2), 16);
    const a = str.length > start + 7 ? Number.parseInt(str.substr(start + 6, 2), 16) / 100 : undefined;

    return {
      r, g, b, a,
      isVar: false,
    };
  }
  return (<Paper className={classes.settingsPaper}>
    <Typography variant="h4" className={classes.labelCardTitle}>
      <span>{title}</span>
      <Switch checked={labelSettings.visible} onClick={() => save({ ...labelSettings, visible: !labelSettings.visible })} color="primary" />
    </Typography>
    <form className={classes.settingsForm}>
      <div className={colorPickerClasses.body}>
        {colors.map(c => {
          const col = rgbaFromString(c);
          const isActive = !isVar && r === col.r && g === col.g && b === col.b;
          return (
            <div key={c} className={colorPickerClasses.swatch + (isActive ? " active" : "")} style={{ background: c }} title={c} onClick={() => {
              setIsVar(false);
              setR(col.r);
              setG(col.g);
              setB(col.b);
            }} />
          )
        })}
        {!isSliceIndicator && (
          <div
            className={colorPickerClasses.swatch + (isVar ? " active" : "")}
            style={{ backgroundImage: "linear-gradient(145deg, red, green)" }}
            onClick={() => setIsVar(true)}
            title="Valtozo" />)
        }
        <div style={{ width: "100%" }} />
        <div className={colorPickerClasses.hash}>R</div>
        <input className={colorPickerClasses.input} type="number" value={!isVar ? r : "*"} placeholder="*" onChange={(e) => { setIsVar(false); setR(e.currentTarget.valueAsNumber) }}></input>
        <div className={colorPickerClasses.hash}>G</div>
        <input className={colorPickerClasses.input} type="number" value={!isVar ? g : "*"} placeholder="*" onChange={(e) => { setIsVar(false); setG(e.currentTarget.valueAsNumber) }}></input>
        <div className={colorPickerClasses.hash}>B</div>
        <input className={colorPickerClasses.input} type="number" value={!isVar ? b : "*"} placeholder="*" onChange={(e) => { setIsVar(false); setB(e.currentTarget.valueAsNumber) }}></input>
        <div className={colorPickerClasses.hash}>A</div>
        <input className={colorPickerClasses.input} type="number" value={a} onChange={(e) => { setIsVar(false); setA(e.currentTarget.valueAsNumber) }}></input>
        <div className={colorPickerClasses.alphaContainer}>
          <AlphaPicker width="100%" color={isVar ? { r: 145, g: 100, b: 200, a } : { r, g, b, a }} onChangeComplete={(e) => setA(e.rgb.a!)}></AlphaPicker>
        </div>
      </div>
      {!isSliceIndicator && <>
        <TextField label="X" type="number" value={x} onChange={(e) => setX(Number.parseInt(e.currentTarget.value, 10))} />
        <TextField label="Y" type="number" value={y} onChange={(e) => setY(Number.parseInt(e.currentTarget.value, 10))} />
        <TextField label="Meret" type="number" value={fontSize} onChange={(e) => setFontSize(Number.parseInt(e.currentTarget.value, 10))} />
      </>}
    </form>
    <Button onClick={e => {
      save({
        visible: labelSettings.visible, x, y, fontSize,
        color: isVar ? `variable${a || 0.7}` : `RGBA(${r},${g},${b},${a || 1})`
      });
    }}
      fullWidth={true} color="primary" variant="contained" className={classes.saveButton}> Ment </Button>
  </Paper>);
};
