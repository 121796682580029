import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    workpackView: {
      align: "left",
    },

    filterInput: {},
    userProgress: {
      width: 40,
      height: 40,
      "& > *": {
        position: "absolute",
        left: theme.spacing(2),
        top: theme.spacing(2),
        width: 40,
        height: 40,
      },
    },
    dialog: {
      overflow: "hidden",
    },
    gridList: {
      display: "grid",
      gridAutoRows: "max-content",
      textAlign: "left",
      padding: theme.spacing(1),
      gridGap: theme.spacing(1),
      [theme.breakpoints.up("xs")]: {
        gridTemplateColumns: "1fr",
      },
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "1fr 1fr",
      },
      [theme.breakpoints.up("md")]: {
        gridTemplateColumns: "1fr 1fr 1fr",
      },
      [theme.breakpoints.up("lg")]: {
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
      },
    },
    gridTile: {
      "&>h5": {
        display: "inline-block",
      },
    },
  })
);
