import { connect } from "react-redux";

import { OrthopredState } from "../../state/reducers";

import { AdminFileListComponent } from "./adminFileList.component";
import {
  adminListFiles,
  setFilePermissionForUser,
  listUsers,
  loadSequenceTypes,
} from "../../state/admin/admin.actions";
import { FilePermission } from "../../dtos/enums/filePermissions";
import { PermissionAction } from "../../dtos/enums/permissionAction";

export const AdminFileListContainer = connect(
  (state: OrthopredState) => {
    return {
      sequenceTypes: state.admin.sequenceTypeList,
      sequenceTypesState: state.admin.sequenceTypeListState,
      fileList: state.admin.fileList,
      userList: state.admin.userList,
    };
  },
  dispatch => {
    return {
      listUsers: (cursor: number) => {
        dispatch(listUsers.request({ cursor }));
      },
      listFiles: (sequenceTypes: string[], emails: string[], loadMore: boolean) => {
        for (const sequenceType of sequenceTypes) {
          dispatch(adminListFiles.request({ sequenceType, emails, loadMore }));
        }
      },
      loadSequenceTypes: () => {
        dispatch(loadSequenceTypes.request({}));
      },
      setFilePermission: (email: string, fileId: string, value: boolean) => {
        dispatch(
          setFilePermissionForUser.request({
            email,
            fileId,
            permission: FilePermission.View,
            action: value ? PermissionAction.Add : PermissionAction.Remove,
          })
        );
      },
    };
  }
)(AdminFileListComponent);
