import { connect } from "react-redux";
import { match } from "react-router";

import { OrthopredState } from "../../state/reducers";

import { FileViewComponent } from "./fileView.component";
import { listFiles, fileDownload } from "../../state/file/file.actions";
import { updateLabel, saveLabels, loadLabels, addLog, setSliceLabel } from "../../state/labels/labeling.actions";
import { ILabelData, ISliceLabelData } from "../../state/labels/labling.reducers";
import { IFileInfo } from "../../state/admin/admin.reducers";

export const FileViewContainer = connect(
  (state: OrthopredState, ownProps: { match: match<{ packId: string; patientId: string }> }) => {
    return {
      packId: ownProps.match.params.packId,
      patientId: ownProps.match.params.patientId,
    };
  },
  (dispatch) => {
    return {
      listFiles: () => {
        dispatch(listFiles.request({ isLoadMore: false }));
      },
      loadFile: (info: IFileInfo) => {
        dispatch(fileDownload.request({ info }));
      },

      setSliceLabel: (label: ISliceLabelData) => {
        dispatch(setSliceLabel(label));
      },
      updateLabel: (label: ILabelData) => {
        dispatch(updateLabel(label));
      },
      saveLabels: () => {
        dispatch(saveLabels.request());
      },
      loadLabels: (fileId: string) => {
        dispatch(loadLabels.request({ fileId }));
      },
      log: (fileId: string, slice: number, message: string) => {
        dispatch(addLog({ fileId, slice, message }));
      },
    };
  }
)(FileViewComponent);
