import { createAsyncAction } from "typesafe-actions";
import { WebApiError } from "../../modules/api/WebApiError";
import { ILoginState, IImageInfoDisplaySettings } from "./session.state";
import { ValidationResult } from "../../modules/validation/validationResult";

export enum SessionActions {
  CheckSession = "CheckSession",
  CheckSessionSuccess = "CheckSessionSuccess",
  CheckSessionFailure = "CheckSessionFailure",

  Login = "Login",
  LoginSuccess = "LoginSuccess",
  LoginFailure = "LoginFailure",

  Logout = "Logout",
  LogoutSuccess = "LogoutSuccess",
  LogoutFailure = "LogoutFailure",

  SaveImageInfoDisplaySettings = "SaveImageInfoDisplaySettings",
  SaveImageInfoDisplaySettingsSuccess = "SaveImageInfoDisplaySettingsSuccess",
  SaveImageInfoDisplaySettingsFailure = "SaveImageInfoDisplaySettingsFailure",
}

export const logout = createAsyncAction(
  SessionActions.Logout,
  SessionActions.LogoutSuccess,
  SessionActions.LogoutFailure
)<void, void, WebApiError>();

export const checkSession = createAsyncAction(
  SessionActions.CheckSession,
  SessionActions.CheckSessionSuccess,
  SessionActions.CheckSessionFailure
)<void, ILoginState, WebApiError>();

export const login = createAsyncAction(SessionActions.Login, SessionActions.LoginSuccess, SessionActions.LoginFailure)<
  { email: string; password: string },
  ILoginState,
  WebApiError | ValidationResult
>();

export const saveImageInfoDisplaySettings = createAsyncAction(
  SessionActions.SaveImageInfoDisplaySettings,
  SessionActions.SaveImageInfoDisplaySettingsSuccess,
  SessionActions.SaveImageInfoDisplaySettingsFailure
)<IImageInfoDisplaySettings, IImageInfoDisplaySettings, Error>();
