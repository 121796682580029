import React from "react";
import { ListItemIcon, ListItemText, Container } from "@material-ui/core";
import { History } from "history";

import OpenIcon from "@material-ui/icons/OpenInBrowser";

import { UserPermission } from "../../dtos/enums/userPermissions";
import { UserSelectComponent } from "../userSelect/userSelect.component";

export const UserListComponent: React.FC<{ history: History }> = ({ history }) => {
  return (
    <Container component="main">
      <UserSelectComponent
        renderListItem={user => {
          return (
            <>
              <ListItemIcon onClick={() => history.push(`/users/${user.email}`)}>
                <OpenIcon />
              </ListItemIcon>
              <ListItemText secondary={user.email}>
                {user.fullName || user.email}
                {user.userPermissions.check(UserPermission.Login) ? "" : "(Nem validalt!)"}
              </ListItemText>
            </>
          );
        }}
      />
    </Container>
  );
};
