import { createAsyncAction } from "typesafe-actions";
import { WebApiError } from "../../modules/api/WebApiError";
import { IFileInfo } from "../admin/admin.reducers";

export enum FileActions {
  Upload = "Upload",
  UploadSuccess = "UploadSuccess",
  UploadFailure = "UploadFailure",

  Download = "Download",
  DownloadSuccess = "DownloadSuccess",
  DownloadFailure = "DownloadFailure",

  ListFiles = "ListFiles",
  ListFilesSuccess = "ListFilesSuccess",
  ListFilesFailure = "ListFilesFailure",

  DeleteDownloadedFile = "DeleteDownloadedFileFiles",
  DeleteDownloadedFileSuccess = "DeleteDownloadedFileFilesSuccess",
  DeleteDownloadedFileFailure = "DeleteDownloadedFileFilesFailure",
}

export const fileUpload = createAsyncAction(FileActions.Upload, FileActions.UploadSuccess, FileActions.UploadFailure)<
  { file: File; source: string; patientId: string; bodyPart: string; seqType: string; modality: string; imgNo: number },
  { fileId: string; source: string; patientId: string; bodyPart: string; seqType: string },
  { source: string; patientId: string; bodyPart: string; seqType: string; err: WebApiError }
>();

export const fileDownload = createAsyncAction(
  FileActions.Download,
  FileActions.DownloadSuccess,
  FileActions.DownloadFailure
)<{ info: IFileInfo }, { info: IFileInfo; blob: Blob }, { fileId: string; err: WebApiError }>();

export const deleteDownloadedFile = createAsyncAction(
  FileActions.DeleteDownloadedFile,
  FileActions.DeleteDownloadedFileSuccess,
  FileActions.DeleteDownloadedFileFailure
)<
  { id: string },
  {
    downloadedFileIds: string[];
    downloadedFiles: Array<{ fileId: string; meta: any; blob: Blob }>;
  },
  { fileId: string; err: WebApiError }
>();

export const listFiles = createAsyncAction(
  FileActions.ListFiles,
  FileActions.ListFilesSuccess,
  FileActions.ListFilesFailure
)<
  { isLoadMore?: boolean },
  {
    files: IFileInfo[];
    cursor: number;
    startCursor: number;
    downloadedFileIds: string[];
    downloadedFiles: Array<{ fileId: string; meta: any; blob: Blob }>;
  },
  WebApiError
>();
