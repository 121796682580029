import { connect } from "react-redux";

import { OrthopredState } from "../../state/reducers";

import { RegistrationComponent } from "./registration.component";

import { LoginStatus } from "../../state/session/session.state";
import { checkSession, login } from "../../state/session/session.actions";
import { registration } from "../../state/registration/registration.actions";
import { dismissError, failedAction } from "../../state/errors/error.actions";

export const RegistrationContainer = connect(
  (state: OrthopredState) => {
    return {
      loginStatus: state.loginState.loginStatus || LoginStatus.Unknown,
      regStatus: state.register.state,
      errors: state.error.errors,
    };
  },
  dispatch => {
    return {
      checkSession: () => {
        dispatch(checkSession.request());
      },
      register: (fullName: string, email: string, password: string) => {
        dispatch(registration.request({ email, password, fullName }));
      },
      login: (email: string, password: string) => {
        dispatch(login.request({ email, password }));
      },
      dismissError: (err: failedAction) => dispatch(dismissError(err)),
    };
  }
)(RegistrationComponent);
