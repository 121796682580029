import React, { useEffect, useState, useContext, useCallback, KeyboardEvent, useMemo, useReducer } from "react";

import {
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Collapse,
  Select,
  Paper,
} from "@material-ui/core";
// import SpeedDial from "@material-ui/lab/SpeedDial";
// import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
// import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import DoneIcon from "@material-ui/icons/Done";

import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { SizeMe } from "react-sizeme";

import { DicomWebViewComponent } from "../dicomImage/dwv.component";
import { useStyles } from "./fileView.style";
import { ILabelData } from "../../state/labels/labling.reducers";
import { IImageInfoDisplaySettings, defaultImageInfoDisplaySettings } from "../../state/session/session.state";
import { useUserFileList, userFileListQuery, IFileListData } from "../../queries/fileList.query";
import { LoadingView } from "../loadingView.component";
import { Redirect } from "react-router";
import { LocalDBContext } from "../../modules/localDb.context";
import { LabelerAutoSaveContext } from "./useAutoSave";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useFileDownloader } from "../../modules/file.hooks";
import { History } from "history";
import { useEventListener } from "../useEventListener";
import { fileViewStateReducer, defaultFileViewState, FileViewActionType, LoadState } from "./fileViewState";
import { GridTile } from "./gridTile.component";

interface IFileViewComponentProps {
  packId: string;
  patientId: string;

  infoLabelSettings: IImageInfoDisplaySettings;
  history: History;
}

const labelColors = [
  "#FF6900",
  "#FCB900",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#EB144C",
  "#F78DA7",
  "#9900EF",
  "green",
  "blue",
  "orange",
  "brown",
  "gray",
  "white",
  "white",
  "white",
  "white",
  "white",
  "white",
  "white",
  "white",
  "white",
  "white",
];

function startDownload(filename, text) {
  const element = document.createElement("a");
  element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export const FileViewComponent: React.FC<IFileViewComponentProps> = ({
  packId,
  patientId,

  history,
}) => {
  const classes = useStyles();

  const { data, called, loading, error } = useUserFileList();

  const progress = data && data.me.currentProgresses.find((p) => p.workpack && p.workpack.id === packId);
  const patient = progress && progress.workpack.patients.find((p) => p.id === patientId);
  const patientProgress = progress && progress.patientProgresses.find((p) => p.id === patientId);
  const currentStepInd = (patientProgress && patientProgress.currentStep) || 0;
  const workflow = progress && progress.workpack.workflow;
  const currentStep = workflow && workflow[currentStepInd || 0];
  const labelModes = progress && progress.workpack.labelingModes;

  const autoSaveContext = useContext(LabelerAutoSaveContext)!;
  const [tasks, downloadFile] = useFileDownloader();

  const localDb = useContext(LocalDBContext);

  const [note, setNote] = useState("");

  const [state, dispatch] = useReducer(fileViewStateReducer, {
    ...defaultFileViewState,
    localDb,
    autoSaveContext,
  });

  const downloadSummary = () => {
    const summary: { [str: string]: string } = {
      workpackId: packId,
      patientId,
      note,
    };
    if (patient && state.labelModesWithColor) {
      for (const mode of state.labelModesWithColor) {
        summary[mode.name] = Math.max(
          ...patient.files.map((f) =>
            Math.max(
              ...(state.fileInfos
                .get(f.id)
                ?.labels.map((label) => (label.labelMode === mode.name ? Number.parseInt(label.label, 10) : 0)) || [0])
            )
          ),
          0
        ).toString(10);
      }
      startDownload(`${patientId}.json`, JSON.stringify(summary));
    }
  };

  const [getFileInfos, fileInfos] = useLazyQuery<{
    fileInfos: Array<{ id: string; labels: ILabelData[]; sliceLabels: number[] }>;
  }>(gql`
    query fileInfo($fileIds: [String!]!) {
      fileInfos(ids: $fileIds) {
        id
        labels {
          id
          label
          labelMode
          rect
          slice
          creator
        }
        sliceLabels
      }
    }
  `);

  const [saveCurrentPatient] = useMutation(gql`
    mutation saveCurrentPatient($packId: String!, $currentPatient: String!) {
      saveCurrentPatient(packId: $packId, currentPatient: $currentPatient) {
        id
        currentPatient
      }
    }
  `);

  const [completeStep] = useMutation(
    gql`
      mutation completeStep($packId: String!, $currentPatient: String!, $step: Int!, $nextStep: Int!, $rev: Boolean) {
        completeStep(packId: $packId, currentPatient: $currentPatient, step: $step, nextStep: $nextStep, revert: $rev) {
          id
          patientProgresses {
            id
            currentStep
            completedSteps
          }
          completedPatientIds
        }
      }
    `,
    {
      update: (store, { data: res }) => {
        if (res && res.completeStep) {
          const data = store.readQuery<IFileListData>({
            query: userFileListQuery,
          });

          if (data && data.me) {
            const prog = data.me.currentProgresses.find((prog) => prog.workpack.id === packId);
            if (prog) {
              prog.completedPatientIds = res.completeStep.completedPatientIds;

              // Write our data back to the cache.
              store.writeQuery({
                query: userFileListQuery,
                data,
              });
            }
          }
        }
      },
    }
  );

  useEffect(() => {
    if (state.patientId !== patientId && patient && progress) {
      dispatch({
        type: FileViewActionType.ResetPatient,
        patientId,
        packId,
        selectedTypes: progress.workpack.sequenceTypes,
        displaySettings: localDb.getImageInfoDisplaySettings(data!.me.email) || defaultImageInfoDisplaySettings,
        steps: progress.workpack.workflow,
      });
      saveCurrentPatient({ variables: { packId, currentPatient: patientId } });

      const sortedPatients = progress.workpack.patients.sort((a, b) => a.id.localeCompare(b.id));
      const ind = sortedPatients.indexOf(patient);
      for (const type of progress.workpack.sequenceTypes) {
        const file = patient.files.find((f) => f.seqType === type);
        getFileInfos({
          variables: {
            fileIds: patient.files.map((f) => f && f.id),
          },
        });
        if (file) {
          downloadFile(file.id, patient.id, type, patient);
        }
      }

      const patientN = ind !== -1 ? sortedPatients[(ind + 1) % progress.workpack.patients.length] : undefined;
      const patientNN = ind !== -1 ? sortedPatients[(ind + 2) % progress.workpack.patients.length] : undefined;
      for (const type of progress.workpack.sequenceTypes) {
        if (patientN && patient !== patientN) {
          const fileN = patientN.files.find((f) => f.seqType === type);
          if (fileN) {
            downloadFile(fileN.id, patient.id, type, patient);
          }
        }
        if (patientNN && patient !== patientNN) {
          const fileNN = patientNN.files.find((f) => f.seqType === type);
          if (fileNN) {
            downloadFile(fileNN.id, patient.id, type, patient);
          }
        }
      }
    }
  }, [patientId, state, dispatch, patient, progress, downloadFile, getFileInfos, packId, saveCurrentPatient]);

  useEffect(() => {
    if (patient && fileInfos.data && state.patientId === patient.id && state.loadState === LoadState.Loading) {
      const infos = patient.files
        .map((f) => fileInfos.data?.fileInfos.find((fileInfo) => fileInfo.id === f.id)!)
        .filter((f) => !!f);
      setNote(patient.notes.find((n) => n.email === data?.me.email)?.note || "");
      dispatch({ type: FileViewActionType.LoadFileInfos, infos });
    }
  }, [dispatch, patient, fileInfos, packId, localDb, setNote]);

  useEffect(() => {
    if (progress && currentStep && currentStep.labelingModes && state.loadState !== LoadState.None) {
      const labelModesWithColor = (
        currentStep.labelingModes.map((name) => labelModes?.find((mode) => mode.name === name)) || []
      )
        .filter((f) => !!f)
        .map((m, i) => ({
          name: m!.name,
          defaultLabel: m!.defaultLabel,
          color: labelColors[i],
        }));
      dispatch({ type: FileViewActionType.SetStep, step: currentStepInd, labelModesWithColor });
    }
  }, [progress, currentStep, localDb, currentStepInd, labelModes, state.loadState]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (tasks) {
        for (const t of tasks.successes) {
          const info = state.fileInfos.get(t.id);
          if (info && !info.storedFile) {
            localDb
              .getStoredFile(info.id)
              .then((storedInfo) => dispatch({ type: FileViewActionType.LoadStoredFile, fileId: info.id, storedInfo }));
            return;
          }
        }
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [tasks, state, localDb]);

  const keyListener = useCallback(
    (e: KeyboardEvent) => {
      if (!progress || !patient) {
        return;
      }
      const sortedPatients = progress.workpack.patients.sort((a, b) => a.id.localeCompare(b.id));
      const ind = sortedPatients.indexOf(patient);

      const next = (doComplete: boolean) => {
        if (doComplete) {
          downloadSummary();
          completeStep({
            variables: {
              packId: packId,
              currentPatient: patientId,
              step: currentStepInd,
              nextStep: Math.min(currentStepInd + 1, progress.workpack.workflow.length - 1),
            },
          });
        }
        if (!doComplete || progress.workpack.workflow.length - 1 === currentStepInd) {
          const patientN = ind !== -1 ? sortedPatients[ind + 1] : undefined;

          if (patientN) {
            history.push(`/images/${packId}/${patientN.id}`);
          }
        }
      };

      const prev = (doReverse: boolean) => {
        if (doReverse) {
          completeStep({
            variables: {
              packId: packId,
              currentPatient: patientId,
              step: currentStepInd,
              nextStep: Math.max(currentStepInd - 1, 0),
              rev: true,
            },
          });
        }

        if (!doReverse || currentStepInd === 0) {
          const patientP = ind > 0 ? sortedPatients[ind - 1] : undefined;

          if (patientP) {
            history.push(`/images/${packId}/${patientP.id}`);
          }
        }
      };
      switch (e.key) {
        case "N":
        case "n":
          next(e.ctrlKey);
          break;
        case "PageDown":
          next(e.shiftKey);
          break;
        case "B":
        case "b":
          prev(e.ctrlKey);
          break;
        case "PageUp":
          prev(e.shiftKey);
          break;
      }
    },
    [progress, patient, history, packId, patientId, completeStep, currentStepInd]
  );

  useEventListener("keyup", keyListener, window);

  function saveNote(note: string) {
    setNote(note);
    autoSaveContext.setNote(patientId, note);
  }

  if (!called || loading) {
    return <LoadingView />;
  }

  if (!data || error) {
    return <main>{JSON.stringify(error)}</main>;
  }

  if (!progress || !patient || !labelModes) {
    return <Redirect to="/images" />;
  }

  return (
    <main className={classes.fileView}>
      <SizeMe monitorHeight={true} refreshMode="debounce" refreshRate={200}>
        {({ size }) => {
          const rowHeight = Math.min(4, ((size.height || 0) - 400) / 200);
          const layout =
            localDb.getPreferredLayout(state.selectedTypes.length) ||
            (state.selectedTypes.length > 2
              ? [
                  { i: "file0", w: 30, h: 99, x: 0, y: 0 },
                  { i: "file1", w: 30, h: 99, x: 0, y: 61 },
                  { i: "file2", w: 60, h: 200, x: 35, y: 0 },
                  { i: "info", w: 20, h: 200, x: 100, y: 0 },
                ]
              : [
                  { i: "file0", w: 50, h: 200, x: 0, y: 0 },
                  { i: "file1", w: 50, h: 200, x: 50, y: 0 },
                  { i: "info", w: 20, h: 200, x: 100, y: 0 },
                ]);

          return (
            <div className={classes.grid}>
              <GridLayout
                layout={layout}
                compactType={null}
                preventCollision={true}
                cols={120}
                rowHeight={rowHeight}
                margin={[2, 2]}
                containerPadding={[0, 0]}
                width={size.width || 0}
                onLayoutChange={(l) => size.height && localDb.savePreferredLayout(state.selectedTypes.length, l)}
                draggableHandle=".titleBar"
                autoSize={false}
              >
                {state.selectedTypes.map((type, ind) => {
                  const file = patient && patient.files.find((a) => a.seqType === type);

                  return (
                    file && (
                      <Paper key={`file${ind}`} className={classes.fileViewTile}>
                        <GridTile
                          gridKey={`file${ind}`}
                          layout={layout}
                          title={
                            state.selectedTypes.length > 1 ? (
                              <Select
                                value={type}
                                onChange={(ev) => {
                                  dispatch({
                                    type: FileViewActionType.MoveSelectedType,
                                    slot: ind,
                                    seqType: ev.target.value as string,
                                  });
                                }}
                                margin="dense"
                              >
                                {state.selectedTypes.map((s) => (
                                  <option key={`${file.id}_sel_${s}`} value={s}>
                                    {s}
                                  </option>
                                ))}
                              </Select>
                            ) : (
                              <span>{type}</span>
                            )
                          }
                        >
                          <DicomWebViewComponent
                            key={`dicomviewcomp_${file.id}`}
                            state={state}
                            fileId={file.id}
                            index={ind}
                            dispatch={dispatch}
                            userEmail={data!.me.email}
                          />
                        </GridTile>
                      </Paper>
                    )
                  );
                })}
                <Paper key="info" className={classes.fileViewTile}>
                  <GridTile gridKey="info" layout={layout} key="info" title="Kép adatok">
                    <section className={classes.infoTile}>
                      <div>
                        {workflow &&
                          workflow.map((s, i) => {
                            return (
                              <Card key={`${packId}_workflow_step_${i}`}>
                                <CardHeader
                                  title={s.name}
                                  onClick={() => {
                                    completeStep({
                                      variables: {
                                        packId: packId,
                                        currentPatient: patientId,
                                        step: i,
                                        nextStep: i,
                                        rev: patientProgress && !patientProgress.completedSteps.includes(i),
                                      },
                                    });
                                  }}
                                  action={
                                    <IconButton
                                      aria-label="complete"
                                      onClick={(ev) => {
                                        ev.stopPropagation();
                                        if (patientProgress && !patientProgress.completedSteps.includes(i)) {
                                          downloadSummary();
                                        }
                                        completeStep({
                                          variables: {
                                            packId: packId,
                                            currentPatient: patientId,
                                            step: i,
                                            nextStep:
                                              patientProgress && patientProgress.completedSteps.includes(i) ? i : i + 1,
                                            rev: patientProgress && patientProgress.completedSteps.includes(i),
                                          },
                                        });
                                      }}
                                    >
                                      <DoneIcon
                                        style={{
                                          color:
                                            patientProgress && patientProgress.completedSteps.includes(i)
                                              ? "green"
                                              : "red",
                                        }}
                                      />
                                    </IconButton>
                                  }
                                />
                                <Collapse in={Math.min(currentStepInd, workflow.length - 1) === i}>
                                  <CardContent>
                                    <List>
                                      {state.labelModesWithColor.map(({ name, color }, ind) => (
                                        <ListItem
                                          key={`label_mode_${name}`}
                                          onClick={() => {
                                            dispatch({ type: FileViewActionType.SetLabelMode, mode: name });
                                            localDb.setPreferredViewSettings(
                                              progress!.workpack.id,
                                              name,
                                              state.selectedTypes
                                            );
                                          }}
                                        >
                                          <ListItemAvatar>
                                            <Avatar
                                              style={{
                                                backgroundColor: color,
                                                color: "white",
                                              }}
                                            >
                                              {Math.max(
                                                ...patient.files.map((f) =>
                                                  Math.max(
                                                    ...(state.fileInfos
                                                      .get(f.id)
                                                      ?.labels.map((label) =>
                                                        label.labelMode === name ? Number.parseInt(label.label, 10) : 0
                                                      ) || [0])
                                                  )
                                                ),
                                                0
                                              ).toString(10)}
                                            </Avatar>
                                          </ListItemAvatar>
                                          <ListItemText
                                            style={{
                                              textDecoration: name === state.currentLabelMode ? "underline" : "none",
                                            }}
                                          >
                                            {name}
                                          </ListItemText>
                                        </ListItem>
                                      ))}
                                    </List>
                                  </CardContent>
                                </Collapse>
                              </Card>
                            );
                          })}
                        <ul className={classes.labelList}>
                          {labelModes
                            .find((l) => l.name === state.currentLabelMode)
                            ?.labels.slice(0, 9)
                            .filter((l) => !!l)
                            .map((l, i) => (
                              <li key={`labelMode${state.currentLabelMode}_${i}`}>
                                {i + 1}: {l}
                              </li>
                            ))}
                        </ul>
                        <TextField
                          multiline={true}
                          fullWidth={true}
                          variant="outlined"
                          label="Megjegyzes"
                          value={note}
                          onKeyUp={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                          }}
                          onChange={(ev) => saveNote(ev.target.value)}
                        />
                        <ul>
                          {patient.notes
                            .filter((f) => f.email !== data.me.email)
                            .map((n) => (
                              <li key={`note_${patientId}_${n.email}`}>
                                {n.email} - {n.note}
                              </li>
                            ))}
                        </ul>
                      </div>
                      {/* <div>
                      {progress.workpack.sequenceTypes.map((type, ind) => {
                        const pf = patient!.files.find((f) => f.seqType === type)!;
                        const cTags = pf && tags.get(pf.id);
                        return (
                          pf && (
                            <div key={"info_" + ind}>
                              <span>
                                {cTags && cTags.SeriesDescription} ({(sliceNrs.get(pf.id) || 0) + 1} /{" "}
                                {cTags && cTags.NumberOfSlices}) {labels[pf.id] ? labels[pf.id].length : ""}
                              </span>
                              <ul className={classes.labelList}>
                                {(labels[pf.id] || [])
                                  .filter((a) => a.slice === sliceNrs.get(pf.id))
                                  .map((a) => {
                                    return (
                                      <li
                                        key={a.id}
                                        onClick={() => setSelectedROI(a.id)}
                                        className={a.id === selectedROI ? classes.selectedLabel : ""}
                                      >
                                        {a.label} / {a.labelMode}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          )
                        );
                      })}
                    </div> */}
                    </section>
                  </GridTile>
                </Paper>
              </GridLayout>
            </div>
          );
        }}
      </SizeMe>
      {/* <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.toolDial}
        icon={<SpeedDialIcon />}
        open={toolsOpen}
        onClick={() => setToolsOpen(!toolsOpen)}
        direction="up"
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => {
              setToolsOpen(false);
              action.onClick();
            }}
          />
        ))}
      </SpeedDial> */}
    </main>
  );
};

(FileViewComponent as any).whyDidYouRender = true;
