import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useEffect, createContext, useRef, useMemo } from "react";
import { ILabelData, ISliceLabelData } from "../../state/labels/labling.reducers";
import { SliceLabelAction } from "../../dtos/sliceLabelAction";
import { LabelAction } from "../../dtos/labelAction";

export interface ILabelerAutosaveHooks {
  log: (fileId: string, slice: number, message: string) => void;
  updateLabel: (label: ILabelData) => void;
  setSliceLabel: (label: ISliceLabelData) => void;
  setNote: (patientId: string, note: string) => void;
}

export const LabelerAutoSaveContext = createContext<ILabelerAutosaveHooks | undefined>(undefined);

export function useLabelerAutosaveHooks(interval: number): ILabelerAutosaveHooks {
  const notesToSave = useRef<Array<{ patientId: string; note: string }>>([]);
  const sliceLabelsToSave = useRef<SliceLabelAction[]>([]);
  const labelsToSave = useRef<LabelAction[]>([]);
  const logsToSave = useRef<Array<{ message: string; slice: number; fileId: string; timeStamp: string }>>([]);

  const [save] = useMutation(gql`
    mutation saveLabelsAndLogs(
      $labels: [LabelAction!]!
      $sliceLabels: [SliceLabelAction!]!
      $logs: [SliceLogItem!]!
      $notes: [PatientNoteAction!]!
    ) {
      saveLabels(labels: $labels, sliceLabels: $sliceLabels) {
        id
        labels {
          id
          slice
          rect
          label
          labelMode
        }
      }

      logSliceEvents(logItems: $logs)

      saveNotes(notes: $notes)
    }
  `);

  useEffect(() => {
    async function doSave() {
      const logs = Array.from(logsToSave.current);
      const labels = Array.from(labelsToSave.current);
      const sliceLabels = Array.from(sliceLabelsToSave.current);
      const notes = Array.from(notesToSave.current);
      if (logs.length + labels.length + sliceLabels.length + notes.length > 0) {
        const res = await save({
          variables: {
            logs,
            sliceLabels,
            labels: labels.map((l) => ({
              ...l,
              creator: undefined,
            })),
            notes,
          },
        });

        if (res.data.saveLabels) {
          if (labels.length > 0) {
            labelsToSave.current = labelsToSave.current.filter((l) => !labels.includes(l));
          }
          if (sliceLabels.length > 0) {
            sliceLabelsToSave.current = sliceLabelsToSave.current.filter((l) => !sliceLabels.includes(l));
          }
        }
        if (res.data.logSliceEvents) {
          if (logs.length > 0) {
            logsToSave.current = logsToSave.current.filter((l) => !logs.includes(l));
          }
        }
        if (res.data.saveNotes) {
          if (notes.length > 0) {
            notesToSave.current = notesToSave.current.filter((n) => !notes.includes(n));
          }
        }
      }
    }

    const int = setInterval(() => doSave(), interval);
    return () => {
      clearInterval(int);
      doSave().then(console.log, console.error);
    };
  }, [interval, save]);

  return useMemo(
    () => ({
      log: (fileId: string, slice: number, message: string) =>
        logsToSave.current.push({ fileId, slice, message, timeStamp: new Date().toISOString() }),
      updateLabel: (label: ILabelData) =>
        (labelsToSave.current = labelsToSave.current
          .filter((c) => c.id !== label.id)
          .concat([
            {
              ...label,
              rect: label.rect && (label.rect.map((a) => Math.floor(a)) as [number, number, number, number]),
              label: Number.parseInt(label.label, 10),
            },
          ])),
      setSliceLabel: (label: ISliceLabelData) =>
        (sliceLabelsToSave.current = sliceLabelsToSave.current
          .filter((c) => c.fileId !== label.fileId && c.slice !== label.slice)
          .concat([label])),
      setNote: (patientId: string, note: string) =>
        (notesToSave.current = notesToSave.current
          .filter((c) => c.patientId !== patientId)
          .concat([{ patientId, note }])),
    }),
    []
  );
}
