import React from "react";

import { Typography } from "@material-ui/core";

export const NotFoundComponent: React.SFC = () => {
  return (
    <main>
      <Typography variant="h3">Ez a link sajnos elromlott :(</Typography>
    </main>
  );
};
