import Konva, { Line } from "konva/konva";

declare var lalolib: any;

interface Matrix {
  type: "matrix";
  m: number;
  n: number;
  length: number;
  size: number;
  val: number[];
  get: (i: number, j: number) => number;
}

export const validCombos = {
  SAG_IW_TSE: "COR_IW_TSE",
  SAG_3D_DESS: "COR_IW_TSE",
  COR_IW_TSE: ["SAG_3D_DESS", "SAG_IW_TSE"],
  SAG_PD_FS: ["COR_PD_TSE_FS", "COR_PD_FS", "COR_T1_SE"],
  SAG_PD_TSE: ["COR_PD_TSE_FS", "COR_PD_FS", "COR_T1_SE"],
  SAG_PD_TSE_FS: ["COR_PD_TSE_FS", "COR_PD_FS", "COR_T1_SE"],
  COR_PD_TSE_FS: ["SAG_PD_FS", "SAG_PD_TSE", "SAG_PD_TSE_FS"],
  COR_PD_FS: ["SAG_PD_FS", "SAG_PD_TSE", "SAG_PD_TSE_FS"],
  COR_T1_SE: ["SAG_PD_FS", "SAG_PD_TSE", "SAG_PD_TSE_FS"],
};

export class SliceIndicator {
  ids: string[] = [];
  sliceCounts: number[] = [];
  frame_of_ref_coords: number[][][][] = [];
  dicom_tags: Array<{ [name: string]: string }> = [];
  sub_windows: Konva.Shape[] = [];
  multi_aff_mtx: Matrix[] = [];
  machine_coords: number[][][] = [];

  constructor(private readonly indicatorColor: string) {}

  addWindow(
    id: string,
    sliceNo: number,
    dicom_tags: { [name: string]: string },
    orientation: [number, number, number, number, number, number],
    origins: Array<[number, number, number]>
  ) {
    if (this.ids.includes(id)) {
      return;
    }
    const w = this.ids.length;
    this.ids[w] = id;
    this.dicom_tags[w] = dicom_tags;
    this.sliceCounts[w] = sliceNo;
    this.frame_of_ref_coords[w] = [];
    this.machine_coords[w] = [];
    this.multi_affine(w, sliceNo, orientation, origins);
    // console.log("window added", w, sliceNo);
  }

  // convert coords from machine coords of fromWindow to 'Frame of Reference' coords of toWindow
  calculate_frame_of_ref_coords(from_window: number, to_window: number): number[][] {
    if (
      this.multi_aff_mtx[to_window].length === 0 ||
      !this.machine_coords[from_window][0] ||
      this.machine_coords[from_window][0].length === 0
    ) {
      return [];
    }

    // const svd = lalolib.svd(this.multi_aff_mtx[to_window], "full");
    const tl = lalolib.solve(this.multi_aff_mtx[to_window], this.machine_coords[from_window][0]);
    const tr = lalolib.solve(this.multi_aff_mtx[to_window], this.machine_coords[from_window][1]);
    const lr = lalolib.solve(this.multi_aff_mtx[to_window], this.machine_coords[from_window][2]);
    const ll = lalolib.solve(this.multi_aff_mtx[to_window], this.machine_coords[from_window][3]);
    // console.log("***************************");
    // console.log(this.multi_aff_mtx[to_window]);
    // console.log(this.machine_coords[from_window]);
    // console.log(tl, tr, lr, ll);
    const ytl = Math.round(tl[0]);
    const xtl = Math.round(tl[1]);
    const ztl = Math.round(tl[2]);
    const ytr = Math.round(tr[0]);
    const xtr = Math.round(tr[1]);
    const ztr = Math.round(tr[2]);
    const ylr = Math.round(lr[0]);
    const xlr = Math.round(lr[1]);
    const zlr = Math.round(lr[2]);
    const yll = Math.round(ll[0]);
    const xll = Math.round(ll[1]);
    const zll = Math.round(ll[2]);

    // console.log([[xtl, ytl, ztl], [xtr, ytr, ztr], [xlr, ylr, zlr], [xll, yll, zll]]);
    // console.log("-----------------------------");
    return [
      [xtl, ytl, ztl],
      [xtr, ytr, ztr],
      [xlr, ylr, zlr],
      [xll, yll, zll],
    ];
  }

  multi_affine(
    w: number,
    no_of_imgs: number,
    orientation: [number, number, number, number, number, number],
    origins: Array<[number, number, number]>
  ): void {
    const img_pos_patient_first_slice = origins[origins.length - 1];
    const img_pos_patient_last_slice = origins[0];

    const pixel_spacing = this.dicom_tags[w].PixelSpacing.split("\\").map((s) => Number.parseFloat(s));
    // const slice_thk = firstSlice.SliceThickness;
    // const frame_of_reference_UID = firstSlice.FrameOfReferenceUID;

    const ipp_first_slice = [
      img_pos_patient_first_slice[0],
      img_pos_patient_first_slice[1],
      img_pos_patient_first_slice[2],
    ];
    const ipp_last_slice = [
      img_pos_patient_last_slice[0],
      img_pos_patient_last_slice[1],
      img_pos_patient_last_slice[2],
    ];
    const multi_aff: number[][] = [[], [], [], [0, 0, 0, 1]];

    multi_aff[0][0] = orientation[3] * pixel_spacing[0];
    multi_aff[1][0] = orientation[4] * pixel_spacing[0];
    multi_aff[2][0] = orientation[5] * pixel_spacing[0];
    multi_aff[0][1] = orientation[0] * pixel_spacing[1];
    multi_aff[1][1] = orientation[1] * pixel_spacing[1];
    multi_aff[2][1] = orientation[2] * pixel_spacing[1];

    const k1 = (ipp_first_slice[0] - ipp_last_slice[0]) / (1 - no_of_imgs);
    const k2 = (ipp_first_slice[1] - ipp_last_slice[1]) / (1 - no_of_imgs);
    const k3 = (ipp_first_slice[2] - ipp_last_slice[2]) / (1 - no_of_imgs);

    multi_aff[0][2] = k1;
    multi_aff[1][2] = k2;
    multi_aff[2][2] = k3;

    multi_aff[0][3] = ipp_first_slice[0];
    multi_aff[1][3] = ipp_first_slice[1];
    multi_aff[2][3] = ipp_first_slice[2];

    this.multi_aff_mtx[w] = lalolib.array2mat(multi_aff);
  }

  calculate_machine_coords(i: number, slice_no: number) {
    if (this.multi_aff_mtx[i].length === 0 || slice_no < 1) {
      return false;
    }

    const c = Number.parseInt(this.dicom_tags[i].Columns, 10);
    const r = Number.parseInt(this.dicom_tags[i].Rows, 10);
    let rc = [1, 1, slice_no, 1];
    this.machine_coords[i][0] = lalolib.mul(this.multi_aff_mtx[i], rc);
    rc = [1, c, slice_no, 1];
    this.machine_coords[i][1] = lalolib.mul(this.multi_aff_mtx[i], rc);
    rc = [r, c, slice_no, 1];
    this.machine_coords[i][2] = lalolib.mul(this.multi_aff_mtx[i], rc);
    rc = [r, 1, slice_no, 1];
    this.machine_coords[i][3] = lalolib.mul(this.multi_aff_mtx[i], rc);
  }

  calc_slice_pos_for_windows(w: number, w2: number, slice: number) {
    if (this.frame_of_ref_coords[w][w2]) {
      const top_z_max = Math.max(this.frame_of_ref_coords[w][w2][0][2], this.frame_of_ref_coords[w][w2][1][2]);
      const top_z_min = Math.min(this.frame_of_ref_coords[w][w2][0][2], this.frame_of_ref_coords[w][w2][1][2]);
      const bot_z_max = Math.max(this.frame_of_ref_coords[w][w2][2][2], this.frame_of_ref_coords[w][w2][3][2]);
      const bot_z_min = Math.min(this.frame_of_ref_coords[w][w2][2][2], this.frame_of_ref_coords[w][w2][3][2]);
      const top_x_max = Math.max(this.frame_of_ref_coords[w][w2][0][0], this.frame_of_ref_coords[w][w2][1][0]);
      const top_x_min = Math.min(this.frame_of_ref_coords[w][w2][0][0], this.frame_of_ref_coords[w][w2][1][0]);
      const bot_x_max = Math.max(this.frame_of_ref_coords[w][w2][2][0], this.frame_of_ref_coords[w][w2][3][0]);
      const bot_x_min = Math.min(this.frame_of_ref_coords[w][w2][2][0], this.frame_of_ref_coords[w][w2][3][0]);
      const top_z_diff = top_z_max - top_z_min;
      const bot_z_diff = bot_z_max - bot_z_min;
      const top_x_diff = top_x_max - top_x_min;
      const bot_x_diff = bot_x_max - bot_x_min;
      let top_y = this.frame_of_ref_coords[w][w2][0][1];
      let bot_y = this.frame_of_ref_coords[w][w2][2][1];
      if (slice > 0) {
        let top_x = Math.round(top_x_min + (top_x_diff * (slice - top_z_min)) / top_z_diff);
        let bot_x = Math.round(bot_x_min + (bot_x_diff * (slice - bot_z_min)) / bot_z_diff);
        const offset = Math.round(
          Number.parseFloat(this.dicom_tags[w2].SliceThickness) / Number.parseFloat(this.dicom_tags[w].PixelSpacing)
        );
        if (this.dicom_tags[w].FrameOfReferenceUID !== this.dicom_tags[w2].FrameOfReferenceUID) {
          console.error(
            " Frame of Reference UIDs are not the same, no slice position display possible!",
            this.dicom_tags[w].FrameOfReferenceUID,
            this.dicom_tags[w2].FrameOfReferenceUID
          );
          top_y = 0;
          bot_y = 0;
          top_x = 0;
          bot_x = 0;
        }
        // console.log(w, w2, [top_x, top_y, bot_x, bot_y]);

        return new Konva.Line({
          id: "SliceIndicator",
          points: [top_x, top_y, bot_x, bot_y],
          stroke: this.indicatorColor,
          strokeWidth: offset,
        });
        // this.sub_windows[w] = new Konva.Line({
        //   points: [top_x_min, top_y, top_x_max, top_y, bot_x_max, bot_y, bot_x_min, bot_y],
        //   closed: true,
        //   stroke: "red",
        //   strokeWidth: offset,
        // });
      }
    } else {
      // console.warn(` no Frame of Reference coords found for ${w} ${w2}`);
      return undefined;
    }
  }

  calc_slice_pos(slice_nos: Map<string, number>): Map<string, Map<string, Line>> {
    const mySliceNos = this.ids.map((id, i) => this.sliceCounts[i] - (slice_nos.get(id) || 0));
    if (!this.dicom_tags[0]) {
      return new Map();
    }
    // console.log("calc", mySliceNos);

    for (let j = 0; j < this.multi_aff_mtx.length; ++j) {
      this.calculate_machine_coords(j, mySliceNos[j]);
      // console.log(this.machine_coords);
      for (let i = 0; i < this.multi_aff_mtx.length; ++i) {
        if (this.isValidCombo(i, j)) {
          this.frame_of_ref_coords[i][j] = this.calculate_frame_of_ref_coords(j, i);
        }
      }
    }

    const r = new Map<string, Map<string, Line>>(this.ids.map((id) => [id, new Map()]));
    for (const i of Array.from(this.multi_aff_mtx.keys())) {
      for (const j of Array.from(this.multi_aff_mtx.keys())) {
        if (this.isValidCombo(i, j)) {
          const a = this.calc_slice_pos_for_windows(j, i, mySliceNos[i]);
          if (a) {
            r.get(this.ids[j])?.set(this.ids[i], a);
          }

          const b = this.calc_slice_pos_for_windows(i, j, mySliceNos[j]);
          if (b) {
            r.get(this.ids[i])?.set(this.ids[j], b);
          }
        }
      }
    }
    return r;
  }

  isValidCombo(i: number, j: number) {
    return i !== j;
    // if (i === j) {
    //   return false;
    // }
    // const typeA = this.dicom_tags[i].SeriesDescription.toUpperCase().replace(/ /g, "_");
    // const typeB = this.dicom_tags[j].SeriesDescription.toUpperCase().replace(/ /g, "_");

    // const stdA = Object.keys(validCombos).find((k) => typeA.startsWith(k));

    // return stdA && validCombos[stdA].some((t) => t.startsWith(typeB));
  }
}

/**
[[ -0.0046445714553818105, 0.36188232306241985 ,-0.3636051175, -1.3144113 ],
[ 0.037826991173624994, 0.04443356427699327, 2.9613266, -91.968986 ],
[ -0.3626026905417442, -1.0031131596477394e-9, 0.3135853749999999, 64.79761 ]]
 */
