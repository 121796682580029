import { createAction, PayloadAction } from "typesafe-actions";
import { WebApiError } from "../../modules/api/WebApiError";
import { ValidationResult } from "../../modules/validation/validationResult";

export enum ErrorActions {
  Dismiss = "Dismiss",
}

export type failedAction = PayloadAction<string, WebApiError | ValidationResult | Error>;

export const dismissError = createAction(ErrorActions.Dismiss, action => (failure: failedAction) =>
  action({ failure })
);
