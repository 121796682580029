import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    settingsView: {
      display: "grid",
      gridAutoRows: "max-content",
      gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
      "& > div": {
        flexGrow: 1,
        margin: theme.spacing(2),
      }
    },
  })
);
