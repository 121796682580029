import { applyMiddleware, createStore } from "redux";

import { combineEpics, createEpicMiddleware } from "redux-observable";

import { WebApi } from "../modules/api/webApi";

import { OrthopredAction } from "./actions";
import { OrthopredState, reducers } from "./reducers";

import { sessionEpics } from "./session/session.epics";
import { registrationEpics } from "./registration/registration.epics";
import { fileEpics } from "./file/file.epics";
import { LocalDB } from "../modules/localDB/localDB";
import { ValidationService } from "../modules/validation/validationService";
import { adminEpics } from "./admin/admin.epics";
import { labelingEpics } from "./labels/labeling.epics";

export function configureStore(api: WebApi, localDB: LocalDB, validationService: ValidationService) {
  const epicMiddleware = createEpicMiddleware<OrthopredAction, OrthopredAction, OrthopredState>({
    dependencies: { api, localDB, validationService },
  });

  const store = createStore(reducers, applyMiddleware(epicMiddleware));
  (store as any).reducers = reducers;

  const rootEpic = combineEpics(sessionEpics, registrationEpics, fileEpics, adminEpics, labelingEpics);
  epicMiddleware.run(rootEpic);

  return store;
}
