import { createAction } from "typesafe-actions";

export enum TaskActions {
  Dismiss = "TaskDismiss",
  Cancel = "TaskCancel",
  Progress = "TaskProgress",
}

export const dismissTask = createAction(TaskActions.Dismiss, action => ({ id }: { id: string }) => action({ id }));
export const cancelTask = createAction(TaskActions.Cancel, action => ({ id }: { id: string }) => action({ id }));
export const progressTask = createAction(
  TaskActions.Progress,
  action => ({ id, state, progress, cancel }: { id: string; state: string; progress: number; cancel: () => void }) =>
    action({ id, state, progress, cancel })
);
