import { connect } from "react-redux";

import { OrthopredState } from "../../state/reducers";

import { LoginView } from "./login.component";
import { LoginStatus } from "../../state/session/session.state";
import { checkSession, login } from "../../state/session/session.actions";
import { failedAction, dismissError } from "../../state/errors/error.actions";

export const LoginContainer = connect(
  (state: OrthopredState) => {
    return {
      loginStatus: state.loginState.loginStatus || LoginStatus.Unknown,
      errors: state.error.errors,
    };
  },
  dispatch => {
    return {
      checkSession: () => {
        dispatch(checkSession.request());
      },
      onSubmit: (email: string, password: string) => {
        dispatch(login.request({ email, password }));
      },
      dismissError: (err: failedAction) => dispatch(dismissError(err)),
    };
  }
)(LoginView);
