import { getType } from "typesafe-actions";

import { OrthopredAction } from "../actions";
import * as FileActions from "./file.actions";
import { IFileInfo } from "../admin/admin.reducers";

export interface IDownloadedFileInfo {
  meta: any;
  fileId: string;
  blob: Blob;
}
interface IFileState {
  fileList: {
    startDate: Date | undefined;
    cursorDate: Date | undefined;
    files: IFileInfo[];
    cursor: number;
    isRefreshing: boolean;
    isFullyLoaded: boolean;
    isLoadingMore: boolean;
  };
  downloadedFileIds: string[];
  downloadedFiles: IDownloadedFileInfo[];
}

const startState: IFileState = {
  fileList: {
    startDate: undefined,
    cursorDate: undefined,
    cursor: 0,
    files: [],
    isRefreshing: false,
    isLoadingMore: false,
    isFullyLoaded: false,
  },
  downloadedFileIds: [],
  downloadedFiles: [],
};

export function filesState(state: IFileState = startState, action: OrthopredAction): IFileState {
  switch (action.type) {
    case getType(FileActions.fileDownload.success):
      return {
        ...state,
        downloadedFileIds: state.downloadedFileIds.concat([action.payload.info.fileId]),
        downloadedFiles: state.downloadedFiles.concat([
          { fileId: action.payload.info.fileId, meta: action.payload.info.meta, blob: action.payload.blob },
        ]),
      };

    case getType(FileActions.listFiles.request):
      return {
        ...state,
        fileList: {
          ...state.fileList,
          startDate: new Date(),
          isRefreshing: true,
        },
      };
    case getType(FileActions.listFiles.success):
      if (action.payload.cursor !== 0 && action.payload.cursor !== state.fileList.cursor) {
        return state;
      }
      const newFiles =
        state.fileList.cursor === 0
          ? action.payload.files
          : [
              ...action.payload.files,
              ...state.fileList.files.filter(f => action.payload.files.every(nf => f.fileId !== nf.fileId)),
            ];

      return {
        ...state,
        fileList: {
          ...state.fileList,
          cursorDate: new Date(),
          files: newFiles,
          cursor: action.payload.cursor,
          isFullyLoaded: action.payload.cursor === 0,
          isRefreshing: false,
          isLoadingMore: false,
        },
        downloadedFileIds: action.payload.downloadedFileIds,
        downloadedFiles: action.payload.downloadedFiles,
      };
    case getType(FileActions.listFiles.failure):
      return {
        ...state,
        fileList: {
          startDate: undefined,
          cursorDate: undefined,
          files: [],
          cursor: 0,
          isRefreshing: false,
          isFullyLoaded: false,
          isLoadingMore: false,
        },
      };

    case getType(FileActions.deleteDownloadedFile.success):
      return {
        ...state,
        downloadedFileIds: action.payload.downloadedFileIds,
        downloadedFiles: action.payload.downloadedFiles,
      };
    default:
      return state;
  }
}
