import React from "react";
import { Container } from "@material-ui/core";

import { IImageInfoDisplaySettings, IInfoLabelSettings } from "../../state/session/session.state";
import { InfoLabelSettingsCard } from './infoLabelSettingsCard.component';
import { useStyles } from './settings.styles';

export interface ISettingsProps {
  settings: IImageInfoDisplaySettings;

  saveSettings: (settings: IImageInfoDisplaySettings) => void;
}

export const SettingsView: React.FC<ISettingsProps> = ({
  settings,

  saveSettings,
}: ISettingsProps) => {
  function save(
    label: keyof IImageInfoDisplaySettings,
    value: IInfoLabelSettings,
  ) {
    saveSettings({
      ...settings,
      [label]: value,
    });
  }
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md" className={classes.settingsView}>
      <InfoLabelSettingsCard title="Beteg azonosító" labelSettings={settings.patientIdLabel} save={(settings: IInfoLabelSettings) => save("patientIdLabel", settings)} />
      <InfoLabelSettingsCard title="Szelet cimke" labelSettings={settings.sliceLabel} save={(settings: IInfoLabelSettings) => save("sliceLabel", settings)} />
      <InfoLabelSettingsCard title="Lateralitás" labelSettings={settings.laterality} save={(settings: IInfoLabelSettings) => save("laterality", settings)} />
      <InfoLabelSettingsCard title="Szelet jelzo" isSliceIndicator={true} labelSettings={settings.sliceIndicator} save={(settings: IInfoLabelSettings) => save("sliceIndicator", settings)} />
    </Container>
  );
};
