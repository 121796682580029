import { DocumentNode } from "graphql";
import { useQuery } from "@apollo/react-hooks";
import { useEffect } from 'react';

export function useListQuery<T extends { id: string }>(name: string, query: DocumentNode, ensureId?: string) {
  const { data, error, networkStatus, refetch, fetchMore } = useQuery<{
    [name: string]: { cursor: number; list: T[] };
  }>(query, {
    variables: {
      cursor: 0,
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const list = data && data[name].list
  const fullyLoaded = data && data[name].cursor === 0;

  function listFetchMore() {
    if (!data) {
      throw new Error("FetchMoreBeforeLoad");
    }
    fetchMore({
      variables: { cursor: data[name].cursor },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return Object.assign({}, prev, {
          cursor: fetchMoreResult.workpacks.cursor,
          list: [...prev[name].list, ...fetchMoreResult[name].list],
        });
      },
    });
  };

  useEffect(() => {
    if (list && ensureId && list.every(a => a.id !== ensureId) && !fullyLoaded) {
      listFetchMore()
    }
  })

  return {
    list,
    fullyLoaded,
    error,
    networkStatus,
    refetch: () => {
      refetch({
        cursor: 0,
      });
    },
    fetchMore: listFetchMore,
  };
}
