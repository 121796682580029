import React, { useState } from "react";
import {
  Container,
  Modal,
  Paper,
  List,
  TextField,
  Button,
  CircularProgress,
  Typography,
  IconButton,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";

import { useStyles } from "./labelModeList.style";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import AddIcon from "@material-ui/icons/Add";
import SeeIcon from "@material-ui/icons/Visibility";
import { LabelMode, labelModeListQuery } from "./labelModeList.query";

export const LabelModeListComponent: React.FC = () => {
  const classes = useStyles();

  const { data: labelModeList } = useQuery<{ listLabelModes: LabelMode[] }>(labelModeListQuery);

  const [createLabelMode, { loading: createLoading }] = useMutation(
    gql`
      mutation createLabelMode($name: String!, $labels: [String!]!, $defaultLabel: Int!) {
        createLabelMode(name: $name, labels: $labels, defaultLabel: $defaultLabel) {
          id
        }
      }
    `,
    {
      refetchQueries: [{ query: labelModeListQuery }],
    }
  );

  const [modeId, setModeId] = useState<string | undefined>();
  const [labelModeName, setLabelModeName] = useState<string | undefined>();
  const [labels, setLabels] = useState<string[] | undefined>();
  const [defaultLabel, setDefaultLabel] = useState<number | undefined>();

  function setLabel(labelInd: number, newLabel: string) {
    setLabels((sl) => {
      const labels = Array.from(sl || []);
      if (labelInd > labels.length) {
        labels.fill("", labels.length, labelInd);
      }
      labels.splice(labelInd, 1, newLabel);
      return labels;
    });
  }
  const validationResult = undefined;

  function clearForm() {
    setModeId(undefined);
    setLabelModeName(undefined);
    setLabels(undefined);
    setDefaultLabel(undefined);
  }

  function openNewLabelMode() {
    setLabelModeName("");
    setLabels(new Array(9).fill(""));
    setDefaultLabel(1);
  }

  function openLabelMode(mode: LabelMode) {
    setModeId(mode.id);
    setLabelModeName(mode.name);
    setLabels(mode.labels);
    setDefaultLabel(mode.defaultLabel);
  }

  return (
    <Container component="main" maxWidth="sm">
      <Modal open={labelModeName !== undefined} onClose={() => clearForm()}>
        <Paper className={classes.modalPaper}>
          {labels && (
            <form
              className={classes.form}
              onSubmit={(ev) => {
                ev.preventDefault();
              }}
            >
              <TextField
                label="Név"
                fullWidth={true}
                value={labelModeName}
                onChange={(ev) => setLabelModeName(ev.target.value)}
              />
              <TextField
                label="Alapertelmezett cimke"
                type="number"
                inputProps={{ min: "1", max: "9", step: "1" }}
                value={defaultLabel || 1}
                fullWidth={true}
                onChange={(ev) => {
                  setDefaultLabel(Number.parseInt(ev.currentTarget.value, 10));
                }}
              />
              <TextField
                fullWidth={true}
                label="Cimke 1"
                value={labels[0]}
                onChange={(ev) => setLabel(0, ev.currentTarget.value)}
              />
              <TextField
                fullWidth={true}
                label="Cimke 2"
                value={labels[1]}
                onChange={(ev) => setLabel(1, ev.currentTarget.value)}
              />
              <TextField
                fullWidth={true}
                label="Cimke 3"
                value={labels[2]}
                onChange={(ev) => setLabel(2, ev.currentTarget.value)}
              />
              <TextField
                fullWidth={true}
                label="Cimke 4"
                value={labels[3]}
                onChange={(ev) => setLabel(3, ev.currentTarget.value)}
              />
              <TextField
                fullWidth={true}
                label="Cimke 5"
                value={labels[4]}
                onChange={(ev) => setLabel(4, ev.currentTarget.value)}
              />
              <TextField
                fullWidth={true}
                label="Cimke 6"
                value={labels[5]}
                onChange={(ev) => setLabel(5, ev.currentTarget.value)}
              />
              <TextField
                fullWidth={true}
                label="Cimke 7"
                value={labels[6]}
                onChange={(ev) => setLabel(6, ev.currentTarget.value)}
              />
              <TextField
                fullWidth={true}
                label="Cimke 8"
                value={labels[7]}
                onChange={(ev) => setLabel(7, ev.currentTarget.value)}
              />
              <TextField
                fullWidth={true}
                label="Cimke 9"
                value={labels[8]}
                onChange={(ev) => setLabel(8, ev.currentTarget.value)}
              />
              {modeId === undefined && (
                <Button
                  type="submit"
                  disabled={createLoading}
                  onClick={() => {
                    if (modeId === undefined) {
                      createLabelMode({
                        variables: {
                          name: labelModeName,
                          labels,
                          defaultLabel,
                        },
                      }).then(clearForm);
                    }
                  }}
                >
                  {createLoading ? <CircularProgress /> : "Mentés"}
                </Button>
              )}
            </form>
          )}
        </Paper>
      </Modal>
      <Typography variant="h2">
        Cimkezesi modok{" "}
        <IconButton onClick={() => openNewLabelMode()}>
          <AddIcon />
        </IconButton>
      </Typography>
      <List>
        {labelModeList?.listLabelModes.map((m) => (
          <ListItem>
            <ListItemText primary={m.name} secondary={m.labels.map((v, i) => `${i}: ${v}`).join(", ")} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => openLabelMode(m)}>
                <SeeIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};
