import { Line } from "konva/konva";

import { ILabelData } from "../../state/labels/labling.reducers";
import { IStepInfo } from "../../queries/fileList.query";
import { IStoredFileInfo, LocalDB } from "../../modules/localDB/localDB";

import { SliceIndicator } from "./sliceIndicatorCalculator";
import {
  IInfoLabelSettings,
  IImageInfoDisplaySettings,
  defaultImageInfoDisplaySettings,
} from "../../state/session/session.state";
import { ILabelerAutosaveHooks } from "./useAutoSave";

export enum LoadState {
  None = "None",
  Loading = "Loading",
  Loaded = "Loaded",
}

export type FileViewState = {
  loadState: LoadState;
  patientId: string;
  packId: string;
  localDb: LocalDB;
  autoSaveContext: ILabelerAutosaveHooks;

  currentStep: number;
  steps: IStepInfo[];

  tool: string;
  selectedTypes: string[];
  windowWidth: number;
  windowLevel: number;

  displaySettings: IImageInfoDisplaySettings;

  sliceIndicatorCalculator: SliceIndicator;
  fileInfos: Map<
    string,
    {
      id: string;
      tags?: { [name: string]: string };
      storedFile?: IStoredFileInfo;
      fileLoadProgress: number;
      slice: number;
      labels: ILabelData[];
      sliceLabels: { [slice: number]: number };
      sliceIndicators: Map<string, Line>;
      sliceCenters: Map<number, [number, number]>;
    }
  >;

  selectedROI: string;

  currentLabelMode: string;
  labelModesWithColor: Array<{
    name: string;
    defaultLabel: number;
    color: string;
  }>;
};

export const defaultFileViewState = {
  loadState: LoadState.None,
  packId: "",
  patientId: "",
  displaySettings: defaultImageInfoDisplaySettings,
  sliceIndicatorCalculator: new SliceIndicator("yellow"),
  fileInfos: new Map(),
  selectedTypes: [],
  windowWidth: 1,
  windowLevel: 0,
  tool: "ZoomAndPan",
  selectedROI: "",
  currentLabelMode: "",
  labelModesWithColor: [],
  currentStep: 0,
  steps: [],
};

export enum FileViewActionType {
  ResetPatient = "ResetPatient",
  LoadFileInfos = "LoadFileInfos",
  SetFileLoadProgress = "SetFileLoadProgress",
  LoadStoredFile = "LoadStoredFile",
  FinishFileLoad = "FinishFileLoad",
  SetSliceLabel = "SetSliceLabel",
  SelectLabel = "SelectLabel",
  CreateLabel = "CreateLabel",
  MoveLabel = "MoveLabel",
  DeleteLabel = "DeleteLabel",
  SavedLabel = "SavedLabel",
  MoveSelectedType = "MoveSelectedType",
  SetSlice = "SetSlice",
  SetWindow = "SetWindow",
  SetTool = "SetTool",
  SetStep = "SetStep",
  SetLabelMode = "SetLabelMode",
}

export type FileViewAction =
  | {
      type: FileViewActionType.CreateLabel;
      fileId: string;
      id: string;
      slice: number;
      rect: [number, number, number, number];
      labelMode: string;
      label: string;
    }
  | {
      type: FileViewActionType.MoveLabel;
      fileId: string;
      id: string;
      rect: [number, number, number, number];
    }
  | {
      type: FileViewActionType.SetSliceLabel;
      fileId: string;
      slice: number;
      label: number;
    }
  | {
      type: FileViewActionType.DeleteLabel;
      fileId: string;
      id: string;
    }
  | {
      type: FileViewActionType.SelectLabel;
      id: string;
    }
  | {
      type: FileViewActionType.MoveSelectedType;
      slot: number;
      seqType: string;
    }
  | {
      type: FileViewActionType.SetWindow;
      ww: number;
      wl: number;
    }
  | {
      type: FileViewActionType.ResetPatient;
      patientId: string;
      packId: string;
      selectedTypes: string[];
      displaySettings: IImageInfoDisplaySettings;
      steps: IStepInfo[];
    }
  | {
      type: FileViewActionType.LoadFileInfos;
      infos: Array<{
        id: string;
        labels: ILabelData[];
        sliceLabels: number[];
      }>;
    }
  | {
      type: FileViewActionType.LoadStoredFile;
      fileId: string;
      storedInfo: IStoredFileInfo;
    }
  | {
      type: FileViewActionType.SetFileLoadProgress;
      fileId: string;
      progress: number;
    }
  | {
      type: FileViewActionType.FinishFileLoad;
      fileId: string;
      tags: {};
      sliceCount: number;
      orientation: [number, number, number, number, number, number];
      origins: Array<[number, number, number]>;
    }
  | {
      type: FileViewActionType.SetTool;
      tool: string;
    }
  | {
      type: FileViewActionType.SetStep;
      step: number;
      labelModesWithColor: Array<{
        name: string;
        defaultLabel: number;
        color: string;
      }>;
    }
  | {
      type: FileViewActionType.SetLabelMode;
      mode: string;
    }
  | {
      type: FileViewActionType.SetSlice;
      fileId: string;
      slice: number;
    };

export function fileViewStateReducer(state: FileViewState, action: FileViewAction): FileViewState {
  console.log(action);
  switch (action.type) {
    case FileViewActionType.ResetPatient:
      const prefWindow = JSON.parse(window.localStorage.getItem(`preferred-window`) || "{}");
      const viewSettings = state.localDb.getPreferredViewSettings(action.packId);
      return {
        ...state,
        loadState: LoadState.Loading,
        patientId: action.patientId,
        packId: action.packId,
        fileInfos: new Map(),
        selectedTypes: viewSettings?.seqTypes || action.selectedTypes,
        windowWidth: prefWindow?.ww ?? 1,
        windowLevel: prefWindow?.wc ?? 1,
        displaySettings: action.displaySettings,
        sliceIndicatorCalculator: new SliceIndicator(action.displaySettings.sliceIndicator.color),
        tool: "ZoomAndPan",
        selectedROI: "",
        currentLabelMode: "",
        labelModesWithColor: [],
        currentStep: 0,
        steps: action.steps,
      };
    case FileViewActionType.SetWindow:
      window.localStorage.setItem(`preferred-window`, JSON.stringify({ wc: action.wl, ww: action.ww }));
      return {
        ...state,
        windowWidth: action.ww,
        windowLevel: action.wl,
      };
    case FileViewActionType.SetTool:
      return {
        ...state,
        tool: action.tool,
      };
    case FileViewActionType.SetLabelMode:
      state.localDb.setPreferredViewSettings(state.packId, action.mode, state.selectedTypes);
      return {
        ...state,
        currentLabelMode: action.mode,
      };
    case FileViewActionType.SetStep:
      const step = state.steps[action.step];
      if (!step) return state;
      return {
        ...state,
        currentStep: action.step,
        currentLabelMode: step.labelingModes.includes(state.currentLabelMode)
          ? state.currentLabelMode
          : step.labelingModes[0],
        labelModesWithColor: action.labelModesWithColor,
      };
    case FileViewActionType.MoveSelectedType:
      const oldInd = state.selectedTypes.indexOf(action.seqType);
      const otherType = state.selectedTypes[action.slot];
      const newTypes = state.selectedTypes.map((t, i) =>
        i === oldInd ? otherType : i === action.slot ? action.seqType : t
      );
      state.localDb.setPreferredViewSettings(state.packId, state.currentLabelMode, newTypes);
      return {
        ...state,
        selectedTypes: newTypes,
      };
    case FileViewActionType.LoadFileInfos:
      return {
        ...state,
        fileInfos: new Map(
          action.infos.map((i) => [
            i.id,
            {
              id: i.id,
              labels: i.labels,
              sliceLabels: i.sliceLabels,
              sliceIndicators: new Map(),
              slice: 0,
              fileLoadProgress: 0,
              tags: undefined,
              storedFile: undefined,
              sliceCenters: new Map(),
            },
          ])
        ),
      };
    case FileViewActionType.LoadStoredFile:
      const sliceCenters = new Map();
      if (action.storedInfo.meta.sliceCenters) {
        for (let i = 0; i < action.storedInfo.meta.sliceCenters.length; i += 3) {
          sliceCenters.set(action.storedInfo.meta.sliceCenters[i], [
            action.storedInfo.meta.sliceCenters[i + 1],
            action.storedInfo.meta.sliceCenters[i + 2],
          ]);
        }
      }
      const fileInfos = new Map(
        Array.from(state.fileInfos.entries()).map(([k, v]) => {
          return k === action.fileId
            ? [
                k,
                {
                  ...v,
                  storedFile: action.storedInfo,
                  slice: action.storedInfo.meta.startingSlice || v.slice,
                  sliceCenters,
                },
              ]
            : [k, v];
        })
      );
      return {
        ...state,
        loadState: Array.from(fileInfos.values()).some((f) => !f.storedFile) ? LoadState.Loading : LoadState.Loaded,
        fileInfos,
      };
    case FileViewActionType.SetFileLoadProgress:
      return {
        ...state,
        fileInfos: new Map(
          Array.from(state.fileInfos.entries()).map(([k, v]) =>
            k === action.fileId ? [k, { ...v, fileLoadProgress: action.progress }] : [k, v]
          )
        ),
      };
    case FileViewActionType.FinishFileLoad:
      const f = state.fileInfos.get(action.fileId);
      if (f && f.storedFile) {
        state.sliceIndicatorCalculator.addWindow(
          action.fileId,
          action.sliceCount,
          action.tags,
          action.orientation,
          action.origins
        );
        // state.autoSaveContext.log(action.fileId, f.storedFile.meta.startingSlice);
      }
      return {
        ...state,
        fileInfos: new Map(
          Array.from(state.fileInfos.entries()).map(([k, v]) =>
            k === action.fileId ? [k, { ...v, tags: action.tags, fileLoadProgress: 101 }] : [k, v]
          )
        ),
      };
    // case FileViewActionType.SavedLabel:
    //   return {
    //     ...state,
    //     fileInfos: new Map(
    //       Array.from(state.fileInfos.entries()).map(([k, v]) => [
    //         k,
    //         k === action.fileId
    //           ? { ...v, labels: v.labels.map((l) => (l.id !== action.id ? l : { ...l, id: action.id })) }
    //           : v,
    //       ])
    //     ),
    //   };
    case FileViewActionType.CreateLabel:
      state.autoSaveContext.updateLabel({
        fileId: action.fileId,
        slice: action.slice,
        id: action.id,
        labelMode: action.labelMode,
        label: action.label,
        rect: action.rect,
        creator: "me",
      });
      return {
        ...state,
        fileInfos: new Map(
          Array.from(state.fileInfos.entries()).map(([k, v]) => [
            k,
            k === action.fileId
              ? {
                  ...v,
                  labels: v.labels
                    .filter((f) => f.id !== action.id)
                    .concat([
                      {
                        fileId: action.fileId,
                        slice: action.slice,
                        id: action.id,
                        labelMode: action.labelMode,
                        label: action.label,
                        rect: action.rect,
                        creator: "me",
                      },
                    ]),
                }
              : v,
          ])
        ),
      };
    case FileViewActionType.MoveLabel:
      const oldLabel = state.fileInfos.get(action.fileId)?.labels.find((l) => l.id === action.id);
      if (!oldLabel) return state;
      state.autoSaveContext.updateLabel({
        fileId: action.fileId,
        id: action.id,
        rect: action.rect,
        slice: oldLabel.slice,
        labelMode: oldLabel.labelMode,
        label: oldLabel.label,
        creator: "me",
      });
      return {
        ...state,
        fileInfos: new Map(
          Array.from(state.fileInfos.entries()).map(([k, v]) => [
            k,
            k === action.fileId
              ? {
                  ...v,
                  labels: v.labels
                    .filter((f) => f.id !== action.id)
                    .concat([
                      {
                        fileId: action.fileId,
                        slice: oldLabel.slice,
                        id: action.id,
                        labelMode: oldLabel.labelMode,
                        label: oldLabel.label,
                        rect: action.rect,
                        creator: "me",
                      },
                    ]),
                }
              : v,
          ])
        ),
      };
    case FileViewActionType.DeleteLabel:
      state.autoSaveContext.updateLabel({
        fileId: action.fileId,
        id: action.id,
        slice: 0,
        labelMode: "",
        label: undefined,
        rect: undefined,
        creator: "me",
      } as any); // TODO
      return {
        ...state,
        fileInfos: new Map(
          Array.from(state.fileInfos.entries()).map(([k, v]) => [
            k,
            k === action.fileId
              ? {
                  ...v,
                  labels: v.labels.filter((f) => f.id !== action.id),
                }
              : v,
          ])
        ),
      };
    case FileViewActionType.SelectLabel:
      if (state.selectedROI === action.id) {
        return state;
      }
      return {
        ...state,
        selectedROI: action.id,
      };
    case FileViewActionType.SetSlice:
      state.autoSaveContext.log(action.fileId, action.slice, "SliceDisplayed");
      const indicators = state.sliceIndicatorCalculator.calc_slice_pos(
        new Map(Array.from(state.fileInfos.entries()).map(([k, v]) => [k, v.slice]))
      );
      return {
        ...state,
        selectedROI: action.fileId,
        fileInfos: new Map(
          Array.from(state.fileInfos.entries()).map(([k, v]) => [
            k,
            {
              ...v,
              sliceIndicators: indicators.get(k) || new Map(),
              slice: k === action.fileId ? action.slice : v.slice,
            },
          ])
        ),
      };
    case FileViewActionType.SetSliceLabel:
      state.autoSaveContext.setSliceLabel({ fileId: action.fileId, label: action.label, slice: action.slice });
      return {
        ...state,
        selectedROI: action.fileId,
        fileInfos: new Map(
          Array.from(state.fileInfos.entries()).map(([k, v]) => [
            k,
            k === action.fileId
              ? {
                  ...v,
                  sliceLabels: { ...v.sliceLabels, [action.slice]: action.label },
                }
              : v,
          ])
        ),
      };
    default:
      console.error(action);
      throw new Error("UnknownFileViewAction");
  }
}
