import { WebApiError } from "../api/WebApiError";

interface IValidationMessage {
  property: string;
  constraints: { [ind: string]: string };
}

export class ValidationResult {
  constructor(public validationErrors: Map<string, Array<{ constraint: string; message: string }>> = new Map()) {}
  static fromApiError(err: WebApiError): ValidationResult | undefined {
    if (err.statusCode === 400) {
      const errors = new Map();
      const validationMessages = err.inner.message as IValidationMessage[];
      for (const field of validationMessages) {
        errors.set(
          field.property,
          Object.keys(field.constraints).map((constraint) => ({
            constraint,
            message: field.constraints[constraint],
          }))
        );
      }
      return new ValidationResult(errors);
    }
    return undefined;
  }

  isFieldInvalid(field: string): boolean {
    return this.validationErrors.has(field);
  }

  fieldValidationMessages(field: string): string[] {
    const f = this.validationErrors.get(field);
    return f ? f.map((a) => a.message) : [];
  }
}
