import React, { useEffect } from "react";
import { Container, CircularProgress, Checkbox } from "@material-ui/core";

import { UserPermission } from "../../dtos/enums/userPermissions";
import { UserFilePermission } from "../../dtos/enums/userFilePermissions";
import { IUserList, IUserInfo, IFileList } from "../../state/admin/admin.reducers";

import { useStyles } from "./userManagement.style";

export interface IUserManagementViewComponentProps {
  user: IUserInfo | undefined;
  userList: IUserList;

  sequenceTypes: string[];
  sequenceTypesState: string;

  fileList: IFileList;

  loadUsers: (cursor: number) => void;
  loadSequenceTypes: () => void;
  listFiles: (email: string, sequenceTypes: string[], loadMore: boolean) => void;

  setUserPermission: (email: string, permission: UserPermission, value: boolean) => void;
  setUserFilePermission: (email: string, permission: UserFilePermission, value: boolean) => void;
  setFilePermission: (email: string, fileId: string, value: boolean) => void;
  setUserLabelSettings: (email: string, sequenceTypes: string[], labels: any) => void;
}

function getSwitch(setter: (val: boolean) => void, checked: boolean) {
  return <Checkbox color="primary" onClick={(ev) => setter((ev.target as any).checked)} checked={checked} />;
}

export const UserManagementViewComponent: React.FC<IUserManagementViewComponentProps> = ({
  userList,
  user,

  loadUsers,

  setUserPermission,
  setUserFilePermission,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (
      (!user || !userList.startDate || new Date().getTime() - userList.startDate.getTime() > 10000) &&
      !userList.isFullyLoaded &&
      !userList.isLoadingMore &&
      !userList.isRefreshing
    ) {
      loadUsers(userList.cursor || 0);
    }
  });

  if (!user) {
    return <CircularProgress />;
  }

  return (
    <Container component="main" className={classes.page}>
      <section>
        <div className={classes.permissions}>
          <div className={classes.permissionBlock}>
            <h4>Felhasznaloi jogosultsagok</h4>
            {getSwitch(
              (val) => setUserPermission(user.email, UserPermission.Login, val),
              user.userPermissions.check(UserPermission.Login)
            )}
            <span>Belepes</span>
            <span>A felhasznalo validalt, belephet a site-ra.</span>
            {getSwitch(
              (val) => setUserPermission(user.email, UserPermission.ManageUsers, val),
              user.userPermissions.check(UserPermission.ManageUsers)
            )}
            <span>Jogosultsag kezeles</span>
            <span>Atallithatja mas felhasznalok felhasznaloi jogosultsagait</span>
            {getSwitch(
              (val) => setUserPermission(user.email, UserPermission.ListUsers, val),
              user.userPermissions.check(UserPermission.ListUsers)
            )}
            <span>Listazasa</span>
            <span>Az osszes regisztracio megtekintese</span>
            <h4>File jogosultsagok</h4>
            {getSwitch(
              (val) => setUserFilePermission(user.email, UserFilePermission.FileView, val),
              user.filePermissions.check(UserFilePermission.FileView)
            )}
            <span>Letoltes</span>
            <span>Szekvenciak letoltese</span>
            {getSwitch(
              (val) => setUserFilePermission(user.email, UserFilePermission.FileUpload, val),
              user.filePermissions.check(UserFilePermission.FileUpload)
            )}
            <span>Feltoltes</span>
            <span>Szekvenciak feltoltese</span>
            {getSwitch(
              (val) => setUserFilePermission(user.email, UserFilePermission.List, val),
              user.filePermissions.check(UserFilePermission.List)
            )}
            <span>Összes listázása</span>
            <span>Az osszes szekvencia megjelenik a listaban</span>
            {getSwitch(
              (val) => setUserFilePermission(user.email, UserFilePermission.List, val),
              user.filePermissions.check(UserFilePermission.ViewAny)
            )}
            <span>Összes szekvencia letoltese</span>
            <span>Hozzaferes az osszes szekvenciahoz</span>
            {getSwitch(
              (val) => setUserFilePermission(user.email, UserFilePermission.SetPermissions, val),
              user.filePermissions.check(UserFilePermission.SetPermissions)
            )}
            <span>Jogosultsag kezelés</span>
            <span>Mas felhasznalok file jogosultsagainak atallitasa</span>
            <h4>Cimkezesi jogosultsagok</h4>
            {getSwitch(
              (val) => setUserFilePermission(user.email, UserFilePermission.Annotate, val),
              user.filePermissions.check(UserFilePermission.Annotate)
            )}
            <span>Jeloles</span>
            <span>Sajat cimkek hozzaadasa</span>
            {getSwitch(
              (val) => setUserFilePermission(user.email, UserFilePermission.ViewAnnotations, val),
              user.filePermissions.check(UserFilePermission.ViewAnnotations)
            )}
            <span>Ellenorzes</span>
            <span>Mas felhasznalok jeloleseinek megtekintese</span>
            {getSwitch(
              (val) => setUserFilePermission(user.email, UserFilePermission.EditAnnotations, val),
              user.filePermissions.check(UserFilePermission.EditAnnotations)
            )}
            <span>Szerkesztese</span>
            <span>Mas felhasznalok cimkeinek szerkesztese</span>
          </div>
        </div>
      </section>
    </Container>
  );
};
