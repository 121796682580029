import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContainer: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      minHeight: "100vh",
      width: "calc(100vw - 18px)",
      overflowX: "hidden",
      "& main": {
        flexGrow: 1,
      },
    },
    appBar: {
      flexGrow: 1,
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        marginLeft: "-20px",
      },
    },
    title: {
      flexGrow: 1,
      "& span+span::before": {
        content: '" - "',
      },
      "& a": {
        color: "inherit",
      },
    },
    done: {
      color: "green",
    },
    incomplete: {
      color: "red",
    },
    drawerPaper: {
      position: "fixed",
      whiteSpace: "nowrap",
      width: theme.spacing(25),
      overflowX: "hidden",
      height: "100vh",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
    },
    mainContainer: {
      display: "flex",
      flexGrow: 1,
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      overflowX: "hidden",
      flexGrow: 1,

      "&>main": {
        textAlign: "left",
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    sideBarSpacer: {
      flexShrink: 0,
      position: "relative",
    },
    sidebarNavList: {
      paddingTop: "0",
    },
    progressPanelIcon: {
      position: "fixed",
      right: theme.spacing(6),
      bottom: theme.spacing(9),
    },
  })
);
