import { OrthopredAction } from "../actions";
import { getType } from "typesafe-actions";
import { dismissTask, cancelTask, progressTask } from "./task.actions";
import { fileDownload, fileUpload } from "../file/file.actions";

export class Task {
  public progress: number;
  public state: string;

  constructor(
    public readonly id: string,
    public readonly type: string,
    public title?: string,
    public onCancel?: () => void
  ) {
    this.progress = 0;
    this.state = "starting";
  }

  cancel() {
    if (this.onCancel) {
      this.onCancel();
    }
  }
}

interface ITaskState {
  tasks: Task[];
}

function updateTask(
  state: ITaskState,
  id: string,
  progress: number,
  taskState: string,
  cancel: (() => void) | undefined
) {
  return {
    ...state,
    tasks: state.tasks.map(a => {
      if (a.id === id) {
        a.progress = progress;
        a.state = taskState;
        a.onCancel = cancel;
      }
      return a;
    }),
  };
}

export function taskReducer(state: ITaskState = { tasks: [] }, action: OrthopredAction): ITaskState {
  switch (action.type) {
    case getType(cancelTask):
      const taskToCancel = state.tasks.find(a => a.id === action.payload.id);
      if (!taskToCancel) {
        return state;
      }
      taskToCancel.cancel();

      return { ...state, tasks: state.tasks.filter(a => a.id !== action.payload.id) };

    case getType(dismissTask):
      return { ...state, tasks: state.tasks.filter(a => a.id !== action.payload.id) };

    case getType(progressTask):
      return updateTask(state, action.payload.id, action.payload.progress, "running", action.payload.cancel);

    case getType(fileDownload.request):
      const oldDownloadTask = state.tasks.find(a => a.id === action.payload.info.fileId);

      if (oldDownloadTask) {
        return updateTask(state, action.payload.info.fileId, 0, "starting", undefined);
      }

      const downloadTask = new Task(action.payload.info.fileId, "FileDownload", action.payload.info.meta.patientId);
      return { ...state, tasks: [...state.tasks, downloadTask] };

    case getType(fileDownload.failure):
      return updateTask(state, action.payload.fileId, 100, "failed", undefined);

    case getType(fileDownload.success):
      return updateTask(state, action.payload.info.fileId, 100, "completed", undefined);

    case getType(fileUpload.request):
      const id = action.payload.source + action.payload.patientId + action.payload.seqType;
      const oldUploadTask = state.tasks.find(a => a.id === id);

      if (oldUploadTask) {
        return updateTask(state, id, 0, "starting", undefined);
      }

      const uploadTask = new Task(id, "FileUpload", action.payload.patientId);
      return { ...state, tasks: [...state.tasks, uploadTask] };

    case getType(fileUpload.failure):
      return updateTask(
        state,
        action.payload.source + action.payload.patientId + action.payload.seqType,
        100,
        "failed",
        undefined
      );

    case getType(fileUpload.success):
      return updateTask(
        state,
        action.payload.source + action.payload.patientId + action.payload.seqType,
        100,
        "completed",
        undefined
      );
    default:
      return state;
  }
}
