import { ValidationResult } from "./validationResult";
import { WebApiError } from "../api/WebApiError";

const humanReadableValidationMessages: { [field: string]: string } = {
  isEmail: "Nem megfelelo email formatum",
  passwordMismatch: "Nem megfelelo email vagy jelszo",
};

export class ValidationService {
  parseError(err: WebApiError): ValidationResult | undefined {
    const res = ValidationResult.fromApiError(err);
    if (res) {
      res.validationErrors.forEach((field) => {
        field.forEach((v) => (v.message = humanReadableValidationMessages[v.constraint] || v.constraint));
      });
      return res;
    }
    return undefined;
  }

  constructWithErrors(field: string, constraint: string) {
    const map = new Map<string, Array<{ constraint: string; message: string }>>([
      [field, [{ constraint, message: humanReadableValidationMessages[constraint] }]],
    ]);
    return new ValidationResult(map);
  }
}
