export enum UserPermission {
  ListUsers = "L",
  ManageUsers = "M",
  InviteUsers = "I",
  Login = "V",
}

function deserialize(str: string) {
  return new UserPermissions(...(str.split("") as UserPermission[]));
}

export class UserPermissions {

  static deserialize = deserialize;
  permissons: UserPermission[];

  constructor(...permissons: UserPermission[]) {
    this.permissons = permissons;
  }

  check(perm: UserPermission) {
    return this.permissons.includes(perm);
  }

  serialize() {
    return this.permissons.join("");
  }
}
