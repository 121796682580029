import { createAsyncAction } from "typesafe-actions";
import { WebApiError } from "../../modules/api/WebApiError";
import { ValidationResult } from "../../modules/validation/validationResult";

export enum RegistrationActions {
  Register = "Register",
  RegisterSuccess = "RegisterSuccess",
  RegisterFailure = "RegisterFailure",

  CheckVerification = "CheckVerification",
  CheckVerificationSuccess = "CheckVerificationSuccess",
  CheckVerificationFailure = "CheckVerificationFailure",
}

export const registration = createAsyncAction(
  RegistrationActions.Register,
  RegistrationActions.RegisterSuccess,
  RegistrationActions.RegisterFailure
)<
  { email: string; fullName: string; password: string },
  { email: string; stretchedPassword: Uint8Array },
  WebApiError | ValidationResult
>();
