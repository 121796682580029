import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileView: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      // justifyContent: "stretch",
      textAlign: "left",
    },
    fileViewTile: {
      display: "grid",
      gridTemplateColumns: "8px 1fr 8px",
      gridTemplateRows: "24px 1fr 8px",
      padding: 0,
      height: "100%",
      "&>div": {
        overflow: "hidden",
        padding: 0,
        margin: 0,
      },

      "& .titleBar": {
        display: "flex",
        justifyContent: "space-between",
      },

      "&>.MuiIconButton-root": {
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 500,
      },
    },
    toolDial: {
      position: "fixed",
      right: theme.spacing(6),
      bottom: theme.spacing(6),
    },
    grid: {
      flexGrow: 1,
      flexShrink: 1,
    },
    labelList: {
      listStyle: "none",
      paddingLeft: theme.spacing(1 / 2),
      "&>li": {
        paddingLeft: theme.spacing(1 / 2),
      },
    },
    dataList: {
      display: "grid",
      gridTemplateColumns: "max-content auto",
      gridAutoFlow: "row",
      gridRowGap: theme.spacing(1 / 2),
      gridColumnGap: theme.spacing(1),
      "&>dd": { margin: 0, overflow: "hidden" },
    },
    infoTile: {
      overflowX: "hidden",
      overflowY: "auto",
    },
    selectedLabel: {
      background: "lightgreen",
      "&::after": {
        content: '" \uD83D\uDD89"',
      },
    },

    progressModal: {
      display: "flex",
      outline: "none",
      justifyContent: "space-around",
      alignItems: "center",
      width: "100%",
      height: "100%",
      background: theme.palette.grey[600],
    },
  })
);
