import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userList: {
      display: "flex",
      flexDirection: "row",
      padding: "8px 12px",
    },

    chipInputWrapper: {
      flexGrow: 1,
      minWidth: theme.spacing(60),
      margin: theme.spacing(1),
    },
    chipInputChip: {
      margin: theme.spacing(0.5),
    },
  })
);
