import React, { useState, ReactElement, useRef, useEffect } from "react";

import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";

import Downshift from "downshift";

import { Chip, Popper, Paper } from "@material-ui/core";

export interface ISuggestion<T> {
  key: string;
  label: string;
  value: T;
}

type RenderInputProps = TextFieldProps & {
  ref?: React.Ref<HTMLDivElement>;
};

function renderInput(inputProps: RenderInputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        ...InputProps,
      }}
      variant="standard"
      {...other}
    />
  );
}

interface RenderSuggestionProps<T> {
  highlightedIndex: number | null;
  index: number;
  itemProps: MenuItemProps<"div", { button?: never }>;
  selectedItem: ISuggestion<T>["key"];
  suggestion: ISuggestion<T>;
}

function renderSuggestion<T>(suggestionProps: RenderSuggestionProps<T>) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || "").indexOf(suggestion.key) > -1;
  console.log("rend", suggestion);
  return (
    <MenuItem
      {...itemProps}
      key={suggestion.key}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

type IMultiChipInputProps<T> = {
  allowAny?: boolean;
  disabled?: boolean;
  maxItemCount?: number;
  getSuggestions: (val: string, selectedItems: Array<ISuggestion<T>>) => Array<ISuggestion<T>>;
  selectedChanged: (values: T[]) => void;
  classes: {
    input?: string;
    wrapper?: string;
    chip?: string;
    popper?: string;
  };
  isFullWidth?: boolean;
  label: string;
  defaultSelectedItems?: Array<ISuggestion<T>>;
};

export function MultiChipInput<T>(props: IMultiChipInputProps<T>): ReactElement | null {
  const selectedItems = props.defaultSelectedItems || [];
  const [inputValue, setInputValue] = useState("");
  const [errorText, setErrorText] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (errorText) {
      const timeout = setTimeout(() => setErrorText(""), 2000);
      return () => clearTimeout(timeout);
    }
  });

  const inputRef = useRef<HTMLDivElement>(null);

  function setSelection(selection: Array<ISuggestion<T>>) {
    if (props.maxItemCount !== undefined && selection.length > props.maxItemCount) {
      setErrorText(`Maximum ${props.maxItemCount} elem valaszthato ki`);
    } else {
      props.selectedChanged(selection.map((s) => s.value));
    }
  }

  function handleInputChange(event: React.ChangeEvent<{ value: string }>) {
    setInputValue(event.target.value);
  }

  function handleChange(item: ISuggestion<T>) {
    setInputValue("");
    if (item !== null && selectedItems.every((i) => i.key !== item.key)) {
      setSelection([...selectedItems, item]);
    }
  }

  const handleDelete = (item: ISuggestion<T>) => () => {
    const newSelectedItem = [...selectedItems];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    setSelection(newSelectedItem);
    setIsFocused(false);
  };

  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={selectedItems}
      itemToString={(it) => it.label}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        isOpen,
        inputValue: inputValue2,
        selectedItem: selectedItem2,
        highlightedIndex,
      }) => {
        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
          onKeyDown: (event: React.KeyboardEvent) => {
            if (selectedItems.length && !inputValue.length && event.key === "Backspace") {
              setSelection(selectedItems.slice(0, selectedItems.length - 1));
            }
            if (highlightedIndex === null && event.key === "Enter" && props.allowAny === true && inputValue.trim()) {
              handleChange({ key: inputValue, value: (inputValue as unknown) as T, label: inputValue });
            }
          },
        });

        return (
          <div className={props.classes.wrapper}>
            {renderInput({
              disabled: props.disabled,
              className: props.classes.input,
              fullWidth: props.isFullWidth,
              label: props.label,
              variant: "standard",
              ref: inputRef,
              error: !!errorText,
              helperText: errorText,
              InputLabelProps: {
                shrink: isFocused || !!inputValue2 || selectedItems.length > 0,
                ...getLabelProps(),
              },
              InputProps: {
                placeholder: selectedItems.length > 0 ? "..." : undefined,
                startAdornment: selectedItems
                  .filter((i) => !!i)
                  .map((suggestion) => {
                    return (
                      <Chip
                        variant="outlined"
                        size="small"
                        className={props.classes.chip}
                        key={"chip" + suggestion.key}
                        tabIndex={-1}
                        label={suggestion.label}
                        onDelete={props.disabled ? undefined : handleDelete(suggestion)}
                      />
                    );
                  }),
                onFocusCapture: (event) => {
                  setIsFocused(true);
                },
                onBlur: (event) => {
                  setInputValue("");
                  setIsFocused(false);
                  onBlur!(event as React.FocusEvent<HTMLInputElement>);
                },
                onChange: (event) => {
                  handleInputChange(event);
                  onChange!(event as React.ChangeEvent<HTMLInputElement>);
                },
                onFocus,
              },
              inputProps,
            })}

            <Popper open={isOpen} anchorEl={inputRef.current} placement="bottom-start" className={props.classes.popper}>
              <Paper>
                {props
                  .getSuggestions(inputValue2!, selectedItems)
                  .filter((sugg) => selectedItems.every((sel) => sel.key !== sugg.key))
                  .map((suggestion, index) => {
                    return renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem: selectedItem2,
                    });
                  })}
              </Paper>
            </Popper>
          </div>
        );
      }}
    </Downshift>
  );
}
