import React, { useState, useEffect } from "react";
import { Container, Switch } from "@material-ui/core";

import {
  SortingState,
  FilteringState,
  PagingState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, TableFilterRow, PagingPanel } from "@devexpress/dx-react-grid-material-ui";

import { IFileList, IUserList, IFileInfo, IUserInfo } from "../../state/admin/admin.reducers";
import { FilePermission } from "../../dtos/enums/filePermissions";
// import { useStyles } from "./adminFileList.style";
import { MultiChipInput } from "../MultiChipInput";
import { useStyles } from "./adminFileList.style";

export interface IFileListComponentProps {
  fileList: IFileList;
  userList: IUserList;

  sequenceTypes: string[];
  sequenceTypesState: string;

  listFiles: (sequenceTypes: string[], emails: string[], loadMore: boolean) => void;
  listUsers: (cursor: number) => void;
  loadSequenceTypes: () => void;

  setFilePermission: (email: string, fileId: string, value: boolean) => void;
}

export const AdminFileListComponent: React.FC<IFileListComponentProps> = ({
  fileList,
  userList,

  sequenceTypes,
  sequenceTypesState,

  listFiles,
  listUsers,
  setFilePermission,
  loadSequenceTypes,
}: IFileListComponentProps) => {
  const classes = useStyles();

  const [selectedUsers, setSelectedUsers] = useState<IUserInfo[]>([]);

  const [selectedSequenceTypes, setSelectedSequenceTypes] = useState<string[]>([]);

  useEffect(() => {
    if (!sequenceTypesState) {
      loadSequenceTypes();
    }
  });

  useEffect(() => {
    function checkUserList() {
      if (
        !userList.isFullyLoaded &&
        !userList.isLoadingMore &&
        !userList.isRefreshing &&
        (!userList.startDate || new Date().getTime() - userList.startDate.getTime() > 10000)
      ) {
        listUsers(0);
      }
    }
    checkUserList();

    const check = setInterval(checkUserList, 30000);
    return () => clearInterval(check);
  });

  useEffect(() => {
    listFiles(selectedSequenceTypes, selectedUsers.map(a => a.email), false);
  }, [selectedSequenceTypes, selectedUsers, listFiles]);
  const columns = [
    { name: "source", title: "Adatbazis" },
    { name: "patientId", title: "Beteg Azonosito" },
    { name: "bodyPart", title: "Testresz" },
    ...selectedSequenceTypes.map(t => ({ name: t, title: t })),
  ];

  const patients = new Map<string, any>();
  const allFiles = selectedSequenceTypes.reduce(
    (acc, curr) => acc.concat(...((fileList[curr] || {}).files || [])),
    [] as IFileInfo[]
  );
  for (const file of allFiles) {
    const meta = file.meta || {};
    const compId = `${meta.source}_${meta.patientId}_${meta.bodyPart}`;
    const val = patients.get(compId) || {
      source: meta.source,
      patientId: meta.patientId,
      bodyPart: meta.bodyPart,
    };
    val[meta.seqType] = file.fileId;

    val[meta.seqType + "_access"] = Object.keys(file.userPermissions).every(
      email => file.userPermissions[email] && file.userPermissions[email].check(FilePermission.View)
    );
    patients.set(compId, val);
  }

  const rows = Array.from(patients.values());

  const BooleanFormatter = (cell: any) => {
    if (!cell.value) {
      return <div />;
    }
    return (
      <Switch
        checked={cell.row[cell.column.name + "_access"]}
        color="primary"
        onChange={e => {
          selectedUsers.forEach(user => setFilePermission(user.email, cell.value, e.currentTarget.checked));
        }}
      />
    );
  };
  return (
    <Container component="main">
      <h1>Szekvenciak jog kezelese</h1>
      {/* <Box display="flex" flexDirection="row" flexWrap="wrap"> */}
      <MultiChipInput
        label="Felhasznalok"
        selectedChanged={(selection: IUserInfo[]) => {
          return setSelectedUsers(selection);
        }}
        classes={{ wrapper: classes.chipInputWrapper, chip: classes.chipInputChip }}
        isFullWidth={true}
        maxItemCount={1}
        getSuggestions={(val: string) => {
          return (
            userList.users &&
            userList.users
              .filter(a => a.email.toLocaleLowerCase().includes(val.toLocaleLowerCase()))
              .map(a => ({ key: a.email, label: a.fullName ? `${a.fullName} (${a.email})` : a.email, value: a }))
          );
        }}
      />
      <MultiChipInput
        label="Szekvencia tipusok"
        allowAny={true}
        maxItemCount={2}
        classes={{ wrapper: classes.chipInputWrapper, chip: classes.chipInputChip }}
        isFullWidth={true}
        selectedChanged={(selection: string[]) => setSelectedSequenceTypes(selection)}
        getSuggestions={(val: string) =>
          sequenceTypes &&
          sequenceTypes
            .filter(a => a.toLocaleLowerCase().includes(val.toLocaleLowerCase()))
            .map(a => ({ key: a, label: a, value: a }))
        }
      />
      {/* </Box> */}
      <h2>Szekvenciak</h2>
      <Grid rows={rows} columns={columns}>
        <FilteringState />
        <SortingState />
        <PagingState pageSize={50} />

        <IntegratedFiltering />
        <IntegratedSorting />
        <IntegratedPaging />

        <DataTypeProvider formatterComponent={BooleanFormatter} for={selectedSequenceTypes} />

        <Table />

        <TableHeaderRow showSortingControls={true} />

        <TableFilterRow />
        <PagingPanel pageSizes={[50, 100, 200]} />
      </Grid>
    </Container>
  );
};
