import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      alignContent: "flex-start",
      maxWidth: "600px",
    },
    submitButton: {
      marginTop: theme.spacing(2),
    },
    dropzone: {
      marginTop: theme.spacing(2),
    },
  })
);
