import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      textAlign: "left",
    },
    chipInputWrapper: {
      flexGrow: 1,
      minWidth: theme.spacing(60),
      // margin: theme.spacing(1),
    },
    chipInputChip: {
      margin: theme.spacing(0.5),
    },
    filterInput: {},
    subHeading: {},
    permissions: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
    },
    permissionBlock: {
      "display": "grid",
      "gridTemplateColumns": "max-content max-content max-content",
      "gridAutoRows": "max-content",
      "alignItems": "center",
      "&>span:not(.MuiIconButton-root)": {
        margin: theme.spacing(0.5),
      },
      "&>h4": {
        gridColumn: "1/4",
      },
    },
    gridList: {
      display: "grid",
      gridAutoRows: "max-content",
      textAlign: "left",
      padding: theme.spacing(1),
      gridGap: theme.spacing(1),
      [theme.breakpoints.up("xs")]: {
        gridTemplateColumns: "1fr",
      },
      [theme.breakpoints.up("sm")]: {
        gridTemplateColumns: "1fr 1fr",
      },
      [theme.breakpoints.up("md")]: {
        gridTemplateColumns: "1fr 1fr 1fr",
      },
      [theme.breakpoints.up("lg")]: {
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
      },
    },
    gridTile: {
      "&>h5": {
        display: "inline-block",
      },
    },
    saveFab: {
      position: "absolute",
      right: "10vh",
      bottom: "10vh",
    },
  })
);
