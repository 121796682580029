import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelCardTitle: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(1),
    },
    settingsForm: {
      display: "flex",
      flexGrow: 1,
      flexWrap: "wrap",
      flexDirection: "row",
      alignContent: "start",
      "& > .MuiFormControl-root, .twitter-picker": {
        margin: theme.spacing(1),
        flexGrow: 1,
      },
      "& .MuiTextField-root": {
        width: "5em",
      }
    },
    saveButton: {

    },
    settingsPaper: {
      maxWidth: theme.spacing(50),
      padding: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
    },
  })
);

export const useColorPickerStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    background: '#fff',
    border: '0 solid rgba(0,0,0,0.25)',
    boxShadow: '0 1px 4px rgba(0,0,0,0.25)',
    borderRadius: '4px',
    position: 'relative',
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  swatch: {
    width: '30px',
    height: '30px',
    borderRadius: '4px',
    margin: theme.spacing(1 / 2),
    cursor: "pointer",

    "&.active": {
      border: "solid 2px black",
    }
  },
  hash: {
    background: '#F0F0F0',
    height: '30px',
    width: '30px',
    borderRadius: '4px 0 0 4px',
    float: 'left',
    color: '#98A1A4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1 / 2),
    marginRight: 0,
  },
  input: {
    width: '2.5em',
    fontSize: '14px',
    color: '#666',
    border: '0px',
    outline: 'none',
    height: '28px',
    boxShadow: 'inset 0 0 0 1px #F0F0F0',
    boxSizing: 'content-box',
    borderRadius: '0 4px 4px 0',
    float: 'left',
    paddingLeft: theme.spacing(1 / 2),
    margin: theme.spacing(1 / 2),
    marginLeft: 0,
    flexGrow: 1
  },

  alphaContainer: {
    margin: theme.spacing(1 / 2),
    marginBottom: theme.spacing(1),
    width: "100%",
    flexGrow: 1,
    clear: "both",
    position: "relative"
  }
}));