import { gql } from "apollo-boost";

export type LabelMode = {
  id: string;
  name: string;
  labels: string[];
  defaultLabel: number;
};

export const labelModeListQuery = gql`
  query listLabelModes {
    listLabelModes {
      id
      name
      labels
      defaultLabel
    }
  }
`;
