import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    layerContainer: {
      position: "relative",

      "& .imageLayer": {
        position: "absolute",
        left: 0,
      },
      "& .drawDiv2": {
        position: "absolute",
        left: 0,
        top: 0,
      },
    },
    progressModal: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      "& *": {
        outline: "none",
      },
    },
  })
);
