export enum FilePermission {
  Delete = "D",
  View = "V",
}

export class FilePermissions {
  permissons: FilePermission[];

  constructor(...permissons: FilePermission[]) {
    this.permissons = permissons;
  }

  static deserialize(str: string) {
    return new FilePermissions(...(str ? (str.split("") as FilePermission[]) : []));
  }

  check(perm: FilePermission) {
    return this.permissons.includes(perm);
  }

  serialize() {
    return this.permissons.join("");
  }
}
