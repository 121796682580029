import { combineEpics, Epic } from "redux-observable";

import { filter, mergeMap, withLatestFrom } from "rxjs/operators";

import { isActionOf } from "typesafe-actions";

import { WebApi } from "../../modules/api/webApi";
import { WebApiError } from "../../modules/api/WebApiError";
import { OrthopredAction } from "../actions";
import { OrthopredState } from "../reducers";

import { fileUpload, listFiles, fileDownload, deleteDownloadedFile } from "./file.actions";
import { LoginStatus } from "../session/session.state";
import { LocalDB } from "../../modules/localDB/localDB";
import { Subject } from "rxjs";
import { progressTask } from "../task/task.actions";
import { IFileInfo } from "../admin/admin.reducers";

const listFilesEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi; localDB: LocalDB }> = (
  action$,
  state$,
  { api, localDB }
) => {
  return action$.pipe(
    filter(isActionOf(listFiles.request)),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
          throw new Error("NotLoggedIn");
        }
        const { sharedKey, sessionId } = state.loginState.sessionInfo;
        const startCursor = state.files.fileList.cursor;
        const res = await api.getAccessibleFiles(sessionId, sharedKey, action.payload.isLoadMore ? startCursor : 0);
        const storedIds = await localDB.getStoredFiles();

        // TODO: combine
        const downloadedFileIds = storedIds.filter((id) =>
          res.accessibleFiles.some((af: IFileInfo) => af.fileId === id)
        );
        const unavailableFileIds = storedIds.filter((id) =>
          res.accessibleFiles.every((af: IFileInfo) => af.fileId !== id)
        );

        for (const id of unavailableFileIds) {
          await localDB.removeFile(id);
        }
        const downloadedFiles = await Promise.all(downloadedFileIds.map((id) => localDB.getStoredFile(id)));

        return listFiles.success({
          files: res.accessibleFiles,
          startCursor,
          downloadedFileIds,
          downloadedFiles,
          cursor: res.cursor,
        });
      } catch (err) {
        if (err instanceof WebApiError) {
          return listFiles.failure(err);
        }

        throw err;
      }
    })
  );
};

const downloadFileEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi; localDB: LocalDB }> = (
  action$,
  state$,
  { api, localDB }
) => {
  return action$.pipe(
    filter(isActionOf(fileDownload.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const subject = new Subject<OrthopredAction>();
      let cancelled = false;
      const fileId = action.payload.info.fileId;
      const impl = async () => {
        try {
          if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
            return subject.next(fileDownload.failure({ fileId, err: new WebApiError(403, "NotLoggedIn") }));
          }
          const { sharedKey, sessionId } = state.loginState.sessionInfo;

          if (cancelled) {
            return subject.next(fileDownload.failure({ fileId, err: new WebApiError(499, "Cancelled") }));
          }

          subject.next(
            progressTask({
              id: fileId,
              state: "running",
              progress: 0,
              cancel: () => {
                cancelled = true;
              },
            })
          );

          const { prom, cancel } = await api.getFile(
            fileId,
            (prog) => {
              subject.next(
                progressTask({
                  id: fileId,
                  state: "running",
                  progress: prog.lengthComputable ? (prog.total / prog.loaded) * 100 : -1,
                  cancel,
                })
              );
            },
            sessionId,
            sharedKey
          );
          if (cancelled) {
            cancel();
            return subject.next(fileDownload.failure({ fileId, err: new WebApiError(499, "Cancelled") }));
          }
          subject.next(progressTask({ id: fileId, state: "running", progress: 1, cancel }));

          prom.then(
            async (blob) => {
              await localDB.saveFile(fileId, action.payload.info.meta, blob);
              subject.next(fileDownload.success({ info: action.payload.info, blob }));
            },
            (err) => subject.next(fileDownload.failure({ fileId, err }))
          );
        } catch (err) {
          return subject.next(fileDownload.failure({ fileId, err }));
        }
      };
      impl();
      return subject;
    })
  );
};

const deleteDownloadedFileEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { localDB: LocalDB }> = (
  action$,
  state$,
  { localDB }
) => {
  return action$.pipe(
    filter(isActionOf(deleteDownloadedFile.request)),
    withLatestFrom(state$),
    mergeMap(async ([action, state]) => {
      try {
        await localDB.removeFile(action.payload.id);
        const downloadedFileIds = (await localDB.getStoredFiles()).filter((id) =>
          state.files.fileList.files.some((af: IFileInfo) => af.fileId === id)
        );
        const downloadedFiles = await Promise.all(downloadedFileIds.map((id) => localDB.getStoredFile(id)));
        return deleteDownloadedFile.success({ downloadedFileIds, downloadedFiles });
      } catch (err) {
        return deleteDownloadedFile.failure({ fileId: action.payload.id, err });
      }
    })
  );
};
const uploadEpic: Epic<OrthopredAction, OrthopredAction, OrthopredState, { api: WebApi }> = (
  action$,
  state$,
  { api }
) => {
  return action$.pipe(
    filter(isActionOf(fileUpload.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const subject = new Subject<OrthopredAction>();

      let cancelled = false;
      const id = action.payload.source + action.payload.patientId + action.payload.seqType;
      const impl = async () => {
        try {
          if (state.loginState.loginStatus !== LoginStatus.LoggedIn) {
            return subject.next(fileUpload.failure({ ...action.payload, err: new WebApiError(403, "NotLoggedIn") }));
          }
          const { sharedKey, sessionId } = state.loginState.sessionInfo;

          if (cancelled) {
            return subject.next(fileUpload.failure({ ...action.payload, err: new WebApiError(499, "Cancelled") }));
          }

          subject.next(
            progressTask({
              id,
              state: "running",
              progress: 0,
              cancel: () => {
                cancelled = true;
              },
            })
          );

          const { prom, cancel } = await api.saveFile(
            action.payload.source,
            action.payload.patientId,
            action.payload.bodyPart,
            action.payload.seqType,
            action.payload.modality,
            action.payload.imgNo,

            action.payload.file,

            sessionId,
            sharedKey,
            (prog) => {
              subject.next(
                progressTask({
                  id,
                  state: "running",
                  progress: prog.lengthComputable ? (prog.total / prog.loaded) * 100 : -1,
                  cancel,
                })
              );
            }
          );
          if (cancelled) {
            cancel();
            return subject.next(fileUpload.failure({ ...action.payload, err: new WebApiError(499, "Cancelled") }));
          }
          subject.next(
            progressTask({
              id,
              state: "running",
              progress: 1,
              cancel,
            })
          );

          prom.then(
            async (res: { fileId: string }) => {
              subject.next(fileUpload.success({ ...action.payload, fileId: res.fileId }));
            },
            (err) => subject.next(fileUpload.failure({ ...action.payload, err }))
          );
        } catch (err) {
          return subject.next(fileUpload.failure({ ...action.payload, err }));
        }
      };

      impl();
      return subject;
    })
  );
};

export const fileEpics = combineEpics(uploadEpic, listFilesEpic, downloadFileEpic, deleteDownloadedFileEpic);
