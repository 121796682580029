import React, { useState, useEffect, ReactNode, useContext } from "react";
import { Redirect, NavLink } from "react-router-dom";
import {
  AppBar,
  CssBaseline,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Divider,
  Slide,
  useScrollTrigger,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";

import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import UploadIcon from "@material-ui/icons/CloudUpload";
import Done from "@material-ui/icons/Done";
import DoneAll from "@material-ui/icons/DoneAll";
import SettingsIcon from "@material-ui/icons/Settings";
import ImageIcon from "@material-ui/icons/Image";
import ListIcon from "@material-ui/icons/List";
import ViewListIcon from "@material-ui/icons/ViewList";

import { LoginStatus } from "../../state/session/session.state";
import { NotValidatedContainer } from "../notValidated/notValidated.container";
import { useStyles } from "./layout.styles";
import { UserPermission } from "../../dtos/enums/userPermissions";
import { IUserIdentity } from "../../state/session/session.state";
import { UserFilePermission } from "../../dtos/enums/userFilePermissions";
import { History } from "history";

import { TaskContext, useDownloadTasks } from "../../modules/task.hooks";
import { ProgressPanelComponent } from "../progressPanel/progressPanel.component";
import { LabelerAutoSaveContext, useLabelerAutosaveHooks } from "../fileView/useAutoSave";
import { useUserFileList } from "../../queries/fileList.query";
import { LocalDBContext } from "../../modules/localDb.context";

interface ILayoutProps {
  loginStatus: LoginStatus;
  identity: IUserIdentity | undefined;

  children: ReactNode;
  history: History;

  checkSession: () => void;
  logout: () => void;
}

if (process.env.NODE_ENV !== "production") {
  // tslint:disable-next-line:no-var-requires
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, { include: /.*/, logOnDifferentValues: false });
}

export const LayoutComponent: React.FC<ILayoutProps> = ({
  history,
  children,

  loginStatus,
  identity,

  checkSession,
  logout,
}: ILayoutProps) => {
  const classes = useStyles();
  const titles: Array<{ label: string; to: string }> = [{ label: "Annotator", to: "/" }];

  const localDb = useContext(LocalDBContext);
  const fileList = useUserFileList();

  useEffect(() => {
    if (loginStatus === LoginStatus.Unknown) {
      checkSession();
    }
  });

  useEffect(() => {
    if (fileList.data) {
      localDb.cleanFiles(fileList.data);
    }
  });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [accountMenuRoot, setAccountMenuRoot] = useState<HTMLElement | null>(null);
  const taskState = useDownloadTasks();
  const autosaveHooks = useLabelerAutosaveHooks(5000);

  const scrollTrigger = useScrollTrigger();

  let checkIcon = false;
  let isDoneAll = false;
  let checkClassName = "";

  const path = history.location.pathname;

  if (path.startsWith("/images")) {
    titles.push({ label: "Kepek", to: "/images" });
    if (path.length > 8) {
      const [workpackId, patientId] = path.substr(8).split("/");
      if (fileList.data) {
        checkIcon = true;
        const progress = fileList.data.me.currentProgresses.find((p) => p.workpack.id === workpackId);
        if (!progress) {
          return <Redirect to="/" />;
        }
        titles.push({ label: progress.workpack.name, to: path });
        titles.push({
          label: `${patientId} (${progress.workpack.patients.findIndex((p) => p.id === patientId) + 1}/${
            progress.workpack.patients.length
          })`,
          to: path,
        });
        isDoneAll = progress.completedPatientIds.length === progress.workpack.patients.length;
        console.log("asdf", isDoneAll);
        if (isDoneAll) {
          checkClassName = classes.done;
        } else {
          checkClassName = classes.incomplete;
        }
      } else {
        titles.push({ label: "...", to: path });
      }
    }
  } else if (path.startsWith("/users")) {
    titles.push({ label: "Felhasznalok", to: "/users" });
    if (path.length > 6) {
      titles.push({ label: path.substr(7), to: path });
    }
  } else if (path.startsWith("/upload")) {
    titles.push({ label: "Feltoltes", to: "/upload" });
  }

  switch (loginStatus) {
    case LoginStatus.Unknown:
    case LoginStatus.LoggingIn:
      return <CircularProgress />;
    case LoginStatus.LoggedIn:
      return (
        <div className={classes.pageContainer}>
          <TaskContext.Provider value={taskState}>
            <LabelerAutoSaveContext.Provider value={autosaveHooks}>
              <CssBaseline />
              <Slide appear={false} direction="down" in={!scrollTrigger}>
                <AppBar className={classes.appBar} color="secondary">
                  <Toolbar variant="dense">
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="Menu"
                      className={classes.menuButton}
                      onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                      {sidebarOpen ? <ChevronLeftIcon /> : <MenuIcon />}
                    </IconButton>
                    <Typography className={classes.title} variant="h6">
                      {titles.map((t, i) => (
                        <span key={`breadcrumb_${i}`}>
                          <NavLink to={t.to} isActive={() => false}>
                            {t.label}
                          </NavLink>
                        </span>
                      ))}
                    </Typography>
                    {checkIcon && (
                      <IconButton
                        aria-label="Account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        className={checkClassName}
                      >
                        {isDoneAll ? <DoneAll /> : <Done />}
                      </IconButton>
                    )}
                    <ProgressPanelComponent />

                    <IconButton
                      aria-label="Account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={(e) => setAccountMenuRoot(e.currentTarget)}
                      color="inherit"
                    >
                      <AccountCircleIcon />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={accountMenuRoot}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      keepMounted={true}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={!!accountMenuRoot}
                      onClose={() => setAccountMenuRoot(null)}
                    >
                      <MenuItem onClick={() => setAccountMenuRoot(null)}>{identity!.email}</MenuItem>
                      <MenuItem
                        onClick={() => {
                          localStorage.setItem(
                            "preferredTheme",
                            localStorage.getItem("preferredTheme") === "dark" ? "light" : "dark"
                          );
                          window.dispatchEvent(new Event("storage"));
                        }}
                      >
                        Szin valtas
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          logout();
                          setAccountMenuRoot(null);
                        }}
                      >
                        Kijelentkezes
                      </MenuItem>
                    </Menu>
                  </Toolbar>
                </AppBar>
              </Slide>
              <div className={classes.mainContainer}>
                <Drawer
                  variant="permanent"
                  anchor="left"
                  open={sidebarOpen}
                  classes={{
                    paper: clsx(classes.drawerPaper, !sidebarOpen && classes.drawerPaperClose),
                  }}
                >
                  <List component="nav" className={classes.sidebarNavList}>
                    <ListItem
                      button={true}
                      onClick={() => setSidebarOpen(!sidebarOpen)}
                      className={classes.appBarSpacer}
                    >
                      <ListItemIcon>{sidebarOpen ? <ChevronLeftIcon /> : <MenuIcon />}</ListItemIcon>
                    </ListItem>
                    <ListItem
                      button={true}
                      component={NavLink}
                      activeClassName="Mui-selected"
                      to="/image"
                      isActive={(_m, l) => l.pathname.startsWith("/images/")}
                    >
                      <ListItemIcon>
                        <ImageIcon />
                      </ListItemIcon>
                      <ListItemText> Aktualis kep </ListItemText>
                    </ListItem>

                    <ListItem button={true} component={NavLink} activeClassName="Mui-selected" to="/images">
                      <ListItemIcon>
                        <ViewListIcon />
                      </ListItemIcon>
                      <ListItemText> Kepek </ListItemText>
                    </ListItem>

                    <ListItem button={true} component={NavLink} activeClassName="Mui-selected" to="/settings">
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText> Beallitasok </ListItemText>
                    </ListItem>

                    <Divider />

                    {identity!.filePermissions!.check(UserFilePermission.FileUpload) && (
                      <ListItem button={true} component={NavLink} activeClassName="Mui-selected" to="/upload">
                        <ListItemIcon>
                          <UploadIcon />
                        </ListItemIcon>
                        <ListItemText> Feltoltes </ListItemText>
                      </ListItem>
                    )}

                    {identity!.userPermissions!.check(UserPermission.ListUsers) && (
                      <ListItem button={true} component={NavLink} activeClassName="Mui-selected" to="/users">
                        <ListItemIcon>
                          <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText> Felhasznalok </ListItemText>
                      </ListItem>
                    )}

                    {identity!.userPermissions!.check(UserPermission.ListUsers) && (
                      <ListItem button={true} component={NavLink} activeClassName="Mui-selected" to="/labelModes">
                        <ListItemIcon>
                          <ListIcon />
                        </ListItemIcon>
                        <ListItemText> Cimkezesi modok </ListItemText>
                      </ListItem>
                    )}

                    {identity!.userPermissions!.check(UserPermission.ListUsers) && (
                      <ListItem button={true} component={NavLink} activeClassName="Mui-selected" to="/workflows">
                        <ListItemIcon>
                          <ListIcon />
                        </ListItemIcon>
                        <ListItemText> Munkafolyamatok </ListItemText>
                      </ListItem>
                    )}

                    {identity!.userPermissions!.check(UserPermission.ListUsers) && (
                      <ListItem button={true} component={NavLink} activeClassName="Mui-selected" to="/workpacks">
                        <ListItemIcon>
                          <ListIcon />
                        </ListItemIcon>
                        <ListItemText> Munkacsomagok </ListItemText>
                      </ListItem>
                    )}
                  </List>
                </Drawer>
                <div
                  className={clsx(classes.sideBarSpacer, classes.drawerPaper, !sidebarOpen && classes.drawerPaperClose)}
                />
                <div className={classes.contentContainer}>
                  <div className={classes.appBarSpacer} />

                  {identity!.userPermissions!.check(UserPermission.Login) ? children : <NotValidatedContainer />}
                  <footer>Lengyel.tech&copy;2019</footer>
                </div>
              </div>
            </LabelerAutoSaveContext.Provider>
          </TaskContext.Provider>
        </div>
      );
    default:
      return <Redirect to="/login" />;
  }
};
(LayoutComponent as any).whyDidYouRender = true;
