import { OrthopredAction } from "../actions";
import { WebApiError } from "../../modules/api/WebApiError";
import { getType } from "typesafe-actions";
import { dismissError, failedAction } from "./error.actions";
import { ValidationResult } from "../../modules/validation/validationResult";

interface IErrorState {
  errors: failedAction[];
}

export function errorReducer(state: IErrorState = { errors: [] }, action: OrthopredAction): IErrorState {
  switch (action.type) {
    case getType(dismissError):
      return { ...state, errors: state.errors.filter((a) => a !== action.payload.failure) };
    default:
      if (
        action.type.endsWith("Failure") ||
        action.payload instanceof WebApiError ||
        action.payload instanceof ValidationResult
      ) {
        return { errors: state.errors.concat(action as failedAction) };
      }
      return state;
  }
}
