import { getType } from "typesafe-actions";

import { OrthopredAction } from "../actions";
import * as RegistrationActions from "./registration.actions";

export type RegStatus = "None" | "Registering" | "Registered" | "Failed";

export interface IRegistrationStatus {
  state: RegStatus;
}

export function registrationState(
  state: IRegistrationStatus = { state: "None" },
  action: OrthopredAction
): IRegistrationStatus {
  switch (action.type) {
    case getType(RegistrationActions.registration.request):
      return { state: "Registering" };
    case getType(RegistrationActions.registration.success):
      return {
        state: "Registered",
      };
    case getType(RegistrationActions.registration.failure):
      return {
        state: "Failed",
      };

    default:
      return state;
  }
}
