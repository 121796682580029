import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  CircularProgress,
  ListItem,
  ListItemText,
  ListItemIcon,
  List,
  Typography,
  ListItemSecondaryAction,
} from "@material-ui/core";

import DownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import OpenIcon from "@material-ui/icons/OpenInBrowser";
import ErrorIcon from "@material-ui/icons/Error";
import { History } from "history";

import { useStyles } from "./fileList.style";
import { LoadingView } from "../loadingView.component";
import { LocalDBContext } from "../../modules/localDb.context";
import { useFileDownloader } from "../../modules/file.hooks";
import { useUserFileList } from "../../queries/fileList.query";

export interface IFileListComponentProps {
  history: History;
}

export const FileListView: React.FC<IFileListComponentProps> = ({ history }: IFileListComponentProps) => {
  const classes = useStyles();

  const localDb = useContext(LocalDBContext);
  const [downloadedFileIds, setDownloadedFileIds] = useState<string[] | undefined>(undefined);
  const [tasks, downloadFile] = useFileDownloader();

  useEffect(() => {
    if (!downloadedFileIds || tasks.successes.some((t) => !downloadedFileIds.includes(t.id))) {
      localDb.getStoredFiles().then(setDownloadedFileIds, (err) => {
        setDownloadedFileIds([]);
        // tslint:disable-next-line:no-console
        console.error(err);
      });
    }
  }, [downloadedFileIds, localDb, tasks]);
  const { data, called, loading, error } = useUserFileList();

  if (!called || loading) {
    return <LoadingView />;
  }

  if (!data || error) {
    return <main>{JSON.stringify(error)}</main>;
  }
  const currentProgresses = data.me.currentProgresses;
  return (
    <Container component="main" maxWidth="lg">
      {currentProgresses.map((prog) => {
        const wp = prog.workpack;
        return (
          <section key={wp.id} className={classes.listContainer}>
            <Typography variant="h3">{wp.name}</Typography>
            <Typography variant="subtitle1">{wp.sequenceTypes.join(" - ")}</Typography>
            <List>
              {wp.patients
                .sort((a, b) => a.id.localeCompare(b.id))
                .map((p) => {
                  const pprog = prog.patientProgresses.find((pp) => pp.id === p.id);
                  const canOpen = wp.sequenceTypes.every((s) => p.sequenceTypes.includes(s));
                  const downloaded =
                    canOpen &&
                    downloadedFileIds &&
                    wp.sequenceTypes.every((s) => downloadedFileIds.includes(p.files.find((f) => f.seqType === s)!.id));
                  const downloading =
                    canOpen &&
                    wp.sequenceTypes.some((s) =>
                      tasks.inProgressTasks.find((t) => t.id === p.files.find((f) => f.seqType === s)!.id)
                    );

                  return (
                    <ListItem key={p.id} dense={true}>
                      <ListItemIcon>
                        <div className={classes.userProgress}>
                          <CircularProgress variant="determinate" value={100} color={"secondary"} />
                          <CircularProgress
                            value={pprog ? (pprog.completedSteps.length / wp.workflow.length) * 100 : 0}
                            variant="static"
                          />
                        </div>
                      </ListItemIcon>
                      <ListItemText primary={p.id} secondary={`${p.source}${p.bodyPart ? " - " + p.bodyPart : ""}`} />
                      <ListItemSecondaryAction>
                        {downloaded ? (
                          <>
                            <OpenIcon color="primary" onClick={() => history.push(`/images/${wp.id}/${p.id}`)} />
                            <DeleteIcon
                              color="error"
                              onClick={() =>
                                p.files.forEach((f) =>
                                  localDb.removeFile(f.id).then(() => setDownloadedFileIds(undefined))
                                )
                              }
                            />
                          </>
                        ) : downloading ? (
                          <CircularProgress color="primary" />
                        ) : canOpen ? (
                          <DownloadIcon
                            color="primary"
                            onClick={() =>
                              wp.sequenceTypes.forEach(async (s) => {
                                const f = p.files.find((cf) => cf.seqType === s);
                                downloadFile(f!.id, p.id, s, f);
                              })
                            }
                          />
                        ) : (
                          <ErrorIcon />
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
            </List>
          </section>
        );
      })}
    </Container>
  );
};

export default FileListView;
