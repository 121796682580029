import { createAsyncAction, createAction } from "typesafe-actions";
import { WebApiError } from "../../modules/api/WebApiError";
import { ILabelData, ILogData, ISliceLabelData } from "./labling.reducers";

export enum LabelingActions {
  LoadLabels = "LoadLabels",
  LoadLabelsSuccess = "LoadLabelsSuccess",
  LoadLabelsFailure = "LoadLabelsFailure",

  SaveLabels = "SaveLabels",
  SaveLabelsSuccess = "SaveLabelsSuccess",
  SaveLabelsFailure = "SaveLabelsFailure",

  SetSliceLabel = "SetSliceLabel",
  UpdateLabel = "UpdateLabel",
  AddLog = "AddLog",
}

export const loadLabels = createAsyncAction(
  LabelingActions.LoadLabels,
  LabelingActions.LoadLabelsSuccess,
  LabelingActions.LoadLabelsFailure
)<{ fileId: string }, { fileId: string; labels: Array<ILabelData & { slice: number }>, sliceLabels: number[] }, WebApiError>();

export const saveLabels = createAsyncAction(
  LabelingActions.SaveLabels,
  LabelingActions.SaveLabelsSuccess,
  LabelingActions.SaveLabelsFailure
)<void, { saved: ILabelData[]; savedSliceLabels: ISliceLabelData[]; savedLogs: ILogData[] }, WebApiError>();

export const updateLabel = createAction(LabelingActions.UpdateLabel, action => (payload: ILabelData) =>
  action(payload)
);

export const setSliceLabel = createAction(LabelingActions.SetSliceLabel, action => (payload: ISliceLabelData) =>
  action(payload)
);

export const addLog = createAction(
  LabelingActions.AddLog,
  action => (payload: { message: string; slice: number; fileId: string }) => action(payload)
);
