export class WebApiError<T = undefined> {
  public error: Error;
  public type: string;
  public inner: any;
  public originalPayload: T | undefined;

  constructor(public statusCode: number, public innerText: string) {
    this.type = "WebApiError";
    this.error = new Error();
    try {
      this.inner = JSON.parse(innerText);
    } catch (_) {
      // Ignored, the server may return non-json errors
    }
  }
}
