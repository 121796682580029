import React from "react";

import { CircularProgress, makeStyles, Theme, createStyles, Container } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    progressView: {
      display: "flex",
      alignContent: "center",
      alignItems: "middle",
    },
    bigProgress: {},
  });
});

export const LoadingView: React.SFC = () => {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="md" className={classes.progressView}>
      <CircularProgress className={classes.bigProgress} size="lg" />;
    </Container>
  );
};
