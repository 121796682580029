import { useState, useEffect, createContext, useCallback, useMemo } from "react";
import { IDownloadIntent } from "./file.hooks";

export enum TaskStates {
  InProgress,
  Failed,
  Succeeded,
}

export interface ITask<S extends TaskStates = TaskStates> {
  started: number;
  id: string;
  title: string;
  subTitle?: string;
  progress: number | undefined;
  isDefinite: boolean;
  status: S;
  step?: string;
  reason: S extends TaskStates.Failed ? string : undefined;
  cancel: S extends TaskStates.InProgress ? () => void : undefined;
}

export interface ITaskState {
  inProgressTasks: Array<ITask<TaskStates.InProgress>>;
  failures: Array<ITask<TaskStates.Failed>>;
  successes: Array<ITask<TaskStates.Succeeded>>;
}

enum UpdateTypes {
  Add,
  Update,
  Success,
  Fail,
}

type TaskUpdate =
  | {
      type: UpdateTypes.Add;
      timestamp: number;
      task: ITask<TaskStates.InProgress>;
    }
  | {
      type: UpdateTypes.Update;
      timestamp: number;
      task: {
        id: string;
        progress: number;
        step?: string;
        isDefinite: boolean;
        cancel: () => void;
      };
    }
  | {
      type: UpdateTypes.Success;
      timestamp: number;
      task: {
        id: string;
      };
    }
  | {
      type: UpdateTypes.Fail;
      timestamp: number;
      task: {
        id: string;
        reason: string;
      };
    };

interface IDownloadTaskState {
  tasks: ITaskState;
  addTask: (intent: IDownloadIntent, cancel: () => void) => void;
  updateTask: (
    intent: IDownloadIntent,
    progress: number,
    isDefinite: boolean,
    cancel: () => void,
    step?: string
  ) => void;
  markFailure: (intent: IDownloadIntent, reason: string) => void;
  markSuccess: (intent: IDownloadIntent) => void;
  dismissTask: (id: string) => void;
}

const defaultTaskListState: ITaskState = { inProgressTasks: [], failures: [], successes: [] };
export function useDownloadTasks(): IDownloadTaskState {
  const [tasks, setTasks] = useState<ITaskState>(defaultTaskListState);
  const [updateQueue, setUpdateQueue] = useState<TaskUpdate[]>([]);

  useEffect(() => {
    const [top, ...rest] = updateQueue;
    if (!top) {
      return;
    }
    if (top.type === UpdateTypes.Add) {
      setTasks((s) => {
        return {
          successes: s.successes.filter((t) => t.id !== top.task.id),
          failures: s.failures.filter((t) => t.id !== top.task.id),
          inProgressTasks: s.inProgressTasks.filter((t) => t.id !== top.task.id).concat([top.task]),
        };
      });
    } else {
      const curr = rest.reduce((o, c) => (c.timestamp > o.timestamp ? c : o), top);
      setTasks((s) => {
        const task = s.inProgressTasks.find((t) => t.id === top.task.id)!;
        if (!task) return s;
        const inProgressTasks = s.inProgressTasks.filter((t) => t !== task);
        if (curr.type === UpdateTypes.Update) {
          inProgressTasks.push({ ...task, ...curr.task });
        }
        const successes =
          curr.type === UpdateTypes.Success
            ? s.successes.concat([
                {
                  ...task,
                  status: TaskStates.Succeeded,
                  cancel: undefined,
                },
              ])
            : s.successes;
        const failures =
          curr.type === UpdateTypes.Fail
            ? s.failures.concat([
                {
                  ...task,
                  status: TaskStates.Failed,
                  reason: curr.task.reason,
                  cancel: undefined,
                },
              ])
            : s.failures;
        return {
          ...s,
          inProgressTasks,
          successes,
          failures,
        };
      });
    }
    setUpdateQueue((q) =>
      q.filter(
        (u) =>
          u.task.id !== top.task.id ||
          u.timestamp > top.timestamp ||
          (top.type === UpdateTypes.Update && [UpdateTypes.Fail, UpdateTypes.Success].includes(u.type))
      )
    );
  }, [setUpdateQueue, updateQueue]);

  const addTask = useCallback(
    (intent: IDownloadIntent, cancel: () => void) => {
      setUpdateQueue((s) => {
        return s.concat([
          {
            type: UpdateTypes.Add,
            timestamp: Date.now(),
            task:
              intent.type === "File"
                ? {
                    started: Date.now(),
                    id: intent.fileId,
                    title: intent.patientId,
                    subTitle: intent.sequenceType,
                    status: TaskStates.InProgress,
                    step: "starting",
                    progress: 0,
                    isDefinite: false,
                    reason: undefined,
                    cancel,
                  }
                : {
                    started: Date.now(),
                    id: intent.packId + intent.type,
                    title: `${intent.packId}_${intent.type.toLocaleLowerCase()}.zip`,
                    subTitle: undefined,
                    status: TaskStates.InProgress,
                    step: "starting",
                    progress: 0,
                    isDefinite: false,
                    reason: undefined,
                    cancel,
                  },
          },
        ]);
      });
    },
    [setUpdateQueue]
  );

  const updateTask = useCallback(
    (intent: IDownloadIntent, progress: number, isDefinite: boolean, cancel: () => void, step?: string) => {
      setUpdateQueue((s) => {
        return s.concat([
          {
            type: UpdateTypes.Update,
            timestamp: Date.now(),
            task: {
              id: intent.type === "File" ? intent.fileId : intent.packId + intent.type,
              progress,
              step,
              isDefinite,
              cancel,
            },
          },
        ]);
      });
    },
    [setUpdateQueue]
  );

  const markFailure = useCallback(
    (intent: IDownloadIntent, reason: string) => {
      setUpdateQueue((s) => {
        return s.concat([
          {
            type: UpdateTypes.Fail,
            timestamp: Date.now(),
            task: { id: intent.type === "File" ? intent.fileId : intent.packId + intent.type, reason },
          },
        ]);
      });
    },
    [setUpdateQueue]
  );

  const markSuccess = useCallback(
    (intent: IDownloadIntent) => {
      setUpdateQueue((s) => {
        return s.concat([
          {
            type: UpdateTypes.Success,
            timestamp: Date.now(),
            task: { id: intent.type === "File" ? intent.fileId : intent.packId + intent.type },
          },
        ]);
      });
    },
    [setUpdateQueue]
  );

  const dismissTask = useCallback(
    (id: string) => {
      setTasks((s) => {
        return {
          ...s,
          successes: s.successes.filter((t) => t.id !== id),
          failures: s.failures.filter((t) => t.id !== id),
        };
      });
    },
    [setTasks]
  );

  const ret = useMemo(() => ({ tasks, addTask, updateTask, markFailure, markSuccess, dismissTask }), [
    tasks,
    addTask,
    updateTask,
    markFailure,
    markSuccess,
    dismissTask,
  ]);
  return ret;
}

export const TaskContext = createContext<IDownloadTaskState | undefined>(undefined);
