import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      alignContent: "flex-start",
      maxWidth: "600px",
    },
    modalPaper: {
      margin: "auto",
      minWidth: "40vw",
      minHeight: "40vh",
      maxWidth: "90vw",
      maxHeight: "90vh",
      padding: theme.spacing(2),
      outline: "none",
      alignSelf: "center",
    },
    submitButton: {
      marginTop: theme.spacing(2),
    },
    chipInputWrapper: {
      flexGrow: 1,
      minWidth: theme.spacing(60),
    },
    chipInputChip: {
      margin: theme.spacing(0.5),
    },
    chipPopper: {
      zIndex: theme.zIndex.modal + 10,
    },
  })
);
