import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listContainer: {
      maxWidth: "80%",
      width: "640px",
    },
    userProgress: {
      width: 40,
      height: 40,
      "& > *": {
        position: "absolute",
        left: theme.spacing(2),
        top: theme.spacing(2),
        width: 40,
        height: 40,
      },
    },
  })
);
