import React, { useState, useContext, useEffect } from "react";
import {
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  Badge,
} from "@material-ui/core";

import DownloadIcon from "@material-ui/icons/CloudDownload";
import CancelIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";

import FlipMove from "react-flip-move";

import { useStyles } from "./progressPanel.style";
import { ITask, TaskStates, TaskContext } from "../../modules/task.hooks";

export interface IProgressPanelComponentProps {}

export const ProgressPanelComponent: React.FC<IProgressPanelComponentProps> = () => {
  const classes = useStyles();

  const { tasks, dismissTask } = useContext(TaskContext)!;
  const [progressPanelRoot, setProgressPanelRoot] = useState<HTMLElement | null>(null);

  const inProgressCount = tasks.inProgressTasks.length;
  const completedCount = tasks.successes.length;
  const failedCount = tasks.failures.length;
  const allTasks: ITask[] = [...tasks.inProgressTasks, ...tasks.failures, ...tasks.successes].sort(
    (a, b) => a.started - b.started
  );

  useEffect(() => {
    if (allTasks.length === 0) {
      setProgressPanelRoot(null);
    }
  }, [allTasks.length]);

  return (
    <>
      <IconButton
        aria-label="List of downloads"
        aria-controls="progress-panel"
        aria-haspopup="true"
        onClick={(e) => setProgressPanelRoot(e.currentTarget)}
        disabled={allTasks.length <= 0}
        color="inherit"
      >
        <Badge
          badgeContent={inProgressCount || failedCount || completedCount}
          color={inProgressCount > 0 ? "primary" : failedCount > 0 ? "error" : "secondary"}
          classes={{ badge: classes.badge, colorSecondary: classes.secondary }}
        >
          <DownloadIcon />
        </Badge>
      </IconButton>
      <Menu
        id="menu-progress-panel"
        className={classes.popover}
        anchorEl={progressPanelRoot}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted={true}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={!!progressPanelRoot}
        onClose={(e) => setProgressPanelRoot(null)}
      >
        <FlipMove typeName={List as any}>
          {allTasks.map((t) => (
            <ListItem key={t.id}>
              <ListItemText className={classes.listText}>
                {t.title || t.id}
                {t.subTitle ? ` - ${t.subTitle}` : ""}
                <LinearProgress value={t.progress} variant={t.isDefinite ? "determinate" : "indeterminate"} />
              </ListItemText>
              <ListItemSecondaryAction>
                {t.status === TaskStates.Succeeded || t.status === TaskStates.Failed ? (
                  <IconButton onClick={() => dismissTask(t.id)} edge="end" aria-label="Dismiss">
                    {t.status === TaskStates.Failed ? <ErrorIcon /> : <CheckIcon />}
                  </IconButton>
                ) : (
                  <IconButton onClick={() => t.cancel!()} edge="end" aria-label="Cancel">
                    <CancelIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </FlipMove>
      </Menu>
    </>
  );
};
