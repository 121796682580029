import { createMuiTheme } from "@material-ui/core";

export function getTheme(type: "light" | "dark" = "dark") {
  return createMuiTheme({
    palette: {
      primary: { main: "#1b9fd4" },
      secondary: { main: "#343a40" },
      type,
    },
  });
}
