import React, { useEffect } from "react";
import { UserPermissions, UserPermission } from "../../dtos/enums/userPermissions";
import { Redirect } from "react-router";

export interface INotValidatedComponentProps {
  permissions: UserPermissions | undefined;

  checkSession: () => void;
}

export const NotValidatedComponent: React.FC<INotValidatedComponentProps> = ({
  permissions,

  checkSession,
}: INotValidatedComponentProps) => {
  useEffect(() => {
    const check = setInterval(checkSession, 10000);
    return () => clearInterval(check);
  });

  if (permissions && permissions.check(UserPermission.Login)) {
    return <Redirect to="/images" />;
  }
  return (
    <main>
      <h3>Adminisztratori jovahagyas szukseges</h3>
      <p>
        A regisztracio az oldalra nem teljesen nyilt, mielott hasznalhatnad a teljes funkcionalitast egy adminnak jova
        kell hagynia a regisztraciodat es beallitani a megfelelo jogosultsagokat.
      </p>
    </main>
  );
};
