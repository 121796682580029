import { createAsyncAction } from "typesafe-actions";
import { WebApiError } from "../../modules/api/WebApiError";
import { UserPermissions, UserPermission } from "../../dtos/enums/userPermissions";
import { PermissionAction } from "../../dtos/enums/permissionAction";
import { FilePermission, FilePermissions } from "../../dtos/enums/filePermissions";
import { UserFilePermission, UserFilePermissions } from "../../dtos/enums/userFilePermissions";
import { IWorkpack } from "./admin.reducers";

export enum AdminActions {
  ListUsers = "ListUsers",
  ListUsersSuccess = "ListUsersSuccess",
  ListUsersFailure = "ListUsersFailure",

  LoadSequenceTypes = "LoadSequenceTypes",
  LoadSequenceTypesSuccess = "LoadSequenceTypesSuccess",
  LoadSequenceTypesFailure = "LoadSequenceTypesFailure",

  AdminListFiles = "AdminListFiles",
  AdminListFilesSuccess = "AdminListFilesSuccess",
  AdminListFilesFailure = "AdminListFilesFailure",

  SetPermissionForUser = "SetPermissionForUser",
  SetPermissionForUserSuccess = "SetPermissionForUserSuccess",
  SetPermissionForUserFailure = "SetPermissionForUserFailure",

  SetFilePermission = "SetFilePermission",
  SetFilePermissionSuccess = "SetFilePermissionSuccess",
  SetFilePermissionFailure = "SetFilePermissionFailure",

  SetUserFilePermission = "SetUserFilePermission",
  SetUserFilePermissionSuccess = "SetUserFilePermissionSuccess",
  SetUserFilePermissionFailure = "SetUserFilePermissionFailure",

  SetUserLabelSettings = "SetUserLabelSettings",
  SetUserLabelSettingsSuccess = "SetUserLabelSettingsSuccess",
  SetUserLabelSettingsFailure = "SetUserLabelSettingsFailure",

  GetUserLabelSettings = "GetUserLabelSettings",
  GetUserLabelSettingsSuccess = "GetUserLabelSettingsSuccess",
  GetUserLabelSettingsFailure = "GetUserLabelSettingsFailure",

  CreateWorkpack = "CreateWorkpack",
  CreateWorkpackSuccess = "CreateWorkpackSuccess",
  CreateWorkpackFailure = "CreateWorkpackFailure",

  GetWorkpack = "GetWorkpack",
  GetWorkpackSuccess = "GetWorkpackSuccess",
  GetWorkpackFailure = "GetWorkpackFailure",

  AssignWorkpack = "AssignWorkpack",
  AssignWorkpackSuccess = "AssignWorkpackSuccess",
  AssignWorkpackFailure = "AssignWorkpackFailure",
}

export const listUsers = createAsyncAction(
  AdminActions.ListUsers,
  AdminActions.ListUsersSuccess,
  AdminActions.ListUsersFailure
)<
  { cursor: number | undefined },
  {
    users: Array<{
      email: string;
      fullName: string;
      userPermissions: UserPermissions;
      filePermissions: UserFilePermissions;
      labelingettings: any;
    }>;
    cursor: number;
    originalCursor: number;
  },
  WebApiError
>();

export const adminListFiles = createAsyncAction(
  AdminActions.AdminListFiles,
  AdminActions.AdminListFilesSuccess,
  AdminActions.AdminListFilesFailure
)<
  { loadMore: boolean; emails: string[]; sequenceType: string },
  {
    files: Array<{ fileId: string; meta: any; userPermissions: { [email: string]: string } }>;
    cursor: number;
    originalCursor: number;
    sequenceType: string;
  },
  WebApiError<{ cursor: number | undefined; emails: string[]; sequenceType: string }>
>();

export const setPermissionForUser = createAsyncAction(
  AdminActions.SetPermissionForUser,
  AdminActions.SetPermissionForUserSuccess,
  AdminActions.SetPermissionForUserFailure
)<
  { email: string; action: PermissionAction; permission: UserPermission },
  { email: string; newPermission: UserPermissions },
  WebApiError
>();

export const setFilePermissionForUser = createAsyncAction(
  AdminActions.SetFilePermission,
  AdminActions.SetFilePermissionSuccess,
  AdminActions.SetFilePermissionFailure
)<
  { email: string; fileId: string; action: PermissionAction; permission: FilePermission },
  { email: string; fileId: string; newPermission: FilePermissions },
  WebApiError
>();

export const setUserFilePermissionForUser = createAsyncAction(
  AdminActions.SetUserFilePermission,
  AdminActions.SetUserFilePermissionSuccess,
  AdminActions.SetUserFilePermissionFailure
)<
  { email: string; action: PermissionAction; permission: UserFilePermission },
  { email: string; newPermission: UserFilePermissions },
  WebApiError
>();

export const loadSequenceTypes = createAsyncAction(
  AdminActions.LoadSequenceTypes,
  AdminActions.LoadSequenceTypesSuccess,
  AdminActions.LoadSequenceTypesFailure
)<{}, { sequenceTypes: string[] }, WebApiError>();

export const setUserLabelSettings = createAsyncAction(
  AdminActions.SetUserLabelSettings,
  AdminActions.SetUserLabelSettingsSuccess,
  AdminActions.SetUserLabelSettingsFailure
)<{ email: string; sequenceTypes: string[]; labels: any }, {}, WebApiError>();

export const getUserLabelSettings = createAsyncAction(
  AdminActions.GetUserLabelSettings,
  AdminActions.GetUserLabelSettingsSuccess,
  AdminActions.GetUserLabelSettingsFailure
)<{ email: string }, { email: string; sequenceTypes: string[]; labels: any }, WebApiError>();

export const createWorkpack = createAsyncAction(
  AdminActions.CreateWorkpack,
  AdminActions.CreateWorkpackSuccess,
  AdminActions.CreateWorkpackFailure
)<{ name: string; files: string[]; sequenceTypes: string[] }, IWorkpack, WebApiError>();

export const getWorkpacks = createAsyncAction(
  AdminActions.GetWorkpack,
  AdminActions.GetWorkpackSuccess,
  AdminActions.GetWorkpackFailure
)<
  { isLoadMore: boolean },
  { originalCursor: number; cursor: number; workpacks: Array<{ pack: IWorkpack; assignees: string[] }> },
  WebApiError
>();

export const assignWorkpack = createAsyncAction(
  AdminActions.AssignWorkpack,
  AdminActions.AssignWorkpackSuccess,
  AdminActions.AssignWorkpackFailure
)<{ packId: string; email: string }, { id: string; email: string; files: string[] }, WebApiError>();
