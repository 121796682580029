import { failedAction } from "../state/errors/error.actions";
import { AsyncActionCreator } from "typesafe-actions";
import { getType } from "typesafe-actions";
import { ValidationResult } from "../modules/validation/validationResult";
import { WebApiError } from "../modules/api/WebApiError";

export function popMyError(
  errorActions: failedAction[],
  asyncAction: AsyncActionCreator<any, any, [any, WebApiError | ValidationResult]>,
  dismiss?: (action: failedAction) => void
): ValidationResult | undefined {
  const res = errorActions.find(
    err => err.type === getType(asyncAction.failure) && err.payload instanceof ValidationResult
  );
  if (res && dismiss) {
    dismiss(res);
    return res.payload as ValidationResult;
  }
  return undefined;
}
